@font-face {
    font-family: 'Magistral';
    font-weight: 400;
    src: local('Magistral'), url(../assets/fonts/MagistralRegular.otf) format('opentype');
}
@font-face {
    font-family: 'Magistral';
    font-weight: 700;
    src: local('Magistral'), url(../assets/fonts/MagistralBold.otf) format('opentype');
}
@font-face {
    font-family: 'Magistral';
    font-weight: 800;
    src: local('Magistral'), url(../assets/fonts/MagistralBlack.otf) format('opentype');
}