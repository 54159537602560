@import "./custom-style.scss";

// sadiq
// Quick Search

.d-mob-none {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.d-web-none {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.iconBtn {
  border: none;
  display: flex;
}

.outline-icon-btn {
  background: #ffffff;
  border: 1px solid #e4e9ee;
  border-radius: 5px;
  width: 40px;
  height: 40px;
}

.shareBtn {
  width: 29px;
  height: 29px;
  background-color: $blackbg;
  border-radius: 50%;
  border: none;
  @include flex-center();

  &:hover {
    background: $primeGradient;
  }
}

@mixin web-bg {
  background-image: url("../assets/images/mob-dashboard-bg.png");
  background-repeat: no-repeat;
  background-position: bottom;
}

.web-bg {
  @include web-bg;
  background-size: 100% 270px;

  @media (max-width: 767px) {
    background-size: 100% auto;
  }
}

.mob-bg {
  @media (max-width: 767px) {
    @include web-bg;
    background-size: 100% auto;
  }
}

.web-car-bg {
  background-image: url("../assets/images/bg-img.png");
  background-repeat: no-repeat;
  background-position: bottom;
  padding-bottom: 260px;

  @media (max-width: 767px) {
    padding-bottom: 0;
    @include web-bg;
    background-size: 100% auto;
  }
}

.hoverBtn {
  &:hover {
    background: $lineargradient !important;
    transition: 1s ease !important;
    -webkit-transition: 1s ease !important;
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 769px) {
    flex-direction: column;
  }
}

.df-flex {
  display: flex;
}

.df-items-center {
  display: flex;
  align-items: center;
}

.df-content-between {
  display: flex;
  justify-content: space-between;
}

.df-content-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cmnSlider {
  .slick-slide {
    padding: 0;
  }

  .slick-dots {
    bottom: 28px;
    display: flex !important;
    align-items: center;
    justify-content: center;

    li {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.5);
      border: 0.2px solid #ffffff;

      &.slick-active {
        width: 10px;
        height: 10px;
        background: #ffffff;
      }

      button:before {
        display: none;
      }
    }
  }
}

.dashboardHeader {
  background: #ffffff;
  padding: 22px 30px 22px;
  position: fixed;
  border-bottom: 1px solid #dbdbdb;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;

  @media (max-width: 767px) {
    padding: 0;
    box-shadow: none;
    border-bottom: none;
  }
}

.dashboardHeader-web {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerNav {
  & > ul {
    display: flex;
    align-items: center;

    & > li {
      margin-left: 15px;
    }
  }

  .chat-icon {
    background: #fe4630;
    width: 32px;
    height: 32px;
  }

  .search-box input {
    min-height: 32px;
    border-radius: 100px;
  }
}

.message-wrp {
  text-align: center;

  svg {
    min-height: 17px;
  }

  path {
    stroke: #7c7c7c;
  }

  span {
    font-weight: 700;
    font-size: 10px;
    color: #7c7c7c;
    display: block;
    margin-top: -3px;
  }
}

.likerCars-link {
  text-align: center;

  svg {
    min-height: 17px;
  }

  path {
    stroke: #7c7c7c;
  }

  span {
    font-weight: 700;
    font-size: 10px;
    color: #7c7c7c;
    display: block;
    margin-top: -3px;
  }
}

.notification-wrp {
  position: relative;

  svg {
    height: 20px !important;
  }

  span {
    font-weight: 700;
    font-size: 10px;
    color: #7c7c7c;
    display: block;
    margin-top: -3px;
  }

  .badge {
    position: absolute;
    top: -15px;
    background: #fe4630;
    color: #fff;
    right: -10px;
    margin: 0;
  }

  .notificationDot {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fe4630;
    border-radius: 15px;
    right: 0;
    top: -5px;
  }

  .notification-panel {
    padding: 30px;
    background-color: #ffffff;
    border: 0.5px solid #e4e4e4;
    box-shadow: 0px 4px 10px rgba(122, 122, 122, 0.2);
    border-radius: 5px;
    transition: all 0.3s;

    &::before {
      content: "";
      margin: 0 12px 0 8px;
      border: solid #e4e4e4;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 8px;
      transform: rotate(-134deg);
      background: white;
      position: absolute;
      right: 10px;
      top: -9px;

      @media (max-width: 767px) {
        display: none;
      }
    }

    &.open {
      transition: all 0.3s;
      opacity: 1;
      pointer-events: all;
    }

    @media (max-width: 767px) {
      width: 410px;
      position: fixed;
      border: none;
      padding: 15px;
      right: -40px;
      top: 0;
    }

    @media (max-width: 480px) {
      width: 320px;
    }

    @media (max-width: 400px) {
      width: 260px;
    }

    .mark-txt {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 2px;
      color: #000;
      background: transparent;
    }

    .notification-controls {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 10px;
    }
  }

  .tab-content {
    max-height: 500px;
    overflow: auto;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 5px;

    h6 {
      background: #fafafa;
      border-radius: 5px;
      font-weight: 400;
      font-size: 14px;
      color: #353333;
      padding: 12px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      position: relative;
      padding-left: 50px;

      @media (max-width: 400px) {
        padding-left: 15px;
      }

      small {
        color: #7c7c7c;
        display: flex;
        font-size: 10px;
        align-items: flex-start;
      }

      svg {
        flex: none;
        margin: auto;
        margin-right: 10px;
        position: absolute;
        left: 15px;
        top: 0;
        bottom: 0;

        @media (max-width: 400px) {
          display: none;
        }
      }

      @media (max-width: 767px) {
        background: #ffffff;
        border: 1px solid #e4e4e4;
        color: #7c7c7c;
      }

      &.new {
        background: #fff3f3;

        path {
          stroke: #ff8177;
        }
      }
    }
  }
}

.userDropdown {
  position: relative;

  .actionBtn {
    border: none;
    font-size: 12px;

    span {
      margin-right: 5px;
    }

    img {
      margin-right: 5px;
      width: 32px;
      height: 32px;
      border-radius: 50px;
    }
  }

  .userdropdownContent {
    padding: 30px 40px;
    opacity: 0;
    background-color: #ffffff;
    border: 0.5px solid #e4e4e4;
    box-shadow: 0px 4px 10px rgba(122, 122, 122, 0.2);
    border-radius: 5px;
    position: absolute;
    width: 350px;
    right: 0;
    left: auto;
    top: 45px;
    pointer-events: none;
    transition: all 0.3s;

    &::before {
      content: "";
      margin: 0 12px 0 8px;
      border: solid #e4e4e4;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 8px;
      transform: rotate(-134deg);
      background: white;
      position: absolute;
      right: 0;
      top: -9px;

      @media (max-width: 767px) {
        display: none;
      }
    }

    &.open {
      transition: all 0.3s;
      opacity: 1;
      pointer-events: all;
    }

    h4 {
      font-weight: 700;
      font-size: 16px;
      color: #353333;
    }

    h6 {
      font-weight: 400;
      font-size: 11px;
      color: #353333;
      margin-bottom: 25px;
    }

    button {
      margin: 25px auto 0;
    }
  }

  @media (max-width: 767px) {
    position: fixed;
    z-index: 1;
    background-color: #000000d1;
    background-image: url("../assets/images/overlaybg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-weight: 400;
      font-size: 28px;
      color: #f27b79;
      padding: 0 50px;
      margin-bottom: 30px;

      span {
        color: #fff;
      }
    }

    .userdropdownContent {
      pointer-events: all;
      width: 100%;
      position: initial;
      border-radius: 40px 40px 0 0;
    }
  }
}

.boxedCheckbox-wrp {
  margin-bottom: 10px;

  label {
    position: relative;
    cursor: pointer;
    display: block;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    padding: 10px 15px;
    color: #000;
    border: 1px solid rgba(124, 124, 124, 0.2509803922);
    border-radius: 50px;
    margin-bottom: 0;
    background: #7c7c7c40;
  }

  input:not(:checked),
  input:checked {
    position: absolute;
    left: -9999px;
  }

  input:checked + label {
    background: linear-gradient(134.62deg, #60cf1c 9.91%, #c8cc00 96.66%);
    color: #fff;
    border: 1px solid #9fcd0b;
  }

  input + label:before {
    content: "+";
    position: absolute;
    top: 0;
    width: 18px;
    height: 18px;
    outline: none;
    transition: all 0.2s;
    background: $primeGradient;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    margin: auto;
    right: 12px;
    color: #fff;
    font-size: 17px;
    border-radius: 50%;
  }

  input:checked + label:before {
    content: "✔";
    background: #ffffff;
    color: #b22b5b;
    font-size: 8px;
    transition: all 0.2s;
  }
}

.colorPicker-wrp {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  margin-top: 15px;

  @media (max-width: 767px) {
    column-gap: 10px;
  }
}

.colourCheckbox-wrp {
  margin-bottom: 10px;
  width: calc(100% / 2 - 15px);

  @media (max-width: 767px) {
    width: 100%;
  }

  label {
    position: relative;
    cursor: pointer;
    display: block;
    width: 100%;
    height: 48px;
    border-radius: 5px;

    em {
      border-radius: 2px;
      font-weight: 400;
      font-size: 14px;
      color: #0e0e0e;
      font-style: normal;
      padding: 1px 5px;
      display: inline-block;
      margin: 12px;
    }
  }

  input:not(:checked),
  input:checked {
    position: absolute;
    left: -9999px;
  }

  input + label:before {
    position: absolute;
    width: 18px;
    height: 18px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    margin: auto;
    left: auto;
    right: 15px;
    top: 0;
    content: "";
    border-radius: 50%;
    font-size: 8px;
    transition: all 0.2s;
  }

  input:checked + label:before {
    position: absolute;
    width: 18px;
    height: 18px;
    content: "✔";
    background: linear-gradient(to right, #ff8177, #b22b5b);
    border-radius: 50%;
    color: #fff;
    transition: all 0.2s;
    border: 1px solid #ffffff;
  }
}

.dropDown-wrp {
  position: relative;

  .dropItems {
    opacity: 0;
    background: #ffffff;
    border: 0.5px solid #e4e4e4;
    box-shadow: 0px 4px 10px rgba(122, 122, 122, 0.2);
    border-radius: 5px;
    position: absolute;
    width: 150px;
    right: 0;
    left: auto;
    pointer-events: none;

    li {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      color: #282828;
      padding: 12px;
      border-bottom: 1px solid #e8e8e8;

      button,
      a {
        text-align: left;
        display: block;
        width: 100%;
      }
    }

    &.open {
      opacity: 1;
      pointer-events: all;
    }
  }

  &::before {
    background-image: url("../assets/images/drop-arrow.png");
    width: 15px;
    height: 15px;
    display: block;
  }
}

.menuBtnWeb {
  svg {
    transform: rotateY(180deg);
  }
}

.dashboardHeader-mob {
  background: #f6f6f6;
  display: flex;
  align-items: center;
  padding: 15px 25px;
  justify-content: space-between;

  h3 {
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: #282828;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 65%;
  }

  button {
    border: none;
  }
}

.headBtn-grp {
  display: flex;
  align-items: center;

  button {
    margin-left: 15px;
  }
}

.footerWeb {
  font-weight: 400;
  font-size: 13px;
  color: #cacaca;
  text-align: center;
  background: #333131;
  padding: 11px 0;
}

.footerMob {
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  width: 96%;
  max-width: 575px;
  margin: auto;
  background: #282828;
  box-shadow: 0px -3px 28px rgba(0, 0, 0, 0.58);
  border-radius: 45px;
  padding: 15px 25px;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .swapBtn {
      background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: relative;
      margin-top: -30px;
    }
  }
}

.dashboard-content {
  width: 100%;
  padding: 140px 0 0;
  position: relative;
  min-height: 100vh;

  @media screen and (max-width: 1440px) {
    padding-top: 130px;
  }

  @media screen and (max-width: 1199px) {
    padding-top: 130px;
  }

  @media screen and (max-width: 769px) {
    padding-top: 110px;
  }

  @media screen and (max-width: 769px) {
    padding-top: 130px;
  }
}

.title-wrp {
  padding: 20px 0px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
  }

  h2 {
    font-weight: 700;
    font-size: 26px;
    color: #282828;
    margin-bottom: 10px;

    @media screen and (max-width: 1200px) {
      font-size: 24px;
    }

    @media screen and (max-width: 767px) {
      font-size: 22px;
    }
  }
}

.search-box {
  width: 270px;
  max-width: 100%;

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 20px;
  }

  input {
    width: 100%;
    padding: 5px 10% 5px 15px;
    min-height: 42px;
    font-size: 14px;
    font-weight: 700;
    color: #353333;
    outline: inherit;
    background-position: 96% center;
    background-color: #f5f5f5;
    border: 0.5px solid #b9b9b9;
    border-radius: 5px;
    background-image: url("../assets/images/search-icon.svg");
    background-repeat: no-repeat;

    @media screen and (max-width: 767px) {
      border-radius: 25px;
      min-height: 39px;
    }

    &::placeholder {
      color: $lightText;
      font-weight: $regularText;
    }

    &:read-only {
      pointer-events: none;

      &::placeholder {
        color: $darkgreyText;
        font-weight: $boldText;
      }
    }
  }
}

.breadCrumbs-wrp {
  ul {
    display: flex;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      margin-bottom: 10px;
    }
  }

  li {
    position: relative;
    font-weight: 400;
    font-size: 14px;
    color: #8c8c8c;

    &::after {
      content: "/";
      margin: 0 5px;
      color: #8c8c8c;
    }

    &:last-child {
      color: #141313;

      &::after {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    display: block !important;
  }
}

// Liked Cars
.LikedCars-wrp {
  .title-btn-grp {
    display: flex;
    align-items: center;
    width: calc(100% - 320px);

    @media (max-width: 767px) {
      width: auto;
    }

    .outline-icon-btn {
      margin-left: 10px;
    }
  }

  .title-wrp {
    @media (max-width: 380px) {
      flex-direction: column;
      align-items: flex-start;

      .sm-mobile-view {
        margin-top: 0;
      }
    }
  }
}

.selectAll-btn {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 15px;
  font-weight: 400;
  font-size: 12px;
  color: #616161;
  padding: 4px 13px;
  margin: 6px 0 6px 0px;
}

// Liked Cars

// My Cars List
.myCarsList-wrp {
  display: flex;
  column-gap: 55px;

  @media (max-width: 1281px) {
    column-gap: 30px;
  }

  .listingCar-card {
    @media (max-width: 1281px) {
      width: calc(100% / 2 - 15px);
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .modal-body h3 span {
    margin: auto;
    margin-top: 15px;
  }
}

.sidebarFilter-wrp {
  width: 265px;
  flex: none;

  @media (max-width: 1280px) {
    display: block !important;
    position: absolute;
    left: 0;
    z-index: 9999;
    width: 100%;
    top: 0;
    padding: 0px;
    bottom: 0;
    background: #00000039;
    transition: all 0.3s ease-in-out;

    .sidebarFilter {
      background: #fff;
      width: 90%;
      padding: 30px;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 9999;
      transition: all 0.3s ease-in-out;
      overflow: auto;

      @media (max-width: 1280px) {
        width: 30%;
      }

      @media (max-width: 1024px) {
        width: 35%;
      }

      @media (max-width: 991px) {
        width: 90%;
      }
    }

    &.hide {
      width: 0 !important;
      padding: 0;
      opacity: 0;
      transition: all 0.3s ease-in-out;

      .sidebarFilter {
        padding: 0 !important;
        width: 0 !important;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.sidebarFilter {
  h3 {
    font-weight: 700;
    font-size: 16px;
    color: #2d2d2d;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;

    span {
      font-size: 14px;
      font-weight: 400;
      color: #464444;
    }
  }

  .search-box input {
    background-image: none;
  }

  .makers-body {
    max-height: 250px;
    overflow: hidden;
    overflow-y: auto;
    margin: 15px 0;
    padding-right: 7px;

    .checkType-checkbox {
      margin-right: 5px;
      position: relative;
    }
  }

  .sliderValue-wrp {
    span {
      display: none;
    }
  }

  .sliderRange-wrp {
    display: none !important;
  }

  .no-data {
    height: 20vh;
  }
}

.resetfilterBtn {
  background: #bd7544;
  width: 100%;
  min-height: 37px;
}

.subsciptionBox {
  margin: 35px 0 25px;
  padding: 20px 25px;
  border-radius: 5px;
  background: #303030;

  h5 {
    font-size: 16px;
    font-weight: $boldText;
    color: $whiteText;
    margin-bottom: 7px;
  }

  p {
    font-size: 14px;
    color: #939393;
    margin-bottom: 30px;
  }

  button {
    font-size: 14px;
    font-weight: $boldText;
    color: $whiteText;
    background: $orangGradient;
    border-radius: 5px;
    padding: 8px 20px;
    min-height: 37px;
  }
}

.listingCars-wrp {
  display: flex;
  gap: 20px;
  width: calc(100% - 320px);

  @media (max-width: 1280px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    column-gap: 0px;
    row-gap: 12px;
    width: 100%;
  }
}

.listingCar-card {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding: 0px;
  width: calc(100% / 2 - 7.5px);
  overflow: hidden;
  position: relative;

  .listingCar-desc-share {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 520px) {
      flex-direction: column;
      align-items: baseline;
      gap: 20px;
    }

    .listingCar-desc-left {
      .socialCount {
        justify-content: flex-start;
      }
    }

    .listingCar-desc-right {
      .socialCount {
        justify-content: flex-end;
      }
    }

    .listingCar-desc-left,
    .listingCar-desc-right {
      width: 100%;

      @media (max-width: 520px) {
        .open-to-swap {
          margin: auto;
          margin-right: 0;
        }

        .socialCount {
          justify-content: space-between;
        }
      }
    }

    .openToSwap {
      .open-to-swap {
        padding: 0 !important;
      }
    }
  }

  @media (max-width: 991px) {
    width: 100%;
  }

  figure {
    width: 100%;
    overflow: hidden;
    border-radius: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h3 {
    font-weight: 400;
    font-size: 13px;
    color: $cardRed;
    margin: 20px 0 20px;
    width: 100%;
    max-width: 100%;
    padding-right: 20px;

    span {
      display: block;
      font-weight: 700;
      font-size: 15px;
      text-transform: uppercase;
      color: $blackText;
      margin-top: 10px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 280px;

      @media (max-width: 1199px) {
        max-width: 230px;
      }

      @media (max-width: 1024px) {
        max-width: 390px;
      }

      @media (max-width: 767px) {
        margin-bottom: 12px;
        font-size: 14px;
        max-width: 180px;
      }
    }

    @media (max-width: 767px) {
      margin: 10px 0 10px;
      font-size: 12px;
    }
  }

  h4 {
    font-weight: 700;
    font-size: 22px;
    color: #353333;
  }

  h6 {
    font-weight: 700;
    font-size: 14px;
    color: #808080;
    margin-bottom: 15px;
  }

  h5 {
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    background: $SecondaryAmberGradient;
    color: #ffffff;
    border-radius: 5px;
    padding: 8px;
    margin: 0;
    width: 100%;
  }

  .socialCount {
    @include flex();
    flex-wrap: wrap;
    gap: 10px;

    li {
      @include flex();
      font-weight: 400;
      font-size: 12px;
      color: #010101;

      svg {
        margin-right: 8px;
        width: 20px;
      }

      &.shareCount {
        margin-left: auto;
        cursor: pointer;

        svg {
          margin-left: 8px;
          margin-right: 0px;
        }
      }

      &.chatB {
        cursor: pointer;

        svg {
          margin-left: 8px;
          margin-right: 0px;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    button {
      @include flex();
      margin-right: 22px;
      font-weight: 700;
      font-size: 12px;
      color: #010101;

      svg {
        margin-right: 8px;
      }

      &.shareCount {
        margin-left: auto;
        cursor: pointer;

        &.nonactive {
          cursor: default;
        }

        svg {
          margin-left: 8px;
          margin-right: 0px;
        }
      }

      &.chatB {
        cursor: pointer;

        svg {
          margin-left: 8px;
          margin-right: 0px;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .offersCount {
      svg {
        width: auto;
      }
    }

    .openToSwap {
      display: flex;
      align-self: center;
      width: auto;

      .open-to-swap {
        display: flex;
        align-items: flex-end !important;
        padding-bottom: 12px;
        gap: 0 10px;

        .open-to-swap-logo {
          display: flex;
          width: 18px;
          height: 18px;
          align-items: center;
          justify-content: center;

          svg {
            width: 20px !important;

            path {
              stroke: #fff;
            }
          }
        }

        .title {
          position: relative;
          text-transform: uppercase;
          font-size: 13px;
          font-weight: 600;
          white-space: nowrap;
        }

        .prevt-hover:hover {
          background: #000000bf !important;
        }
      }
    }
  }

  &:hover {
    .actionBtn-grp {
      opacity: 1;
      pointer-events: all;
      transition: all 0.3s;
    }
  }

  .custom-checkbox {
    width: auto;
    padding-left: 30px;

    .checkmark {
      top: 0px;
      height: 20px;
      width: 20px;

      &:after {
        left: 6px;
        top: 3px;
      }
    }
  }
}

.more-wrp {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .moreBtn {
    background: #282828;
    border-radius: 5px;
    font-weight: 700;
    font-size: 12px;
    color: #ffffff;
    text-transform: uppercase;
    padding: 7px 10px;
    margin-left: 15px;
  }
}

.listingCar-desc {
  padding: 20px;
  position: relative;
  min-height: 203px;
}

.listingCar-select-action {
  padding: 0px 20px 20px 20px;
  position: relative;
  z-index: 99;
}

.myCars-slider {
  width: 100%;
  position: relative;

  .slick-dots {
    bottom: 20px;
    align-items: center;
  }

  .slick-dots li {
    width: 12px;
    height: 12px;
    border-radius: 50px;
    background: #d9d9d9;
    border: none;
    margin: 0 4px;

    &.slick-active {
      width: 16px;
      height: 16px;
      background: $primeGradient;
    }

    button {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }

  figure {
    position: relative;
    height: 185px;
  }

  .mycarShare {
    position: absolute;
    bottom: 30px;
    right: 12px;
    top: auto;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .item {
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 58%;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgb(0 0 0 / 38%) 50.36%, rgba(0, 0, 0, 0) 100%);
      pointer-events: none;
    }
  }
}

.actionBtn-grp {
  position: absolute;
  top: 0;
  display: flex;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 100%;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  background: #ffffff85;
  z-index: 11;
  padding-top: 165px;

  button {
    margin: 0 10px;
    height: 42px;
    min-width: auto;
    width: 150px;
  }
}

.pagination-wrp {
  margin: 15px 0 10px;

  ul {
    display: flex;
    align-items: center;

    li {
      margin-right: 10px;

      button {
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        background: #bdbdbd;
        border-radius: 5px;
        padding: 9px 17px;

        &:hover,
        &.active {
          background: #3f3244;
        }

        &:disabled:hover {
          background: #bdbdbd;

          color: white;
          cursor: default;
        }
      }

      &:first-child,
      &:last-child {
        button {
          padding: 9px 25px;
        }
      }
    }
  }
}

.carRatings {
  top: 15px;
  left: 0;
  right: 0;
  margin: auto;
  width: 94%;
  position: absolute;
  z-index: 111;
  display: flex;
  align-items: center;

  .carRatings-btn {
    flex-direction: column;
    gap: 5px;
    width: 50px;
    height: 46px;
    background: rgba(0, 0, 0, 0.42);
    border: 1px solid #656565;
    border-radius: 4px;
    cursor: pointer;
    padding: 3px;
  }

  .premium-txt {
    color: #f1dd80;
    display: block;
    font-size: 10px;
  }

  h6 {
    color: #f27b79;
    font-weight: 700;
    font-size: 14px;
    margin: 0;
    word-break: break-word;
    max-width: 70%;
    padding-right: 10px;
  }

  button {
    background: #141414;
    border-radius: 2px;
    width: 31px;
    height: 31px;
    @include flex-center();

    &.tagBtn {
      margin-left: auto;
    }

    margin: auto;
    margin-right: 0;
  }

  .quickSale-condition {
    margin-right: 80px;
  }
}

.ratingStars-grp {
  display: flex;
  align-items: center;
  margin-left: 10px;

  li {
    margin-right: 5px;

    path {
      fill: rgb(128, 128, 128);
    }

    &.full {
      path {
        fill: rgb(255, 196, 43);
      }
    }

    &.half {
      path#second {
        fill: rgb(255, 196, 43);
      }
    }
  }
}

.experienceratingStars-grps {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  li {
    margin-right: 5px;
    cursor: pointer;

    &.disabled {
      path {
        fill: rgb(128, 128, 128);
      }
    }
  }
}

button.disabled {
  background: #cacaca;
  cursor: not-allowed;
}

.blackBtn {
  background: #282828;
}

.redBtn {
  background: $primeGradient;
}

.whiteBtn {
  background-color: #ffffff;
  color: #353333;
  border: 1px solid #282828;

  &:hover {
    color: #fff;
  }
}

.confirmNobuttonDisabled {
  background-color: #ffffff;
  color: #353333;
  border: 1px solid #282828;

  &:hover {
    color: #fff;
  }

  &:disabled {
    background-color: #ffffff;
    color: #353333;
  }
}

.addCar-btn {
  min-height: 35px;
  padding: 5px 20px;
  min-width: 120px;

  svg {
    margin: 3px 5px 0;

    path {
      stroke: #fff;
    }
  }
}

.carSaleIcon {
  margin-left: auto;
  display: flex;
  width: 100%;
  align-items: center;
}

// My Cars List

// Quick Search

.rangeSlider-wrp {
  padding: 5px 0 15px 0;

  .sliderValue-wrp {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .slider__left-value,
    .slider__right-value {
      font-weight: 400;
      font-size: 14px;
      color: #8c8c8c;
    }
  }

  .sliderTrack-wrp {
    position: relative;
    width: 100%;

    .slider__track,
    .slider__range {
      position: absolute;
      border-radius: 8px;
      height: 1px;
    }

    .slider__track {
      background-color: #f2f2f2;
      width: 100%;
      z-index: 1;
      box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    }

    .slider__range {
      background-color: #e3e3e3;
      z-index: 2;
    }
  }

  .sliderRange-wrp {
    @include flex-between();
    font-weight: 400;
    font-size: 14px;
    color: #7c7c7c;
    margin-top: 40px;
  }

  .thumb-wrp {
    position: relative;
  }

  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
  }

  .thumb--left {
    z-index: 3;
  }

  .thumb--right {
    z-index: 4;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    border: none;
    border-radius: 50%;
    background: #292929;
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    border: none;
    border-radius: 50%;
    background: #292929;
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  &.singleRange {
    .sliderValue-wrp {
      .slider__left-value {
        display: none;
      }

      span {
        display: none;
      }
    }

    .thumb--left {
      display: none;
    }
  }
}

.filterpopup-range {
  margin: 15px 0;

  .sliderValue-wrp {
    .slider__left-value,
    .slider__right-value {
      font-weight: 700;
      font-size: 16px;

      color: #353333;
    }
  }

  .sliderTrack-wrp {
    .slider__track,
    .slider__range {
      border-radius: 8px;
      height: 8px;
    }

    .slider__range {
      background-color: #40b9b9;
      z-index: 2;
    }
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.38);
    height: 18px;
    width: 18px;
    margin-top: 6px;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.38);
    height: 18px;
    width: 18px;
    margin-top: 6px;
    pointer-events: all;
    position: relative;
  }
}

.checkType-checkbox {
  margin-bottom: 10px;

  label {
    position: relative;
    cursor: pointer;
    display: block;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    padding: 6px 0;
    color: #535353;
    text-align: left;
  }

  input:not(:checked),
  input:checked {
    position: absolute;
    left: -9999px;
  }

  input + label:before {
    content: "";
    position: absolute;
    top: 0;
    width: 18px;
    height: 18px;
    outline: none;
    transition: all 0.2s;
    background: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    margin: auto;
    right: 0;
    color: #fff;
    font-size: 17px;
    border: 1px solid #d2d2d2;
  }

  input:checked + label:before {
    content: "";
    color: #fff;
    font-size: 8px;
    transition: all 0.2s;
    background: url("../assets/images/checks-black.svg");
    background-size: cover;
  }

  &.tickFirst {
    input + label:before {
      left: 0;
      right: auto;
    }

    label {
      padding-left: 30px;
    }
  }
}

.modal-wrap.quickSearch-modal {
  @media (max-width: 767px) {
    align-items: flex-end;
  }

  .modal-head {
    text-align: left;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 20px;

    @media (max-width: 767px) {
      border: none;
      padding-bottom: 0px;

      h5 {
        font-weight: 400;
        font-size: 16px;
        color: #353333;
      }
    }

    .modalClose-btn {
      display: none;
    }
  }

  .modal-body {
    text-align: left;
    padding: 30px;
    max-width: 790px;

    h6 {
      font-weight: 700;
      font-size: 14px;
      color: #282828;
      text-transform: uppercase;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    p {
      font-size: 12px;
      color: #7c7c7c;
      margin-bottom: 20px;
    }

    @media (max-width: 767px) {
      .modal-foot .resetBtn {
        background: #282828;
        color: #ffffff;
      }
    }

    .modal-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-direction: column;
      }

      .searchOP {
        width: 45%;
        margin-bottom: 20px;

        @media (max-width: 767px) {
          margin-bottom: 10px;
          width: 100%;
        }
      }
    }
  }

  .checkType-checkbox {
    &:last-of-type {
      margin-bottom: 0;

      label {
        border: none;
      }
    }
  }

  .dvder {
    margin: 10px 0;
  }
}

.modal-wrap.closeBtn-modal {
  .modal-head {
    position: relative;
    padding: 25px 30px;
    border-bottom: 0.5px solid #cfcfcf;

    @media (max-width: 767px) {
      padding: 15px 30px;
    }

    h5 {
      font-weight: 700;
      font-size: 16px;
      color: #b22b5b;
    }
  }

  .modal-body {
    text-align: left;
    background-color: #ffffff;
    padding: 0;

    @media (max-width: 767px) {
      background-color: #f6f6f6;
    }
  }

  .modal-content {
    padding: 0px 30px;

    @media (max-width: 767px) {
      padding: 0 30px;
    }
  }

  .modal-foot {
    padding: 20px 30px;
  }

  .modalClose-btn {
    position: absolute;
    right: 19px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 25px;
    height: 25px;

    svg {
      width: 12px;
    }
  }
}

// Quick Search
// Filter Modal

.szh-accordion__item-content {
  transition: height 0.2s ease-in-out;
}

.accordion-wrp {
  .szh-accordion__item-panel {
    padding-top: 20px;
  }

  .szh-accordion__item {
    position: relative;
    padding: 0 0 10px;

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: $lineargradient;
    }
  }

  .szh-accordion__item-btn {
    position: relative;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: #353333;
    margin: 0;
    width: 100%;
    text-align: left;

    &::after {
      content: "^";
      height: 10px;
      position: absolute;
      bottom: 0;
      right: 0;
      left: auto;
      width: 10px;
      transform: rotateX(180deg);
      transition: all 0.3s;
    }
  }

  .szh-accordion__item--expanded {
    .szh-accordion__item-btn {
      &::after {
        transform: rotateX(180deg);
        transition: all 0.3s;
      }
    }
  }
}

.filterModal.modal-wrap {
  .search-box {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;

    input {
      background: url(../assets/images/search-transperant.svg) no-repeat 17px center;
      padding-left: 40px;
      padding-right: 15px;

      @media screen and (max-width: 767px) {
        border-radius: 5px !important;
      }
    }
  }

  .modal-body {
    max-width: 95%;
    width: 1200px;
    background: #ffffff;
    flex: none;
  }

  .rangeSlider-wrp .sliderValue-wrp {
    justify-content: flex-end;
  }

  @media (max-width: 767px) {
    .modal-head {
      position: relative;
      border-bottom: none;
      padding-bottom: 0;
      background: rgba(226, 226, 226, 0);
      margin-bottom: 8px;

      h5 {
        font-weight: 400;
        color: #353333;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .modal-foot {
      padding: 20px 30px;
      justify-content: flex-start;
      width: 35%;

      .custom-btn,
      .send-btn {
        min-width: 50%;
        border-radius: 5px;
      }
    }
  }

  .modal-foot {
    padding: 20px 30px;
  }
}

.w-50-wrp {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div {
    width: 48%;

    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

.filterTab {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow-x: hidden;

  @media (max-width: 767px) {
    overflow: auto;
    padding-right: 5px;

    .accordion-wrp {
      overflow: auto;
      height: 190px;
      padding-right: 10px;
    }

    .content {
      .tab-content {
        &:first-child {
          .mob-height {
            height: unset;
          }
        }
      }
    }

    .mob-height {
      height: 260px;
      margin-top: 10px;
    }
  }

  .locationFilter {
    input:checked + label::after {
      right: unset !important;
      left: 0;
    }
  }

  .nav {
    align-items: flex-start;
    flex-direction: column;
    width: 35%;

    &.inner-scroll {
      overflow: auto;
      height: 51vh;
      flex-direction: row;
      padding-right: 10px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow: auto;
    }

    li {
      position: relative;
      width: 100%;
      font-size: 16px;
      font-weight: 700;
      color: #727272;
      padding: 10px 10px 10px;
      cursor: pointer;
      border-bottom: 1px solid #ededed;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      @media screen and (max-width: 767px) {
        font-size: 14px;
        white-space: nowrap;
        padding: 0 10px;
      }

      &:last-child {
        border: none;
      }

      &.active {
        color: $blackText;
        position: relative;
      }

      &::after {
        content: "";
        border: solid black;
        border-width: 0 1px 1px 0;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        float: right;

        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
  }

  .content {
    width: 65%;
    padding: 10px 5px 10px 35px;
    transition: left 0.3s;
    min-height: 200px;

    &.scroll-content {
      height: 51vh;
      overflow: auto;
    }

    .tab-content {
      padding-right: 15px;

      @media (max-width: 420px) {
        padding: 0 15px;
      }
    }

    h4 {
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      color: #353333;
      margin-bottom: 18px;
    }

    h6 {
      font-size: 14px;
      font-weight: 400;
      color: #7c7c7c;
      margin-bottom: 15px;
    }

    @media screen and (max-width: 767px) {
      padding: 25px 0 0;
      width: 100%;
      position: absolute;
      left: 50%;
      right: 0;
      top: 0;
      z-index: 1111;
      background: #fff;
      height: 100%;
      overflow: auto;
      opacity: 0;
      pointer-events: none;
    }
  }

  @media screen and (max-width: 767px) {
    &.mob-open {
      .content {
        left: 0%;
        opacity: 1;
        pointer-events: all;
        transition: left 0.3s;
        position: relative !important;
      }
    }
  }

  .checkType-checkbox {
    &:last-of-type {
      label {
        border: none;
      }
    }
  }

  .checkType-checkbox input + label:before {
    border: none;
    background: #e4e4e4;
  }

  .checkType-checkbox input:checked + label:before {
    background: url("../assets/images/filter-tick.svg");
    background-size: 8px !important;
    background-repeat: no-repeat;
    z-index: 1;
    background-position: center;
  }

  .checkType-checkbox input:checked + label::after {
    background: linear-gradient(to right, #ff8177, #b22b5b);
    content: "";
    position: absolute;
    top: 0;
    width: 18px;
    height: 18px;
    transition: all 0.2s;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    margin: auto;
    right: 0;
    color: #fff;
    background-position: center;
  }
}

.slected-options-details {
  margin-left: auto;
  margin-right: 10px;

  @media (max-width: 767px) {
    margin-right: 0px;
  }

  span {
    background-color: #c6c6c6;
    padding: 2px 8px;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    border-radius: 5px;
    margin-left: 3px;
    display: inline-block;
    text-transform: capitalize;

    @media (max-width: 767px) {
      font-size: 14px;
      padding: 1px 5px;
    }
  }
}

.selected-option-ttoltip {
  font-size: 14px;

  b {
    display: block;
  }
}

.mob-hidden {
  @media (max-width: 768px) {
    display: none;
  }
}

.grpCheck {
  padding: 0px;

  .grpCheck-head {
    padding: 0px 22px;
    border-bottom: 1px solid rgba(124, 124, 124, 0.2509803922);
  }

  .grpCheck-body {
    padding: 0px 22px;

    label {
      border: none;
      word-break: break-all;
      padding-right: 20px;
    }
  }
}

.locationFilter {
  .checkType-checkbox {
    display: inline-flex;
    margin-right: 8%;
    width: auto !important;

    @media (max-width: 768px) {
      margin-right: 0;
    }

    label {
      border: none;
    }
  }

  .custom-input {
    margin-bottom: 30px;
  }

  .filterpopup-range {
    margin-top: 0;
  }

  .location-map-box {
    background: #f9f9f9;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    height: 220px;
    margin-bottom: 15px;
  }

  h5 {
    font-size: 14px;
    font-weight: 400;
    color: #7c7c7c;
    margin-bottom: 30px;
  }

  .exterior-select {
    .reactAsyncSelect__value-container {
      padding: 0;
      padding-left: 15px;
    }

    input {
      min-height: 40px;
      border: 1px solid #e4e4e4;
      outline: inherit;
      padding: 4px 20px;
    }

    .reactAsyncSelect__control {
      border-color: #e4e4e4;
    }

    .reactAsyncSelect__input-container {
      padding: 0;
      margin: 0;

      .reactAsyncSelect__input {
        text-transform: uppercase;
        width: auto !important;
      }
    }

    .light__single-value,
    .reactAsyncSelect__single-value {
      font-weight: 700;
      color: #353333;
      font-size: 14px;
      padding: 4px 20px;
      padding-left: 0px;
    }
  }
}

.viewcarsFilter {
  div {
    .checkType-checkbox {
      display: inline-flex;
      margin-right: 8%;

      label {
        border: none;
      }
    }
  }
}

.doorFilter {
  .sliderValue-wrp {
    display: none;
  }

  .sliderRange-wrp {
    display: none;
  }

  ul {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    li {
      font-weight: 700;
      font-size: 16px;
      color: #353333;
    }
  }
}

// Filter Modal

// Transfer Summary
.cmnCard {
  background: #f5f5f5;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 0px 46px 2px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 30px;

  @media (max-width: 767px) {
    border: none;
  }
}

.cmnCard-details {
  border: 1px solid #e8e8e8 !important;
  border-radius: 5px;
  padding: 30px;
  background-color: #fff;

  @media (max-width: 769px) {
    padding: 10px;
  }
}

.transferDetails {
  @media (max-width: 767px) {
    padding: 10px;
    box-shadow: none;
  }
}

.transferItem-card {
  display: flex;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 30px;
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding-bottom: 0;
    margin-bottom: 15px;
    border-bottom: none;
  }

  ul {
    li {
      padding: 10px 0;
      display: flex;
      align-items: center;
      border-bottom: 0.7px dashed #9d9d9d;

      @media screen and (max-width: 420px) {
        flex-direction: column;
        gap: 10px;
      }

      &:last-child {
        border-bottom: none;
      }

      .remove-bg {
        background: #dbdbdb;
        padding: 10px;

        @media (max-width: 420px) {
          margin: auto;
        }
      }

      figure {
        margin-right: 10px;
        width: 50px;
        height: 50px;
        display: flex;
        background: linear-gradient(to right, #ff8177, #b22b5b);
        border-radius: 50%;
        overflow: hidden;
        min-width: 50px;
        max-width: 50px;
        min-height: 50px;
        max-height: 50px;

        @media (max-width: 420px) {
          margin: auto;
        }

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      .figCaption {
        width: 100%;

        h6 {
          font-weight: 700;
          font-size: 14px;
          color: #353333;
          margin: 3px 10px 3px 0px;

          @media (max-width: 480px) {
            margin: auto;
            text-align: center;
          }
        }

        h5 {
          font-weight: 400;
          font-size: 14px;
          text-transform: uppercase;
          color: #666666;
          margin: 3px 0;

          @media (max-width: 480px) {
            margin: auto;
            text-align: center;
            margin-top: 3px;
          }
        }
      }

      .transfermix-name {
        display: flex;
        justify-content: space-between;
      }

      .transferPay {
        display: flex;
        align-items: center;
        margin-left: auto;

        @media (max-width: 480px) {
          margin: auto;
        }

        h4 {
          font-weight: 700;
          font-size: 12px;
          text-transform: uppercase;
          color: #f27b79;
          margin: 0;
        }

        h3 {
          font-weight: 700;
          font-size: 14px;
          color: #353333;
          margin: 0;
        }

        .carIcon {
          width: 37px;
          height: 37px;
          background: #ffffff;
          border: 1px solid #cdcdcd;
          @include flex-center();
          border-radius: 50%;
          margin-left: 10px;
          flex: none;
        }
      }
    }
  }
}

.tcarDt {
  .carIcon {
    padding: 10px;
    background: #ffffff;
    border: 1px solid #cdcdcd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 10px;
    flex: none;
  }
}

.userTransfer-details {
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 20px;
  flex: 1;

  @media (max-width: 1024px) {
    padding-bottom: 35px;
    border-bottom: 1px solid #f5f5f5;
    border-radius: 15px 15px 0 0;
  }
}

.traderDetails {
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 20px;
  flex: 1;

  @media (max-width: 1024px) {
    margin-top: -26px;
    padding-top: 35px;
  }

  @media (max-width: 767px) {
    margin-top: -30px;
    background: #e4e4e4;
    border-radius: 0 0 15px 15px;
  }

  .figCaption {
    align-items: center;
    width: 100%;

    .ratingStars-grp {
      margin-left: 0;

      li {
        all: unset;
        margin-right: 5px;
      }
    }
  }
}

.transferMethod {
  background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
  border-radius: 5px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 30px;
  flex: none;
  flex-direction: column;

  @media (max-width: 1024px) {
    flex-direction: row;
    margin: auto;
    margin-top: -26px;
    z-index: 9;
    gap: 15px;
    width: fit-content;
    padding: 9px 15px;
    transform: translate(0px, -10px);
  }

  @media (max-width: 769px) {
    position: relative;
    padding: 7px 12px;
  }

  svg {
    @media (max-width: 1024px) {
      width: 18px;
      height: 18px;
      transform: rotate(90deg);
    }
  }

  h3 {
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 40px;

    @media (max-width: 1280px) {
      margin-top: 10px;
    }

    @media (max-width: 1024px) {
      margin-top: 0;
      display: flex;
      gap: 10px;
      font-size: 16px;
    }

    @media (max-width: 767px) {
      margin: 0;
      font-weight: 400;
      font-size: 12px;
    }

    span {
      display: block;

      @media (max-width: 767px) {
        display: inline-block;
      }
    }
  }

  h4 {
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;

    @media (max-width: 767px) {
      margin: 0;
      font-weight: 400;
      font-size: 12px;
    }

    span {
      display: block;

      @media (max-width: 767px) {
        display: inline-block;
      }
    }
  }
}

.signEmail-card {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: linear-gradient(105.64deg, #f46444 7.68%, #f48e19 109.47%);
  padding: 20px;
  margin-bottom: 20px;
  width: calc(100% / 2 - 15px);

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 15px;
    border-radius: 15px;
  }

  h4 {
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    margin: 8px 0;
  }

  h5 {
    font-weight: 400;
    font-size: 14px;
    color: #282828;
    margin: 0;

    button {
      font-weight: 700;
      font-size: 14px;
      color: #ffffff;
      margin-left: 12px;
      display: inline-flex;
      align-items: center;

      svg {
        margin-left: 10px;
      }
    }
  }
}

.sendBtn {
  min-width: 160px;
  height: 35px;
  background: #282828;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.mobFull-width {
    @media (max-width: 767px) {
      min-width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  @media (max-width: 767px) {
    border-radius: 20px;
    min-width: auto;
  }
}

span.button-check {
  width: 19px;
  height: 19px;
  background: linear-gradient(134.62deg, #60cf1c 9.91%, #c8cc00 96.66%);
  border-radius: 50%;
  margin-left: 15px;
  color: #fff;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payAmount-card {
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 5px 20px;
  width: calc(50% - 15px);

  @media (max-width: 767px) {
    width: 100%;
    border-radius: 15px;
  }

  .df-content-center-between {
    margin: 15px 0;
  }

  h4 {
    font-weight: 700;
    font-size: 28px;
    color: #282828;
    margin: 0;
  }

  h5 {
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    color: #f27b79;
    margin: 0;
  }

  .breakdownBtn {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #353333;
    margin: 0;
  }

  .blackBtn {
    min-width: 113px;
    min-height: 34px;
  }
}

.toggleSwitch {
  display: inline-flex;
  align-items: center;

  em {
    font-weight: 400;
    font-size: 16px;
    color: #353333;
    font-style: normal;
    margin-right: 10px;
  }

  label {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 16px;
    margin: 0;
  }

  label input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .trigger {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background: #ffffff;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.33);
    border-radius: 49px;
  }

  .trigger:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: -1px;
    bottom: -2px;
    transition: 0.4s;
    background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
    box-shadow: -1px 0px 3px rgba(0, 0, 0, 0.39);
    border-radius: 50%;
  }

  .trigger-disabled {
    background: #ffffff;
  }

  .trigger-disabled::before {
    background: grey;
  }

  input:checked + .trigger:before {
    transform: translateX(16px);
    background: linear-gradient(134.62deg, #60cf1c 9.91%, #c8cc00 96.66%);
  }
}

.serviceQst-card {
  width: calc(100% / 2 - 20px);
  margin-bottom: 20px;

  @media (max-width: 769px) {
    width: 100%;
    margin-top: 0px;
    background: none;
    box-shadow: none;
    padding: 0;
    margin-bottom: 10px;
  }

  li {
    padding: 2px 0;

    @media (max-width: 767px) {
      border-bottom: none;
      padding: 5px 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.toggleField {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
    font-size: 16px;
    color: #353333;
  }
}

.logBook-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% / 2 - 20px);
  margin-bottom: 20px;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  padding: 22px;
  background: #ffffff;
  box-shadow: none;

  @media (max-width: 769px) {
    padding: 10px;
    width: 100%;
    padding-left: 14px;
  }

  .logBtn {
    font-weight: 700;
    font-size: 16px;
    color: #282828;
    display: flex;
    align-items: center;

    svg {
      margin-left: 10px;
      width: 30px;
      height: 30px;

      @media (max-width: 767px) {
        width: 20px;
        height: 20px;
      }

      path {
        stroke: #f15f5f;
      }
    }
  }

  .custom-btn {
    min-height: 50px;
    min-width: 125px;

    svg {
      transform: rotate(270deg);
      margin-left: 10px;
      width: 15px;
      height: 15px;

      path {
        stroke: white;
      }
    }

    @media (max-width: 769px) {
      min-height: 39px;
      padding: 5px 20px;
      min-width: 120px;
    }
  }
}

.logistic-card {
  margin: 30px 0;

  @media (max-width: 769px) {
    background: #fff;
    box-shadow: none;
    padding: 15px;
    margin-top: 20px;
  }

  .btnWrp {
    border-top: 1px solid #dfdfdf;
    text-align: center;
    margin: 30px 0;
    padding-top: 15px;

    @media (max-width: 769px) {
      margin: 0 auto;
      border-top: none;
    }

    .agreeBtn {
      margin: auto;
      min-width: 295px;

      @media (max-width: 767px) {
        min-width: 100%;

        span.button-check {
          margin-left: auto;
        }
      }
    }

    .mobFull-width {
      &:hover {
        background: #000 !important;
        cursor: default;
      }
    }

    .downloadBtn {
      svg {
        margin-right: 10px;
      }
    }

    .sendBtn {
      margin: auto;
      min-width: 125px;

      @media (max-width: 767px) {
        min-width: 100%;
      }
    }
  }
}

.map-box {
  margin-top: 40px;
  width: 100%;

  @media (max-width: 769px) {
    margin: 0;
  }
}

.logisticDetails-wrp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap;

  @media (max-width: 769px) {
    flex-direction: column;
  }

  h6 {
    font-weight: 400;
    font-size: 14px;
    color: #7c7c7c;

    margin-bottom: 12px;

    strong {
      color: #282828;
    }
  }

  .dvder {
    margin: 20px 0;
  }
}

.cancelBtn {
  @media (max-width: 767px) {
    margin-top: 15px;
    min-width: 88px;
    min-height: 28px;
  }
}

.logisticAddress {
  width: 46%;

  @media (max-width: 769px) {
    width: 100%;
  }

  h3 {
    font-weight: 700;
    font-size: 16px;
    color: #353333;

    margin-bottom: 5px;
  }

  h5 {
    font-weight: 400;
    font-size: 14px;
    color: #282828;

    button {
      float: right;
      color: #000;
    }
  }
}

.logisticMethod {
  width: 46%;

  @media (max-width: 769px) {
    width: 100%;
  }

  .radio-button {
    margin-right: 40px;
    margin-top: 10px;
    font-weight: 600;
    color: #000;

    &:last-child {
      margin-right: 0;
    }
  }

  input {
    left: 0;
  }

  .disabled {
    cursor: default !important;

    input {
      cursor: default !important;
    }
  }

  h5 {
    font-weight: 600;
    font-size: 16px;
    color: #282828;
    margin-bottom: 18px;
  }
}

.disabled-loc-btn {
  cursor: default !important;
}

.exchangeCar-status {
  width: calc(100% / 2 - 8px);
  border: none;

  @media (max-width: 769px) {
    width: 100%;
    padding: 10px;
  }

  h6 {
    font-weight: 400;
    font-size: 16px;
    color: #f27b79;
    margin: 0;

    span {
      font-size: 14px;
      float: right;
      color: #353333;
    }
  }

  h5 {
    font-weight: 400;
    font-size: 16px;
    color: #7c7c7c;
    margin: 20px 0 3px;
  }

  .toggleField {
    padding: 0;
    margin-bottom: 5px;
  }

  .dvder {
    margin: 25px 0;
  }
}

.historySummary-logistic {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .logisticDetails-wrp {
    width: 47%;

    @media (max-width: 767px) {
      width: 100%;
    }

    .logisticAddress {
      width: 100%;
    }

    .logisticMethod {
      width: 100%;

      @media (max-width: 767px) {
        .d-none {
          display: block !important;
        }
      }
    }
  }

  .exchangecarStatus-wrp {
    display: block;
    width: 47%;
    margin-top: 30px;

    @media (max-width: 767px) {
      width: 100%;
      margin-top: 0;
    }

    .exchangeCar-status {
      width: 100%;
      padding: 0;
      box-shadow: none;

      &:first-child {
        .d-web-none {
          display: block !important;
        }
      }
    }
  }
}

.logModal.modal-wrap {
  .modal-head {
    h5 {
      font-size: 17px;
      color: #f27b79;
    }
  }
}

.editLocation-modal.modal-wrap {
  .mob-bg {
    background-position: 0px 84px;
  }

  .modal-body {
    text-align: left;
    background-color: #ffffff;

    .custom-input input {
      background-color: white;
    }
  }

  .modal-head {
    margin-bottom: 30px;
  }

  .modal-foot {
    @media (max-width: 767px) {
      .outline-btn {
        background: #282828;
        color: #fff;
        width: 50%;
      }

      .saveBtn {
        background: $lineargradient;
        width: 50%;
      }
    }
  }

  .custom-btn {
    min-width: 49%;
  }
}

.breakdown-modal.modal-wrap {
  .mob-bg {
    background-position: 0px 120%;
  }

  .modal-body {
    text-align: left;

    .custom-input input {
      background-color: #fff;
    }

    h4 {
      font-weight: 400;
      font-size: 14px;
      color: #7c7c7c;
      margin-bottom: 15px;
      @include flex-between();

      span {
        font-size: 18px;
        color: #353333;
      }
    }

    .dvder {
      margin: 0px 0 20px 0;
    }
  }

  .modal-head {
    margin-bottom: 30px;
  }
}

.insurance-modal.modal-wrap {
  .mob-bg {
    background-position: 0px 120%;
  }

  .modal-body {
    text-align: left;

    .custom-input input {
      background-color: white;
    }
  }

  .modal-head {
    margin-bottom: 20px;
  }

  .modal-foot {
    @media (max-width: 767px) {
      .outline-btn {
        background: #282828;
        color: #fff;
        width: 50%;
      }

      .saveBtn {
        background: $lineargradient;
        width: 50%;
      }
    }
  }

  .custom-btn {
    min-width: 50%;
  }
}

// Transfer Summary
.chat-ttips {
  &::after {
    width: auto !important;
    text-transform: capitalize !important;
  }
}

.tooltip-button {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #d9d9d9;
    top: 24px;
    right: 2px;
    opacity: 0;
    pointer-events: none;
  }

  &::after {
    background: #d9d9d9;
    border-radius: 5px;
    color: #585858;
    content: attr(data-title);
    font-size: 14px;
    padding: 5px 14px 5px;
    position: absolute;
    top: calc(100% + 8px);
    font-weight: 400;
    right: auto;
    left: 50%;
    transform: translate(-50%);
    z-index: 1111;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    width: 245px;
  }

  &:hover::before,
  &:hover::after {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &.tooltip-right {
    &::after {
      right: auto;
      transform: translate(0%);
      top: 0;
      left: 100%;
    }
  }

  &.tooltip-bottom-left {
    &::after {
      right: -6px;
      left: auto;
      transform: translate(0%);
    }
  }
}

.info-tooltip {
  svg {
    width: 20px;
    color: #545454;
    display: block;
    height: 20px;

    path {
      stroke: #545454 !important;
    }
  }
}

// upload Photos
.video-player {
  display: block;
  width: 100%;
  height: 100%;
}

// sadiq

// Car Valuation Check

.borderCard {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 0px 46px 2px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 30px;
}

.left-select {
  .select .light__single-value,
  .select .light__placeholder {
    justify-content: flex-start !important;
  }

  .light__input {
    min-height: 100%;
  }

  .light__option--is-focused,
  .light__option--is-selected {
    color: hsl(0deg 0% 0%);
    background: #ededed;
  }
}

.valuationCheck-warp {
  .custom-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 4%;

    .valueRegistration-wrp {
      width: 46%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .valueField-wrp {
      width: 50%;

      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 20px;
      }
    }

    .custom-input {
      margin-bottom: 15px;
    }

    h4 {
      font-size: 16px;
      font-weight: $regularText;
      color: $lightgreyText;
      margin-bottom: 30px;

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }

      span {
        display: block;
        font-size: 30px;
        color: $darkgreyText;
        font-weight: $boldText;
        margin-top: 8px;
        text-transform: uppercase;

        @media screen and (max-width: 1199px) {
          font-size: 26px;
        }

        @media screen and (max-width: 1024px) {
          font-size: 24px;
        }
      }
    }

    .country-flag {
      background: $orangGradient;
      padding: 22px;
      margin-bottom: 0;
      width: 100%;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
      border-radius: 5px;

      label {
        color: $whiteText;
      }

      .position-relative {
        border-radius: 2px;
        border: 3px solid rgba(255, 255, 255, 0.13);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
      }

      input {
        border-radius: 2px;
        font-size: 26px;

        &::placeholder {
          font-size: 26px;
        }
      }
    }
  }

  .skip {
    margin-top: 25px;
    font-size: 14px;
    font-weight: $regularText;
    color: #1e1e1e;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 767px) {
      color: $whiteText;
      justify-content: center;
    }
  }
}

.valuationCheck-content {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;

  .borderCard {
    width: 61%;

    @media screen and (max-width: 1199px) {
      width: 100%;
    }
  }
}

.marketingContent-card {
  background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
  border: 1px solid #e8e8e8;
  box-shadow: 0px 0px 46px 2px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 50px;
  width: 37%;
  margin-bottom: 30px;

  @media screen and (max-width: 1199px) {
    width: 100%;
  }

  h4 {
    font-weight: 400;
    font-size: 26px;
    color: #ffffff;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    color: #ffedf6;
  }
}

// Car Valuation Check

// car valuation
.carValuation-wrap {
  .content-box {
    border: none;

    .head-wrap {
      border-bottom: none;
    }
  }

  .content-wrap {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
  }

  .head-wrap {
    @media screen and (max-width: 767px) {
      padding: 0 0 5px;
    }

    h3 {
      font-size: 22px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 0;
      color: #353333;

      @media screen and (max-width: 767px) {
        font-size: 24px;
      }

      span {
        font-size: 33px;
        background: -webkit-linear-gradient(#20cf1c, #cc9f00);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: $boldText;
        display: block;

        @media screen and (max-width: 767px) {
          font-size: 39px;
        }
      }
    }
  }

  .valuation-content {
    @include flex-center;
    padding: 0 40px;

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }

    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }

  .carValue-wrap {
    background: linear-gradient(to bottom right, #20cf1c, #cc9f00);
    width: 100%;
    padding: 55px 40px 40px;
    text-align: center;
    z-index: 1;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    @include flex-between;

    @media screen and (max-width: 991px) {
      margin-bottom: 30px;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      border-radius: 40px;
      padding: 30px;
      margin-bottom: 15px;
    }

    .car-price {
      @media screen and (max-width: 767px) {
        margin-bottom: 25px;
      }

      .tick-icon {
        width: 61px;
        height: 61px;
        border-radius: 50%;
        @include flex-center;
        background-color: $whitebg;
        margin: 0 auto 30px;

        @media screen and (max-width: 767px) {
          margin: 0 auto 15px;
        }
      }

      .price {
        margin-bottom: 0;
        font-size: 36px;
        font-weight: $regularText;
        color: $whiteText;
      }
    }

    .edit-value {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    p {
      font-size: 14px;
      font-weight: $regularText;
      color: $whiteText;
      margin-bottom: 35px;

      @media screen and (max-width: 767px) {
        font-size: 12px;
        margin-bottom: 20px;
      }
    }

    .edit {
      font-size: 16px;
      font-weight: $regularText;
      color: #ffffff;
      margin-bottom: 10px;
      cursor: pointer;
      @include flex;

      @media screen and (max-width: 767px) {
        justify-content: center;
        color: #ffef99;
        font-size: 14px;
        text-align: center;
        width: 100%;
      }

      span {
        margin-right: 5px;
        display: block;
        color: #ffef99;
      }
    }

    .custom-btn {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .addExtra-wrap {
    width: 100%;
    padding-left: 30px;

    @media screen and (max-width: 991px) {
      padding-left: 0;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .add-extra {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 767px) {
      justify-content: flex-end;
      padding: 10px 0 30px;
      margin-bottom: 0;
    }

    p {
      font-size: 16px;
      font-weight: $regularText;
      color: $lightgreyText;
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        font-size: 12px;
      }

      span {
        font-size: 16px;
        font-weight: $boldText;
        color: $darkgreyText;
        display: block;

        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }

    .action {
      width: 32px;
      height: 32px;
      min-width: 32px;
      background-color: $blackbg;
      border-radius: 50%;
      @include flex-center;
      border: none;
      padding: 0;
      margin-left: 15px;

      @media screen and (max-width: 767px) {
        border-radius: 50%;
      }

      svg {
        fill: $whitebg;
        font-size: 18px;
      }
    }
  }

  .extraItem-btn {
    background-color: #353333;
    border-radius: 40px;
    font-size: 14px;
    font-weight: $regularText;
    color: $whiteText;
    padding: 12px 25px;
    @include flex-between;
    width: 100%;
    margin-bottom: 15px;

    span {
      @include flex;
    }
  }

  .trade-value {
    width: 100%;
    background: $purpleGradient;
    padding: 15px 35px;
    border-radius: 5px;
    margin: 0 auto;
    @include flex-between;
    box-shadow: 0 4px 10px 0px rgb(0 0 0 / 25%);
    margin-bottom: 30px;

    @media screen and (max-width: 767px) {
      padding: 12px 25px;
      width: 100%;
      border-radius: 50px;
    }

    p {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: $regularText;
      color: $whiteText;
      padding-right: 5px;
    }

    h6 {
      margin-bottom: 0;
      font-size: 22px;
      font-weight: $boldText;
      color: $whiteText;
    }
  }

  .quick-sale {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p {
      font-size: 14px;
      font-weight: $regularText;
      color: #585858;
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        text-align: left;
        color: $whiteText;
        font-size: 12px;
      }
    }

    .quickSale-btn {
      min-width: 118px;
      height: 42px;
      color: #ffffff;
      font-size: 13px;
      font-weight: 700;
      margin-left: 15px;
      background: linear-gradient(180deg, #ff0000 0%, #ff0000 50.52%, #d20000 100%);
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      @include flex-center;

      span {
        padding-right: 5px;
        @include flex-center;
      }

      @media screen and (max-width: 767px) {
        border-radius: 50px;
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }

  .info-tooltip svg {
    path {
      stroke: #ffffff;
    }
  }

  .tooltip-button.tooltip-bottom-left::after {
    @media screen and (max-width: 767px) {
      left: 0;
      transform: translate(-50%);
      right: 0;
    }
  }

  .add-extraItem {
    display: flex;
    align-items: center;
    padding: 0px 40px 25px 40px;
    margin-top: 30px;
    width: 100%;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 0;
      border: none;
      margin-top: 0;
    }

    h6 {
      font-size: 16px;
      font-weight: $regularText;
      color: $darkgreyText;
      margin: 0;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .item-list {
      @include flex;
      padding-left: 40px;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding-left: 0;
        width: 100%;
      }

      li {
        background-color: #e9e9e9;
        @include flex-between;
        padding: 10px 12px;
        margin: 0 8px;
        border-radius: 5px;

        @media screen and (max-width: 767px) {
          background-color: transparent;
          width: 100%;
          border-bottom: 1px solid #e8e8e8;
          padding: 15px 0;
          margin: 0;
          border-radius: 0;
        }

        p {
          font-size: 16px;
          font-weight: $regularText;
          color: $darkgreyText;
          margin: 0;

          @media screen and (max-width: 767px) {
            font-size: 14px;
            color: #535353;
          }
        }

        .action {
          width: 22px;
          height: 22px;
          background-color: $blackbg;
          border-radius: 50%;
          @include flex-center;
          border: none;
          padding: 0;
          margin-left: 20px;

          @media screen and (max-width: 767px) {
            width: 20px;
            height: 20px;
          }

          svg {
            fill: $whitebg;
            font-size: 14px;
          }
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;

          @media screen and (max-width: 767px) {
            border-bottom: 0;
          }
        }
      }
    }
  }

  .update-item {
    @include flex-between;
    padding: 40px 40px 0;
    width: 100%;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 0;
    }

    .add-extra {
      margin-bottom: 0;
      align-items: center;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      p {
        text-align: end;
        font-size: 12px;

        span {
          font-size: 14px;
        }
      }

      .action {
        width: 30px;
        height: 30px;
        min-width: 30px;
      }
    }

    .custom-btn {
      min-width: 306px;

      @media screen and (max-width: 991px) {
        min-width: 260px;
      }

      @media screen and (max-width: 767px) {
        min-width: 100%;
      }
    }
  }

  .save-extra {
    border-top: 1px solid #e8e8e8;
    justify-content: flex-start;
    padding: 30px 40px 0px 40px;
    margin: 0;

    @media screen and (max-width: 767px) {
      padding: 10px 0 30px;
    }

    .custom-btn {
      margin-left: auto;
    }

    .action {
      margin-left: 0;
      margin-right: 15px;
    }
  }
}

// car valuation

// QR Popup
.QrModal {
  .modal-content {
    overflow: initial;
  }

  .modal-body {
    background: #f6f6f6;
  }

  h6 {
    position: relative;
    width: 60%;
    margin: 0 auto 20px;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background-color: #c8c8c8;
    }

    span {
      background-color: #f6f6f6;
      position: relative;
      z-index: 1;
      padding: 0 8px;
    }
  }

  h5 {
    font-size: 14px;
    font-weight: 400;
    color: #626262;
    margin: 0;
  }
}

.qr-wrp {
  width: 155px;
  margin: 0 auto 20px;
  padding: 5px 15px;
  background: #fff;
  display: flex;
  align-items: center;
}

.storeBtn-grp {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 11px;
  margin: 20px 0;
}

// QR Popup

// Car Details

.carDetails-wrap {
  .borderCard {
    border: none;
    margin: 0;

    h4 {
      font-weight: 400;
      font-size: 16px;
      text-transform: uppercase;
      color: #282828;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }
  }

  .custom-form {
    display: flex;
    flex-wrap: wrap;
    gap: 4%;

    .vehicle-image {
      width: 48%;
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: 15px;

      @media screen and (max-width: 767px) {
        width: 100%;
        border-radius: 20px;
      }

      img {
        max-width: 100%;
      }
    }

    .vehicle-details {
      width: 100%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .custom-input {
      margin-bottom: 15px;
    }
  }

  .choose-file {
    margin-bottom: 30px;

    label {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  .custom-btn {
    width: 100%;
    margin-top: 15px;
  }

  .action-btn {
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .custom-btn {
      width: 240px !important;
      margin: 0;
    }
  }

  .vehicle-details {
    h3 {
      font-weight: 700;
      font-size: 12px;
      color: #353333;
      margin: 20px 0 10px;
    }

    h5 {
      color: #7c7c7c;
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 30px;

      span {
        color: #f07071;
      }
    }
  }
}

.mandatory-star {
  color: red;
}

// Car Details

// Add Description

.addDescription {
  .borderCard {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-shadow: 0px 0px 46px 2px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 30px;
  }

  .custom-form .vehicle-image {
    border: 1px solid #ececec;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
  }

  .custom-btn {
    width: auto;
    margin-left: 0px;
  }
}

// Add Description

// car details page
.carDetailsPage-wrap {
  .borderCard {
    border: none;
  }

  .exterior-grade {
    margin-bottom: 10px;
  }

  .custom-tab {
    @media screen and (max-width: 767px) {
      height: calc(100% - 42px);
    }

    .content {
      padding: 20px 0 0;

      @media screen and (max-width: 767px) {
        height: calc(100% - 29px);
      }

      // do not delete this
      .title {
        color: #353333;
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 15px;
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 30px;
        display: block;
        width: 100%;
      }

      .tab-content {
        height: 100%;
      }
    }

    .hpi-history {
      @media screen and (max-width: 767px) {
        height: 100%;
        @include flex-between;
        flex-direction: column;
      }

      .history {
        width: 100%;

        .view-btns {
          @media (max-width: 520px) {
            margin-top: 15px !important;
          }
        }
      }

      .history-list {
        flex-wrap: wrap;
        margin: 0 -8px;

        @media screen and (max-width: 767px) {
          margin: 0 -5px;
        }

        .px-custom {
          padding: 0 8px;

          @media screen and (max-width: 767px) {
            padding: 0 5px;
          }
        }
      }

      .hpi-check-input {
        align-items: flex-end;
        margin-bottom: 25px;

        .custom-input {
          margin-bottom: 0;
        }
      }
    }

    .mot-history {
      height: 100%;
      @include flex-between;
      flex-direction: column;

      .history {
        width: 100%;
      }

      .d-flex {
        margin: 0 -15px;
        flex-wrap: wrap;

        @media screen and (max-width: 767px) {
          margin: 0 -7px;
        }

        .pink-btn {
          @media screen and (max-width: 767px) {
            margin-bottom: 25px;
          }
        }

        .px-custom {
          @media screen and (max-width: 767px) {
            padding: 0 7px;
          }
        }
      }

      .pink-btn {
        margin-bottom: 20px;
      }
    }

    .button-group {
      margin-top: 30px;
      justify-content: flex-end;
      width: 100%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      button {
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }

  .action-btn {
    gap: 20px;
  }
}

// car details page
.label {
  font-size: 14px;
  color: #7c7c7c;
  font-weight: 400;
  white-space: nowrap;
}

.upload-images {
  margin-bottom: 40px;

  @media screen and (max-width: 767px) {
    @include flex;
    flex-direction: column-reverse;
    margin-bottom: 30px;
  }

  .image-wrap {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 767px) {
      margin-bottom: 10px;
      gap: 10px;
    }

    li {
      width: 100%;

      .upload-img {
        height: 93px;
        border-radius: 5px;
        background-color: rgb(228 228 228 / 30%);
        @include flex-center;
        position: relative;
        overflow: hidden;

        @media screen and (max-width: 767px) {
          height: 45px;
        }

        .uploaded-img {
          @include full-space;
          @include object-fit;
        }

        .close-icon {
          position: absolute;
          top: 7px;
          right: 7px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: $whitebg;
          @include flex-center;
          cursor: pointer;

          svg path {
            stroke: $blackText;

            @media screen and (max-width: 767px) {
              stroke: $whiteText;
            }
          }
        }

        &.no-img {
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(../assets/images/no-img.svg);
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: center;

            @media screen and (max-width: 767px) {
              background-size: 14px;
            }
          }
        }
      }
    }
  }

  .choose-file {
    margin-bottom: 20px;

    .custom-file-input {
      padding: 40px 30px;
      background: linear-gradient(90deg, rgba(255, 129, 119, 0.03) 0%, rgba(178, 43, 91, 0.03) 100%);

      @media screen and (max-width: 767px) {
        border-radius: 5px !important;
        background: $lineargradient;
      }

      &::after {
        @media screen and (max-width: 767px) {
          content: "Click here to add image";
          color: #fff;
          font-size: 12px;
          font-weight: $regularText;
          width: 100%;
          overflow: hidden;
          outline: none;
          -webkit-user-select: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }
      }

      &::before {
        @media screen and (max-width: 767px) {
          top: -4px;
        }
      }
    }

    label {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  p {
    font-size: 14px;
    color: #7c7c7c;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
  }

  .label {
    .required {
      color: red;
    }
  }
}

// car details page

// service history
.serviceHistory-wrap {
  .title-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    h5 {
      font-size: 18px;
      font-weight: 700;
      color: #282828;
      text-align: left;
    }
  }

  .custom-input {
    margin-bottom: 20px;
  }

  .choose-file {
    margin-bottom: 30px;
    border: 1px dashed #b22b5b;
    background-color: #fefafb;
    padding: 10px 5px;
    border-radius: 4px;

    .custom-file-input {
      border-radius: 5px;

      &::after {
        @media screen and (max-width: 767px) {
          content: "Click here to add image";
          color: #fff;
          font-size: 12px;
          font-weight: $regularText;
          width: 100%;
          overflow: hidden;
          outline: none;
          -webkit-user-select: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }
      }

      &::before {
        @media screen and (max-width: 767px) {
          top: -4px;
        }
      }
    }
  }

  .upload-images {
    display: block;
    margin-bottom: 10px;

    .image-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      li {
        width: calc(100% / 6 - 10px);

        @media (max-width: 1280px) {
          width: calc(100% / 5 - 10px);
        }

        @media (max-width: 1199px) {
          width: calc(100% / 4 - 10px);
        }

        @media (max-width: 767px) {
          width: calc(100% / 2 - 10px);
        }

        .upload-img {
          height: 90px;
          width: 100%;
          border: solid 1px #ccc;

          .uploaded-img {
            opacity: 0;
            /* For remove double image*/
          }
        }
      }
    }
  }

  .uploadImg-close-icon {
    .close-icon {
      width: 24px !important;
      height: 24px !important;
      background-color: transparent !important;
      border: none !important;
      cursor: pointer;

      svg {
        fill: #f44335;
        stroke: transparent;

        path {
          stroke: transparent !important;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .custom-btn {
      width: 100%;
      margin-top: 30px;
    }
  }
}

// service history

// Need Finance
.needFinance-wrp {
  .borderCard {
    border: none;
  }

  .checkbox-wrap {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 767px) {
    .custom-btn {
      min-width: 50%;
    }
  }
}

.financeRange {
  h6 {
    font-weight: 400;
    font-size: 14px;
    color: #282828;
  }

  .sliderValue-wrp {
    justify-content: flex-end;

    .slider__right-value {
      font-weight: 700;
      font-size: 16px;

      color: #353333;
    }
  }

  .sliderTrack-wrp {
    .slider__track,
    .slider__range {
      border-radius: 8px;
      height: 8px;
    }

    .slider__range {
      background: linear-gradient(90deg, #40b9b9 0%, #025cc7 100%);
      z-index: 2;
    }
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.38);
    height: 18px;
    width: 18px;
    margin-top: 6px;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.38);
    height: 18px;
    width: 18px;
    margin-top: 6px;
    pointer-events: all;
    position: relative;
  }
}

.service-next {
  width: 150px;
  height: 45px;
  margin-top: 30px;
}

// Need Finance

// Payment

.payment-wrp {
  .borderCard {
    @media (min-width: 768px) {
      padding: 50px 40px;
    }
  }

  h4 {
    font-weight: 400;
    font-size: 16px;
    color: #7c7c7c;
    margin-bottom: 30px;
  }

  .paymentContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .coupen-wrp {
      width: 40%;

      @media (max-width: 991px) {
        width: 50%;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 30px;
      }

      .custom-input {
        display: flex;
        margin-bottom: 10px;

        .custom-btn {
          background: #ff8f66;
          width: 130px;
          min-width: auto;
          margin-left: 15px;
          flex: none;

          @media (max-width: 767px) {
            width: 80px;
          }
        }
      }
    }

    .paymentSplits {
      width: 40%;

      @media (max-width: 767px) {
        width: 100%;
      }

      h6 {
        font-weight: 400;
        font-size: 16px;
        color: #7c7c7c;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;

        span {
          color: #353333;
        }
      }

      h5 {
        font-weight: 700;
        font-size: 18px;
        color: #353333;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;

        span {
          font-size: 24px;
        }
      }
    }
  }

  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.coupenStatus {
  font-weight: 400;
  font-size: 14px;

  &.success {
    color: #4caf50;
  }

  &.failed {
    color: red;
  }
}

.coupenTags {
  display: flex;
  margin-top: 20px;

  span {
    background: #e3eaeb;
    border-radius: 5px;
    font-weight: 400;
    font-size: 12px;
    color: #3b3b3b;
    display: inline-flex;
    align-items: center;
    padding: 6px 10px 6px 6px;

    & + span {
      margin-left: 10px;
    }

    img {
      margin-right: 5px;
    }

    button {
      width: 10px;
      display: flex;
      align-items: center;
      margin-left: 25px;
    }
  }
}

.modal-wrap.confirm-modal {
  .modal-foot {
    .custom-btn {
      min-width: 40%;
      transition: all 0.5s ease-in-out;

      &:hover {
        background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
        border: 1px solid transparent;
      }
    }
  }
}

.gotoBtn {
  font-weight: 700;
  font-size: 14px;
  color: #6a6a6a;
  margin-top: 20px;
}

// Payment

// History

.history-wrp {
  .transferItem-card {
    border-bottom: none;
    padding-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    .title-wrp {
      display: block;
    }

    .search-box {
      margin-bottom: 0px;
    }
  }
}

.history-search-wrp {
  form {
    display: flex;
    align-items: center;

    .custom-btn {
      margin-left: 17px;
      min-width: auto;
      text-transform: capitalize;
      flex: none;

      svg {
        margin-right: 8px;

        path {
          fill: #7c7c7c;
          stroke: #7c7c7c;
        }
      }
    }
  }
}

// History

// About Us

.aboutus-wrp {
  .borderCard {
    display: flex;
    flex-wrap: wrap;
    border: none;

    figure {
      width: 30%;
      text-align: center;

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 30px;
      }

      img {
        max-width: 100%;
      }
    }

    .about-description {
      width: 70%;

      @media (max-width: 767px) {
        width: 100%;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        color: #353333;
        margin-bottom: 25px;
      }
    }
  }
}

// About Us

// Settings
.settings-wrp {
  .borderCard {
    border: none;
  }

  h6 {
    font-weight: 700;
    font-size: 14px;
    color: #353333;
  }

  h5 {
    font-size: 14px;
    color: #353333;
    font-weight: 400;
    padding-bottom: 5px;
    margin-top: 15px;
  }

  .dvder {
    margin-bottom: 10px;
  }

  .toggleField p {
    font-size: 14px;
  }
}

// Settings

// Share experience

.shareExp-wrp {
  h5 {
    font-weight: 400;
    font-size: 16px;
    color: #7b7b7b;
    margin-bottom: 50px;
  }

  h6 {
    font-size: 16px;
    color: #7c7c7c;
    margin-bottom: 15px;
  }

  .borderCard {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    figure {
      width: 30%;
      text-align: center;

      @media (max-width: 1199px) {
        width: 50%;
      }

      @media (max-width: 1199px) {
        width: 100%;
      }
    }

    .ratingSection {
      width: 30%;
      padding: 0 35px;

      @media (max-width: 1199px) {
        width: 50%;
      }

      @media (max-width: 1199px) {
        width: 100%;
        padding: 0;
      }
    }

    .custom-form {
      width: 40%;

      .button-wrap {
        display: flex;
        justify-content: space-between;

        button {
          width: 49%;
          min-width: auto;
        }
      }

      @media (max-width: 1199px) {
        width: 100%;
      }

      @media (max-width: 1199px) {
        width: 100%;
      }
    }
  }
}

.dealer-rating {
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 30px;

  h4 {
    font-weight: 700;
    font-size: 14px;
    color: #f27b79;
    margin-bottom: 6px;
  }

  img {
    max-width: 60px;
    margin-right: 15px;
  }

  .ratingStars-grp {
    margin-left: 0;
  }
}

.light__placeholder {
  color: #b3b3b3 !important;
  font-size: 14px;
  font-weight: 500;
  font-family: "PT Sans", sans-serif;
}

.edit-car-value {
  padding: 12px 0px !important;
}

.add-extra {
  .modal-foot {
    padding: 0px 13px !important;
    gap: 15px;

    @media (max-width: 769px) {
      flex-direction: column;
    }

    .custom-btn {
      margin-right: 0 !important;
      min-width: 170px;

      @media (max-width: 769px) {
        width: 100%;
      }
    }
  }
}

.description-placeholder {
  &::placeholder {
    color: $lightText;
    font-weight: $regularText;
  }
}

.curser-pointer-block {
  cursor: auto;
}

//nithin
.custom-list-style {
  margin-bottom: 15px;

  li {
    padding-left: 15px;
    position: relative;
    margin-bottom: 5px;

    @media (max-width: 767px) {
      margin-top: 10px;
      font-size: 14px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 8px;
      left: 0;
      margin: auto;
      height: 9px;
      width: 9px;
      background-color: #b22b5b;
      border-radius: 100%;

      @media (max-width: 767px) {
        top: 6px;
      }
    }
  }
}

.add-info {
  margin: 20px auto;

  p {
    color: #7c7c7c;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
}

// Share experience
// Nodata
.no-data {
  height: 65vh;

  span {
    margin: 15px 0px;
  }

  button {
    margin: 10px 0px;
  }
}

//copy url
.copy-action {
  display: flex;
  align-items: center;
  margin-top: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;

  .custom-input {
    width: 100%;
  }

  .copy-input {
    width: 100%;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .copy-icon {
    border: none;
    padding-right: 10px;
    cursor: pointer;
  }
}

.chat-avatar {
  position: relative;

  svg {
    background: #0000003b;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
  }
}

.open-to-swap-button {
  display: flex;
  width: 150px;

  @media (max-width: 520px) {
    width: 110px;
  }

  .darkpink-btn {
    min-width: 60px !important;
    line-height: 0;
    background: #000000bf;

    svg {
      width: 20px !important;

      path {
        stroke: #fff;
      }
    }

    span {
      margin-left: 5px;
    }
  }

  .open-to-swap {
    align-items: flex-end !important;
    padding-bottom: 30px;
    padding-bottom: 20px;
    position: absolute;
    bottom: 0 !important;
    gap: 0 10px;

    .open-to-swap-logo {
      position: relative;
      display: flex;
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
      top: 8px;
      left: 5px;

      svg {
        width: 20px !important;

        path {
          stroke: #fff;
        }
      }
    }

    .title {
      position: relative;
      bottom: 2px;
      white-space: nowrap;
      font-weight: 400;
      letter-spacing: 0.2px;
    }

    .prevt-hover:hover {
      background: #000000bf !important;
    }
  }
}

.like-share-button-wrap {
  align-items: flex-end !important;
  padding-bottom: 30px;
  padding-bottom: 20px;
  position: absolute;
  right: 20px;
  bottom: 0 !important;
  gap: 0 10px;

  @media (max-width: 767px) {
    align-items: center !important;
    bottom: 0 !important;
    padding: 10px;
    position: absolute;
    right: 0px;
    background: linear-gradient(#00000000, #000000bf);
    max-height: 60px;
    width: 100%;
    gap: 0;
    justify-content: flex-end !important;
  }

  .open-to-swap {
    font-size: 14px;

    @media (max-width: 520px) {
      font-size: 12px;
    }
  }

  .open-to-swap-logo {
    width: 16px !important;
    height: 16px !important;
  }

  .darkpink-btn {
    min-width: 60px !important;
    line-height: 0;
    background: #000000bf;

    @media (max-width: 767px) {
      background: transparent;
      padding: 5px !important;
      min-width: 40px !important;
    }

    svg {
      width: 20px !important;

      @media (max-width: 767px) {
        width: 14px !important;
      }

      path {
        stroke: #fff;
      }
    }

    span {
      margin-left: 5px;
    }
  }

  .prevt-hover:hover {
    background: #000000bf !important;
  }

  .like-button {
    &:hover {
      background: $lineargradient;
    }
  }
}

.like-overlay-like {
  background: #2cbd55 !important;
  box-shadow: 0 0 50px 15px #2cbd55;
}

.like-overlay-dislike {
  background: #cf2b2b !important;
  box-shadow: 0 0 50px 15px #cf2b2b;
}

.overlay-sold {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.3s;
}

.notification-item {
  overflow: hidden;

  .dot {
    width: 6px;
    height: 6px;
    min-width: 6px;
    min-height: 6px;
    border-radius: 100px;
    display: block;
    background: #fe4630;
  }

  .notification-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    word-break: break-word;
  }

  .message-content {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .read-more {
    font-weight: 500;
    color: #ed1155;
    padding-left: 5px;
  }

  .expanded {
    .read-more {
      display: none;
    }
  }

  .message-height {
    height: 40px;
  }
}

.btn-sold {
  pointer-events: none;
}

.btn-validation {
  pointer-events: none;
  width: auto !important;
}

.btn-validation-failed {
  background: #f44336;
  width: auto !important;
  pointer-events: auto !important;

  &:hover {
    background: #f44336;
  }
}

.agreeRefresh {
  margin: 0px 5px;
  border-radius: 50%;
  color: #fff;
  padding: 3px 9px;
  background: linear-gradient(45deg, #fa7c75, #b9335e);
}

.sidebar-menu {
  li {
    a {
      display: block;
    }
  }
}

.nav-highlight {
  color: #f7e9ee;
  background: #ffffff26;
  padding: 5px 8px;
  border-radius: 4px;
  display: block;
}

.signLink {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #8f8f8f;
  text-align: center;

  a {
    color: #1e1e1e;
    font-weight: 700;
  }
}

.transfersummary-wrp {
  .modal-foot.btn-group {
    @media (max-width: 520px) {
      flex-direction: column;
      gap: 10px;
    }
  }
}

// new sign in

.font-medium {
  font-weight: 500 !important;
}

.font-extrabold {
  font-weight: 800 !important;
}

.font-outfit {
  font-family: "Outfit", sans-serif;
}

.signin-container {
  width: 85%;
  padding: 0 15px;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.signin-header {
  background-color: #f9eaf0;
  padding: 40px 0;
  border-bottom: 1px solid #ed1155;

  @media (max-width: 1600px) {
    padding: 30px 0;
  }

  @media (max-width: 767px) {
    padding: 5px 0;

    .signin-logo {
      svg {
        width: 100px;
      }
    }
  }

  .signin-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ul {
      display: flex;
      align-items: center;
      gap: 25px;

      @media (max-width: 767px) {
        gap: 10px;
      }

      li {
        color: #ed1155;
        font-size: 20px;
        font-weight: 500;
        transition: all 0.3s;

        &:hover {
          color: #000;
          transition: all 0.3s;
        }

        @media (max-width: 1600px) {
          font-size: 16px;
        }

        @media (max-width: 767px) {
          font-size: 12px;
        }

        button {
          color: inherit;
        }
      }
    }
  }
}

.signin-footer {
  background-color: #ed1155;
}

.signin-footer-top {
  display: flex;
  padding: 60px 0 50px;
  gap: 10px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    padding: 30px 0;
    gap: 30px;
    text-align: center;
  }

  h4 {
    color: #f9eaf0;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 20px;

    @media (max-width: 1600px) {
      font-size: 26px;
    }

    @media (max-width: 1400px) {
      font-size: 22px;
    }

    @media (max-width: 767px) {
      font-size: 18px;
      margin-bottom: 10px;
    }

    span {
      font-weight: 800;
      display: block;
      margin-top: 5px;
    }
  }

  h3 {
    font-size: 48px;
    color: #f9eaf0;
    font-weight: 500;
    margin-bottom: 50px;

    @media (max-width: 1600px) {
      font-size: 40px;
    }

    @media (max-width: 1400px) {
      font-size: 35px;
    }

    @media (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 15px;
    }
  }

  p {
    font-size: 20px;
    color: #f9eaf0;

    @media (max-width: 1600px) {
      font-size: 18px;
    }

    @media (max-width: 1400px) {
      font-size: 16px;
    }

    @media (max-width: 767px) {
      font-size: 14px;
    }

    span {
      display: block;
      margin-bottom: 10px;

      @media (max-width: 767px) {
        margin-bottom: 0px;
      }
    }
  }

  .footer-contact {
    width: calc(100% / 3);

    @media (max-width: 767px) {
      width: 100%;
    }

    button {
      background-color: #f9eaf0;
      border-radius: 20px;
      font-size: 25px;
      color: #ed1155;
      font-weight: 500;
      padding: 13px 30px;
      transition: all 0.3s;

      &:hover {
        transition: all 0.3s;
        background-color: #000;
        color: #fff;

        svg {
          path {
            stroke: #fff;
            transition: all 0.3s;
          }
        }
      }

      @media (max-width: 1600px) {
        font-size: 20px;
      }

      @media (max-width: 1400px) {
        font-size: 18px;
      }

      @media (max-width: 767px) {
        font-size: 14px;
        padding: 8px 20px;
        border-radius: 15px;

        svg {
          width: 6px;
        }
      }

      svg {
        margin-left: 5px;

        path {
          transition: all 0.3s;
        }
      }
    }
  }

  .footer-getin-touch {
    width: calc(100% / 3);

    @media (max-width: 767px) {
      width: 100%;
    }

    h4 {
      margin-bottom: 30px;

      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
  }

  .footer-download-app {
    width: calc(100% / 3);

    @media (max-width: 767px) {
      width: 100%;
    }

    .store-links {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 70px;
      gap: 10px;

      @media (max-width: 767px) {
        margin-top: 0px;
        justify-content: center;
      }

      a {
        max-width: 45%;
        display: block;

        img {
          max-width: 100%;
          height: 59px;

          @media (max-width: 1400px) {
            height: 45px;
          }

          @media (max-width: 1199px) {
            height: 40px;
          }

          @media (max-width: 991px) {
            height: 35px;
          }
        }
      }
    }
  }
}

.signin-footer-bottom {
  border-top: 1px solid #f9eaf0;
  padding: 30px 0 60px;

  @media (max-width: 767px) {
    padding: 30px 0 30px;
  }

  .signin-footer-bottom-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-direction: column-reverse;
      gap: 20px;
    }

    ul {
      display: flex;
      align-items: center;
      gap: 60px;

      @media (max-width: 1199px) {
        gap: 25px;
      }

      @media (max-width: 767px) {
        gap: 10px;
        flex-wrap: wrap;
        justify-content: center;
      }

      li {
        font-size: 20px;
        color: #f9eaf0;
        font-weight: 500;
        border-bottom: 1px solid;
        transition: all 0.3s;

        &:hover {
          transition: all 0.3s;
          color: #000;
        }

        @media (max-width: 1600px) {
          font-size: 18px;
        }

        @media (max-width: 1400px) {
          font-size: 16px;
        }

        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
    }
  }

  p {
    font-size: 20px;
    color: #f9eaf0;
    font-weight: 500;

    @media (max-width: 1600px) {
      font-size: 18px;
    }

    @media (max-width: 1400px) {
      font-size: 16px;
    }

    @media (max-width: 991px) {
      font-size: 14px;
    }
  }
}

.new-signin {
  background-color: #f9eaf0;
  padding: 60px 0 0;

  @media (max-width: 767px) {
    padding: 30px 0 0;
  }

  .new-signin-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .new-signin-left {
    width: 60%;
    padding-right: 30px;

    @media (max-width: 1199px) {
      width: 55%;
    }

    @media (max-width: 991px) {
      width: 50%;
    }

    @media (max-width: 767px) {
      width: 100%;
      padding-right: 0;
    }

    figure {
      margin-left: calc(((100% - 85%) + 30px) * -1);

      img {
        max-width: 100%;

        @media (min-width: 1921px) {
          width: 75% !important;
        }
      }

      @media (max-width: 1199px) {
        margin-left: calc(((100% - 85%) + 35px) * -1);
      }

      @media (max-width: 767px) {
        display: none;
      }
    }

    h1 {
      font-size: 76px;
      color: #ed1155;
      font-weight: 700;
      margin-bottom: 20px;

      @media (max-width: 1600px) {
        font-size: 60px;
      }

      @media (max-width: 1400px) {
        font-size: 45px;
      }

      @media (max-width: 991px) {
        font-size: 22px;
      }
    }

    p {
      font-size: 32px;
      color: #ed1155;
      margin-bottom: 40px;

      @media (max-width: 1600px) {
        font-size: 25px;
      }

      @media (max-width: 1400px) {
        font-size: 20px;
      }

      @media (max-width: 1199px) {
        br {
          display: none;
        }
      }

      @media (max-width: 991px) {
        font-size: 18px;

        br {
          display: none;
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
  }

  .new-signin-right {
    width: 40%;
    padding-bottom: 90px;

    @media (max-width: 1199px) {
      width: 45%;
    }

    @media (max-width: 991px) {
      width: 50%;
      padding-bottom: 30px;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .new-form-wrap {
    width: 600px;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 30px;
    max-width: 100%;
    margin-left: auto;

    @media (max-width: 991px) {
      padding: 20px;
    }

    @media (max-width: 767px) {
      margin: auto;
    }

    h2 {
      color: #000;
      font-size: 25px;
      font-weight: 700;
      margin-bottom: 20px;

      @media (max-width: 1600px) {
        font-size: 22px;
      }

      @media (max-width: 991px) {
        font-size: 20px;
      }
    }

    p {
      color: #000;
      font-size: 20px;
      margin-bottom: 20px;

      @media (max-width: 1600px) {
        font-size: 18px;
      }

      @media (max-width: 991px) {
        font-size: 14px;
      }
    }

    .custom-input input {
      min-height: 52px;
      border-radius: 20px;
      border: 1px solid #ed1155;
      font-size: 20px;
      font-weight: 500;

      @media (max-width: 1600px) {
        font-size: 18px;
      }

      @media (max-width: 1199px) {
        font-size: 16px;
      }

      @media (max-width: 991px) {
        font-size: 14px;
        min-height: 40px;
      }

      @media (max-width: 767px) {
        &::placeholder {
          font-size: 12px;
        }
      }

      &::placeholder {
        color: #191919 !important;
        opacity: 1;
      }
    }

    .circle-checkbox {
      padding-left: 35px;
      font-size: 20px;

      @media (max-width: 1600px) {
        font-size: 17px;
        padding-left: 28px;
      }

      @media (max-width: 991px) {
        font-size: 14px;
      }

      .checkmark {
        height: 25px;
        width: 25px;
        border: 1px solid #ed1155;

        @media (max-width: 1600px) {
          width: 22px;
          height: 22px;
        }

        @media (max-width: 991px) {
          width: 16px;
          height: 16px;
        }

        &:after {
          width: 15px;
          height: 15px;

          @media (max-width: 1600px) {
            width: 12px;
            height: 12px;
          }

          @media (max-width: 991px) {
            width: 8px;
            height: 8px;
          }
        }
      }
    }

    .custom-btn {
      padding: 7px 20px;
      font-size: 25px;
      font-weight: 500;
      background: #ed1155;
      border-radius: 20px;
      height: auto;
      min-height: 52px;
      transition: all 0.3s;

      &:hover {
        transition: all 0.3s;
        background-color: #000;
        color: #fff;
      }

      @media (max-width: 1600px) {
        font-size: 20px;
      }

      @media (max-width: 991px) {
        font-size: 14px;
        min-height: 40px;
      }
    }

    .form-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;

      span {
        font-size: 20px;

        @media (max-width: 1600px) {
          font-size: 18px;
        }

        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
    }

    h6 {
      font-size: 20px;
      font-weight: 400;

      @media (max-width: 1600px) {
        font-size: 18px;
      }

      @media (max-width: 991px) {
        font-size: 14px;
      }

      button {
        font-weight: 600;
        margin-left: 5px;
        color: #000 !important;
      }
    }

    .custom-input p.error {
      color: red;
    }

    .social-media {
      a {
        margin-left: 10px;
        display: inline-block;
        cursor: pointer;

        img {
          @media (max-width: 1400px) {
            width: 35px;
          }

          @media (max-width: 1199px) {
            width: 28px;
          }

          @media (max-width: 767px) {
            width: 22px;
          }
        }
      }
    }

    .custom-input.show-flag-signin input {
      padding-left: 50px;

      @media (max-width: 991px) {
        padding-left: 40px;
      }
    }

    .custom-input .tick,
    .custom-input .verify {
      top: 11px;

      img {
        width: 20px;
      }

      @media (max-width: 991px) {
        top: 6px;

        img {
          width: 15px;
        }
      }
    }

    .custom-input .flag {
      top: 12px;

      img {
        width: 24px;
      }

      @media (max-width: 991px) {
        top: 5px;

        img {
          width: 20px;
        }
      }
    }
  }
}

// new sign in
.flex-inline {
  display: flex;
  align-items: flex-start;
}

.filter-select-container {
  width: 400px;
  max-width: 100%;
}

.filter-select__menu {
  font-family: "PT Sans", sans-serif;

  .filter-select__option {
    display: flex;
    align-items: center;
    padding: 4px 12px;

    &.filter-select__option--is-selected {
      background: none;
    }

    &.filter-select__option--is-focused {
      background-color: #deebff;
    }
  }

  .filter-select__group-heading {
    font-size: 16px;
    font-weight: 700;
    color: #535353;
  }

  .checkType-checkbox {
    width: auto !important;
    margin-bottom: 0;
    margin-left: auto;
  }

  .checkType-checkbox:last-of-type label {
    border: none;
    width: 18px;
    padding-left: 0;
    margin-left: auto;
  }

  .filter-select__option_label {
    font-size: 16px;
    font-weight: 400;
    color: #535353;
    margin: 0;
  }

  .filter-select__menu-list {
    @media (min-width: 768px) {
      min-height: 250px;
    }
  }
}

.filter-inner-search {
  padding: 8px;

  input {
    width: 100%;
    padding: 8px;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
  }
}
.button-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  border-radius: 40px;
  margin: 15px 20px;

  .chat-call {
    border-radius: 40px;
    padding: 8px 20px;
    color: #ffffff;
    font-size: 14px;
    width: 50%;
    font-weight: 600;
    text-align: center;

    &.active {
      background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
    }
  }
}

.chat-call-notification {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    background-color: #f0f0f0;
    border: 1px solid #dedede;
    font-size: 14px;
    color: #f46444;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 30px;
    text-align: center;

    svg {
      margin-right: 10px;
    }
  }

  &.call-duration {
    span {
      color: #7c7c7c;
    }
  }
}

.call-data {
  text-align: left;
  margin-top: 5px;

  h4 {
    font-size: 12px;
    color: #8b8a8a;
    font-weight: 300;

    span {
      color: #ff644a;
    }

    svg {
      width: 10px;
      margin-right: 8px;
    }
  }
}

.thankyou-box {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 50px;
  gap: 80px;
  box-shadow: 0 4px 46px 2px #00000008;
  justify-content: center;
  margin-top: 40px;

  @media (max-width: 1199px) {
    gap: 50px;
    margin-top: 10px;
  }

  @media (max-width: 767px) {
    gap: 30px;
    padding: 20px;
  }

  @media (max-width: 576px) {
    flex-direction: column;
  }

  @media (max-width: 380px) {
    margin-top: 40px;
  }

  .thankyou-image-part {
    max-width: 50%;

    @media (max-width: 767px) {
      max-width: 100%;
    }

    img {
      max-width: 100%;
    }
  }

  .thankyou-content-part {
    flex: none;
    @media (max-width:576px) {
      text-align: center;
    }
    h2 {
      font-size: 48px;
      text-align: left;
      color: #000000;

      @media (max-width: 1199px) {
        font-size: 35px;
      }

      @media (max-width: 991px) {
        font-size: 30px;
      }

      @media (max-width: 767px) {
        font-size: 20px;
      }
      @media (max-width:576px) {
        text-align: center;
      }
      span {
        font-weight: 700;
        color: #000000;
        display: block;
      }
    }

    h3 {
      font-size: 25px;
      color: #343434;
      font-weight: 300;
      margin: 30px 0;

      @media (max-width: 1199px) {
        font-size: 20px;
      }

      @media (max-width: 991px) {
        font-size: 18px;
        margin: 20px 0;
      }

      @media (max-width: 767px) {
        font-size: 14px;
        margin: 10px 0;
      }

      span {
        font-weight: 700;
      }
    }

    .app-links-wrap {
      display: flex;
      align-items: center;
      gap: 30px;
      flex-wrap: wrap;

      @media (max-width: 576px) {
        justify-content: center;
        flex-direction: column-reverse;
        gap: 20px;
        margin-top: 20px;
      }
    }

    .storeBtn-grp {
      flex-direction: column;
      margin: 0px 0;
      gap: 20px;

      @media (max-width: 576px) {
        gap: 10px;
      }

      img {
        height: 45px;
      }
    }

    .qr-wrp {
      margin: 0;
      width: 110px;
      padding: 0;
    }
    .qr-wrp-holder {
      margin: 0;
      padding: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media (max-width:576px) {
        align-items: center;
      } 
      .qr-wrp-code svg {
        @media (max-width:576px) {
          width: 75px;
        }
      }
      h3{
        @media (max-width:1199px) {
          font-size: 35px;
        }
        @media (max-width:991px) {
          font-size: 30px;
        }
        @media (max-width:767px) {
          font-size: 20px;
        }
        span{
          font-weight: bold;
        }
      }
    }
  }

  &.thankyou-wrap {
    flex-direction: column;
    justify-content: center;
    text-align: center;

    h5 {
      font-size: 24px;
      color: #191919;
      font-weight: 700;
      margin-top: 20px;

      @media (max-width: 576px) {
        font-size: 20px;
      }
    }

    p {
      font-size: 18px;
      color: #191919;
      margin-bottom: 20px;

      @media (max-width: 576px) {
        font-size: 16px;
      }
    }

    img {
      max-width: 100%;
    }

    .custom-btn {
      margin: auto;
      min-width: 120px;
    }
  }
}

.tnk-wrap {
  min-height: 100vh;

  .app-links-wrap {
    flex-direction: column;
    align-items: self-start !important;

    @media (max-width: 576px) {
      align-items: center !important;
    }
  }
  .storeBtn-grp {
    flex-direction: row !important;
    margin: 0px 0;
    gap: 20px;

    img {
      height: 46px !important;
    }
  }

  .qr-wrp {
    margin-top: 20px;
  }

  @media (max-width: 576px) {
    padding: 105px 0 30px;
  }

  .thankyou-box {
    @media (max-width: 380px) {
      margin-top: 20px;
    }
  }

  .thankyou-image-part {
    @media (max-width: 520px) {
      max-width: 70%;
    }
  }
}
/* Bavel Buttons*/
.storeBtn-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 100px;
  gap: 10px;
  button {
    display: inline-block;
    min-width: 40%;
    background: #148adb;
    color: #fff;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    font-family: sans-serif;
    box-shadow:
        inset 0 0 2px 0 rgba(255,255,255,.4),
        inset 0 0 3px 0 rgba(0,0,0,.4),
        inset 0 0 3px 5px rgba(0,0,0,.05),
        2px 2px 4px 0 rgba(0,0,0,.25);
    border-radius: 40px;
    padding: 16px 30px;
    font-size: 12px;
    line-height: 14px;
    position: relative;
    .btn-header-blk {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
        .btn-headers{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: left;
            h4{
              @media (max-width:576px) {
                font-size: 15px;
              } 
            }
        }
        img{
          margin-right: 10px;
          width: 25px;
          
          @media (max-width:576px) {
            width: 15px;
          } 
        }
    }
    p {
      font-size: 14px;
      @media (max-width:576px) {
        font-size: 12px;
      } 
    }
    
    @media (max-width:576px) {
      width: 100%;
      padding: 10px 14px;
    } 
  }
  button:before,
  .button:after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    right: 2px;
    height: 3px;
  }
  button:before {
    top: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0 1px 2px 0 rgba(255, 255, 255, 0.6);
  }
  button:after {
    bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: rgba(0,0,0,.15);
    box-shadow: 0 -1px 2px 0 rgba(0,0,0,.15);
  }  
  @media (max-width:576px) {
    flex-direction: column;
  }
}
