@import "./font.scss";
@import "./variable.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: auto;
  min-height: 100vh;
}

body {
  width: 100%;
  height: 100%;
  font-family: "PT Sans", sans-serif;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #fafafa;

  @media screen and (max-width: 767px) {
    background-color: #f6f6f6;
  }
}

#root {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

// scrollbar
::-webkit-scrollbar {
  width: 10px;
  border-radius: 6px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #d5d5d5;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: #c3c3c3;
  border-radius: 6px;
}

// reusable styles
@mixin full-space {
  width: 100%;
  height: 100%;
}

@mixin flex {
  display: flex;
  align-items: center;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-end {
  display: flex;
  justify-content: flex-end;
}

@mixin object-fit {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

@mixin position-center {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

// common styles
a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

button {
  background: inherit;
  outline: none !important;
  cursor: pointer;
  border: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

// date picker
input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: transparent;
}

input[type="date"] {
  display: block;
  position: relative;
  background: white url("../assets/images/calendar-icon.svg") right 1rem center no-repeat;
  cursor: pointer;
}

input[type="date"]:focus {
  outline: none;
}

input[type="date"]::-webkit-datetime-edit-text {
  opacity: 0;
}

input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 2.5rem;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
  margin: 0;
}

.text-justify {
  text-align: justify;
}

.mb-0 {
  margin-bottom: 0;
}

.p-0 {
  padding: 0;
}

.postion-relative {
  position: relative !important;
}

.pink-bg {
  background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
}

.new-bg {
  background: #ED1155;
}

.pink-text {
  color: #f27b79 !important;
}

.wb-block {
  display: block !important;
}

.mb-block {
  display: none !important;

  @media screen and (max-width: 767px) {
    display: block !important;
  }
}

//buttons
.custom-btn {
  min-width: 180px;
  min-height: 42px;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: $boldText;
  color: $whiteText;
  background: $blackbg;
  @include flex-center;
  text-transform: uppercase;
  border: none;
  transition: 0.3s background-color;
  -webkit-transition: 1s background-color;
  position: relative;
  border-radius: 5px;
  letter-spacing: 1px;

  @media screen and (max-width: 767px) {
    min-height: 39px;
  }

  &:hover {
    background: $lineargradient;
    transition: 0.3s background-color;
    -webkit-transition: 1s background-color;
  }

  &.lg-btn {
    min-width: 240px;
    margin: 0 auto;

    @media screen and (max-width: 767px) {
      min-width: 100%;
    }
  }

  &:disabled {
    background-color: #7c7c7c !important;
    pointer-events: none;
    height: 34px;
  }

  .loader {
    border: 6px solid #565656;
    border-top: 6px solid #3c3c3c;
    width: 30px;
    height: 30px;
  }

  &.outline-btn {
    background: transparent;
    border: 1px solid $blackbg;
    color: $blackText;

    &:hover {
      background: $lineargradient;
      border: none;
      color: $whiteText;
    }
  }

  &.pink-btn {
    background: $lineargradient;

    &:hover {
      background: $blackbg;
    }
  }

  &.white-bg {
    background-color: $whitebg;
    color: $blackText;

    &:hover {
      color: $whiteText;
    }
  }

  &.pink-bg {
    background-color: #fe8077;
  }

  &.sm-btn {
    min-width: 100px;
    min-height: 33px;
  }

  .icon {
    margin-left: 10px;
    @include flex-center;
  }

  &.darkpink-btn {
    background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);

    &:hover {
      background: $blackbg;
    }
  }
}

.send-btn {
  @extend .custom-btn;
  font-size: 22px;
  padding: 0;
  min-width: 42px;

  @media screen and (max-width: 767px) {
    max-width: 39px;
    max-height: 39px;
  }

  svg {
    @media screen and (max-width: 767px) {
      width: 18px;
      height: 18px;
    }
  }
}

.button-group {
  @include flex-center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  .custom-btn {
    margin: 0 6px !important;

    @media screen and (max-width: 767px) {
      margin: 4px 0 !important;
    }
  }
}

.detail-btn {
  background-color: #fe4630;
  font-size: 12px;
  font-weight: $boldText;
  border-radius: 5px;
  padding: 5px 10px;
  color: $whiteText;
  text-transform: uppercase;
}

.bookmark-icon {
  @include flex-center;

  svg {
    width: 17px;
  }
}

//button loader
.loader {
  border-radius: 50%;
  animation: spin 2s linear infinite;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.spinner {
  border: 6px solid #565656;
  border-top: 6px solid #3c3c3c;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute;
  left: 50px;
  right: 0;
  margin: 0 auto;
  width: 25px;
  height: 25px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.chatClosebtn {
  display: none;

  @media(max-width: 767px) {
    display: flex;
    background: #282828;
    justify-content: space-between;
    padding: 9px 10px;
    border-radius: 5px 5px 0 0;
    color: #fff;
  }

}

.padding-wrap {
  padding: 180px 0 80px;

  @media screen and (max-width: 1600px) {
    padding: 160px 0 80px;
  }

  @media screen and (max-width: 1440px) {
    padding: 160px 0 80px;
  }

  @media screen and (max-width: 1199px) {
    padding: 136px 0 40px;
  }

  @media screen and (max-width: 1025px) {
    padding: 160px 0 40px;
  }

  @media screen and (max-width: 767px) {
    padding: 145px 0 30px;
  }
}

.container-wrap {
  width: 85%;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    padding: 0 15px;
  }
}

.cmn-head {
  margin-bottom: 15px;

  @media screen and (max-width: 1440px) {
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }

  h1 {
    font-size: 32px;
    font-weight: $boldText;
    margin-bottom: 5px;

    @media screen and (max-width: 1440px) {
      margin-bottom: 0;
    }

    @media screen and (max-width: 1199px) {
      font-size: 28px;
    }

    @media screen and (max-width: 991px) {
      font-size: 24px;
    }

    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }

  h2 {
    font-size: 26px !important;
    font-weight: $boldText;
    color: $blackText;
    margin-bottom: 5px;

    @media screen and (max-width: 1440px) {
      font-size: 24px !important;
    }

    @media screen and (max-width: 991px) {
      font-size: 22px !important;
    }

    @media screen and (max-width: 767px) {
      font-size: 16px !important;
      padding: 0;
    }
  }

  p {
    font-size: 16px;
    font-weight: $regularText;
    color: #ffedf6;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.pink-bg .cmn-head {

  h1,
  h2 {
    color: $whiteText;
  }
}

.content-section {
  padding-top: 40px;

  @media screen and (max-width: 1440px) {
    padding-top: 20px;
  }

  @media screen and (max-width: 991px) {
    padding-top: 20px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 20px;
  }
}

// header
.header-wrap {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1111;
  background: $whitebg;
  box-shadow: 0 4px 34px 0 rgb(0 0 0 / 4%);
  @include flex-between;
  padding: 25px 100px;

  @media screen and (max-width: 1800px) {
    padding: 20px 80px;
  }

  @media screen and (max-width: 1600px) {
    padding: 15px 60px;
  }

  @media screen and (max-width: 1440px) {
    padding: 15px 55px;
  }

  @media screen and (max-width: 1366px) {
    padding: 15px 40px;
  }

  @media screen and (max-width: 1280px) {
    padding: 15px 30px;
  }

  @media screen and (max-width: 1199px) {
    padding: 15px 30px;
  }

  @media screen and (max-width: 767px) {
    padding: 15px 15px;
  }

  @media screen and (max-width: 380px) {
    flex-direction: column;
    gap: 5px;
  }

  .custom-btn {
    @media screen and (max-width: 480px) {
      margin-right: 8px
    }
  }
}

.head-logo {
  @include flex;

  svg {
    @media screen and (max-width: 769px) {
      width: 150px;
      height: 70px;
    }

    @media screen and (max-width: 400px) {
      width: 120px;
      height: 70px;
    }
  }

  .logo {
    position: relative;
    padding-right: 20px;
    @include flex;

    @media screen and (max-width: 1600px) {
      padding-right: 15px;
    }

    @media screen and (max-width: 769px) {
      padding-right: 0;
    }

    svg {
      @media screen and (max-width: 1600px) {
        width: 120px;
      }

      @media screen and (max-width: 1440px) {
        width: 120px;
        height: auto;
      }

      @media screen and (max-width: 1366px) {
        width: 120px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto 0;
      width: 1px;
      height: 60%;
      background: #ED1155;

      @media screen and (max-width: 1280px) {
        height: 44%;
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  h6 {
    padding-left: 20px;
    font-size: 16px;
    color: #252525;
    margin: 0;
    font-family: "Magistral", sans-serif;
    font-weight: 700;

    @media screen and (max-width: 1600px) {
      font-size: 15px;
      padding-left: 15px;
    }

    @media screen and (max-width: 1440px) {
      font-size: 13px;
    }

    @media screen and (max-width: 1280px) {
      font-size: 12px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.head-menu {
  @include flex;

  .nav-menu {
    @include flex;

    a {
      font-size: 14px;
      font-weight: $boldText;
      color: $darkgreyText;
      display: block;
      margin: 0 11px;
      text-transform: uppercase;
      word-break: break-all;

      @media screen and (max-width: 1800px) {
        margin: 0 10px;
      }

      @media screen and (max-width: 1600px) {
        font-size: 12px;
      }

      @media screen and (max-width: 1366px) {
        margin: 0 9px;
      }

      @media screen and (max-width: 1280px) {
        font-size: 12px;
      }

      &.active {
        font-weight: 700;
        color: #ff8177;
      }
    }
  }

  .custom-btn {
    margin-left: 11px;
    font-size: 16px;
    padding: 10px 35px;
    min-width: auto;
    min-height: auto;

    @media screen and (max-width: 1800px) {
      padding: 10px 30px;
    }

    @media screen and (max-width: 1600px) {
      padding: 7px 20px;
      font-size: 12px;
    }

    @media screen and (max-width: 1366px) {
      padding: 8px 20px;
      font-size: 13px;
    }

    @media screen and (max-width: 1280px) {
      font-size: 12px;
      margin-left: 4px;
    }

    @media screen and (max-width: 769px) {
      padding: 5px 8px;
    }

    @media screen and (max-width: 400px) {
      font-size: 10px;
    }

    &:hover {
      background: $blackbg;
    }
  }

  .user {
    margin: 0 8px 0 20px;
    @include flex;

    @media screen and (max-width: 1800px) {
      margin: 0 5px 0 20px;
    }

    @media screen and (max-width: 1600px) {
      margin: 0 7px 0 15px;
    }

    @media screen and (max-width: 1366px) {
      margin: 0 7px 0 15px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }

    .pro-pic {
      width: 50px;
      height: 50px;
      display: flex;
      background: linear-gradient(to right, #ff8177, #b22b5b);
      border-radius: 50%;
      overflow: hidden;

      img {
        object-fit: cover;
        margin: auto;
        width: 100%;
        height: 100%;
      }

      @media screen and (max-width: 1600px) {
        width: 35px;
        height: 35px;
      }

      @media screen and (max-width: 1366px) {
        width: 40px;
        height: 40px;
      }
    }

    .remove-bg {
      background: #dbdbdb;

      img {
        object-fit: cover;
        margin: auto;
        width: 24px;
        height: 24px;
      }

      @media screen and (max-width: 1600px) {
        width: 35px;
        height: 35px;
      }

      @media screen and (max-width: 1366px) {
        width: 40px;
        height: 40px;
      }
    }

    h6 {
      font-size: 14px;
      font-weight: $boldText;
      color: $darkgreyText;
      margin: 0;
      padding-left: 18px;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media screen and (max-width: 1600px) {
        padding-left: 15px;
      }

      @media screen and (max-width: 1440px) {
        padding-left: 12px;
      }

      @media screen and (max-width: 1366px) {
        padding-left: 10px;
      }

      @media screen and (max-width: 1280px) {
        font-size: 13px;
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  .menu-icons {
    @include flex;

    .icons {
      margin: 0 18px;
      cursor: pointer;
      @include flex;

      @media screen and (max-width: 1600px) {
        margin: 0 10px;
      }

      @media screen and (max-width: 1440px) {


        margin: 0 8px;
      }

      @media screen and (max-width: 767px) {
        &:nth-child(1) {
          display: none;
        }
      }


      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .user-private-logout
  {
    margin: 0 8px 0 20px;
    @include flex;

    @media screen and (max-width: 1800px) {
      margin: 0 5px 0 20px;
    }

    @media screen and (max-width: 1600px) {
      margin: 0 7px 0 15px;
    }

    @media screen and (max-width: 1366px) {
      margin: 0 7px 0 15px;
    }
    .pro-pic {
      width: 50px;
      height: 50px;
      display: flex;
      background: linear-gradient(to right, #ff8177, #b22b5b);
      border-radius: 50%;
      overflow: hidden;

      img {
        object-fit: cover;
        margin: auto;
        width: 100%;
        height: 100%;
      }

      @media screen and (max-width: 1600px) {
        width: 35px;
        height: 35px;
      }

      @media screen and (max-width: 1366px) {
        width: 40px;
        height: 40px;
      }
    }

    .remove-bg {
      background: #dbdbdb;

      img {
        object-fit: cover;
        margin: auto;
        width: 24px;
        height: 24px;
      }

      @media screen and (max-width: 1600px) {
        width: 35px;
        height: 35px;
      }

      @media screen and (max-width: 1366px) {
        width: 40px;
        height: 40px;
      }
    }

    h6 {
      font-size: 14px;
      font-weight: $boldText;
      color: $darkgreyText;
      margin: 0;
      padding-left: 18px;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media screen and (max-width: 1600px) {
        padding-left: 15px;
      }

      @media screen and (max-width: 1440px) {
        padding-left: 12px;
      }

      @media screen and (max-width: 1366px) {
        padding-left: 10px;
      }

      @media screen and (max-width: 1280px) {
        font-size: 13px;
      }
    }
  }

  .menu-private-logout {
    @include flex;

    .icons {
      margin: 0 18px;
      cursor: pointer;
      @include flex;

      @media screen and (max-width: 1600px) {
        margin: 0 10px;
      }

      @media screen and (max-width: 1440px) {


        margin: 0 8px;
      }
         &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.private-header {
  .nav-menu {
    @media screen and (max-width: 1199px) {
      display: none;
    }
  }
}

.public-header {
  .nav-menu {
    @media screen and (max-width: 767px) {
      display: none;
    }

    a:last-child {
      margin-right: 0;
    }
  }

  .nav-icon {
    .disabled {
      display: none;
    }

    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}

.nav-icon {
  width: 18px;
  height: 14px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 11;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $blackbg;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 6px;
    }

    &:nth-child(4) {
      top: 12px;
    }
  }

  &.open {
    right: -45px;

    @media screen and (max-width: 1800px) {
      right: -35px;
    }

    @media screen and (max-width: 1600px) {
      right: -10px;
    }

    @media screen and (max-width: 1440px) {
      right: -5px;
    }

    @media screen and (max-width: 1366px) {
      right: 0;
    }

    span {
      background: $whitebg;

      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }
}

.header-dropdown-btn {
  .show>.btn-primary.dropdown-toggle {
    color: #353333;
    background-color: transparent !important;
    border-color: transparent !important;
    display: flex;
    align-items: center;
  }

  .btn {
    border: none;
    background: transparent;
    font-size: 14px;
    font-weight: 700;
    color: #353333 !important;
    padding: 0;


    &:active,
    &:focus,
    &:hover {
      box-shadow: none !important;
      background: transparent !important;
      outline: none;
      border: none;
      background-position: center;
      font-size: 14px;
      font-weight: 700;
      color: #353333;
    }

    &::after {
      content: "";
      border: none;
      background: url("../assets/images/down-arrow.svg") no-repeat;
      height: 10px;
      width: 14px;
      background-position: center;
      position: absolute;
      inset: 0;
      margin: auto;
      transform: translate(0px, 1px);
    }
  }

  .dropdown-menu {
    min-width: 210px;
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    z-index: 1;
    background-color: #ffffff;
    padding: 5px 25px;
    margin-top: 10px;
    border: none;

    @media (max-width: 991px) {
      min-width: 180px;
    }

    a {
      font-size: 16px;
      font-weight: 400;
      color: #353333;
      padding: 15px 0;
      text-decoration: none;
      display: block;
      border-bottom: 1px solid #e4e4e4;
      transition: all ease 0.5s;
      cursor: pointer;
      text-transform: capitalize;

      &:last-child {
        border: none;
      }

      &:active,
      &:focus,
      &:hover {
        box-shadow: none !important;
        background: transparent !important;
        outline: none;
        opacity: 0.8;
      }
    }
  }
}

.dropdown-navmenu {
  position: relative;
  display: inline-block;

  .dropdown-btn {
    cursor: pointer;
  }

  .dropdown-content {
    position: absolute;
    right: 0;
    min-width: 210px;
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    z-index: 1;
    background-color: $whitebg;
    padding: 5px 25px;
    margin-top: 10px;

    a {
      font-size: 16px;
      font-weight: $regularText;
      color: $darkgreyText;
      padding: 15px 0;
      text-decoration: none;
      display: block;
      border-bottom: 1px solid #e4e4e4;
      transition: all ease 0.5s;
      cursor: pointer;

      &:hover {
        color: #f27b79;
      }

      &:last-child {
        border: none;
      }
    }

    .edit-icon {
      display: flex;
      align-items: center;
    }

    .delete-icon {
      display: flex;
      align-items: center;
    }

    button {
      font-size: 16px;
      font-weight: $regularText;
      color: $darkgreyText;
      padding: 15px 0;
      text-decoration: none;
      display: block;
      border-bottom: 1px solid #e4e4e4;
      transition: all ease 0.5s;
      cursor: pointer;

      &:hover {
        color: #f27b79;
      }

      &:last-child {
        border: none;
      }
    }
  }
}

// sidenav
.sidebar-wrap {
  @include full-space;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  overflow-x: hidden;

  .back-drop {
    position: absolute;
    @include full-space;
  }

  &.show {
    width: 100%;

    .side-bar {
      right: 0;
      box-shadow: -7px 0 30px 0 rgba(0, 0, 0, 0.25);
    }
  }

  &.hide {
    width: 0 !important;
  }
}

.side-bar {
  transition: all ease 0.5s;
  width: 370px;
  background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
  position: fixed;
  z-index: 1;
  top: 0;
  right: -370px;
  overflow-x: hidden;
  height: 100%;
  border-radius: 5px 0 0 5px;

  @media screen and (max-width: 1366px) {
    width: 350px;
    right: -350px;
  }

  @media screen and (max-width: 1199px) {
    width: 330px;
    right: -330px;
  }

  @media screen and (max-width: 767px) {
    border-radius: 0;
    width: 100%;
    right: -100%;
    background: $lineargradient;
  }

  .sidebar-body {
    padding: 100px 0 55px;
    height: 100%;

    @media screen and (max-width: 1366px) {
      padding: 85px 0 50px;
    }

    @media screen and (max-width: 1280px) {
      padding: 85px 0 50px;
    }

    @media screen and (max-width: 1199px) {
      padding: 75px 0 50px;
    }

    @media screen and (max-width: 767px) {
      padding: 60px 0 40px;
      background: url(../assets/images/bg-ovl.png) no-repeat center;
      background-size: cover;
    }
  }

  .sidebar-head {
    margin: 0 55px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ff76a7;

    @media screen and (max-width: 1366px) {
      margin: 0 45px;
    }

    @media screen and (max-width: 1199px) {
      margin: 0 35px;
    }

    .user-img {
      width: 57px;
      height: 57px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #e4e4e4;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .remove-bg {
      background: #dbdbdb;

      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
      }
    }

    .user-name {
      @include flex-between;
      margin-bottom: 4px;

      h6 {
        margin: 0;
        font-size: 14px;
        font-weight: $boldText;
        color: $whiteText;
        max-width: 70%;
        word-break: break-word;
      }

      .edit-btn svg path {
        stroke: $whitebg;
      }
    }

    .ratingStars-grp {
      margin-left: 0;
    }
  }

  .sidebar-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100% - 145px);
    padding: 0 55px;
    overflow-y: auto;
    margin-top: 15px;

    @media screen and (max-width: 1366px) {
      padding: 0 45px;
    }

    @media screen and (max-width: 1199px) {
      padding: 0 35px;
    }

    @media screen and (max-width: 767px) {
      height: calc(100% - 150px);
    }

    &::-webkit-scrollbar-track {
      background: #df2466;

      @media screen and (max-width: 767px) {
        background: #e2616c;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: #bd1c55;

      @media screen and (max-width: 767px) {
        background: #f27372;
      }
    }
  }

  .sidebar-menu li {
    font-size: 16px;
    font-weight: $regularText;
    color: $whiteText;
    margin: 13px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .sidebar-bottom {
    border-top: 1px solid #ff76a7;
    padding-top: 45px;
    margin-top: 25px;
  }
}

//footer
.footer-wrap {
  box-shadow: 0 0 34px 4px rgba(0, 0, 0, 0.04);
  padding: 100px 0 60px;
  background: $whitebg;

  @media screen and (max-width: 1600px) {
    padding: 80px 0 50px;
  }

  @media screen and (max-width: 1440px) {
    padding: 60px 0 30px;
  }

  @media screen and (max-width: 767px) {
    padding: 20px 0;
  }

  .footer-content {
    @include flex-between;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .logo {
    margin-bottom: 40px;
    display: block;

    @media screen and (max-width: 1440px) {
      margin-bottom: 30px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 15px;
      text-align: center;
    }

    svg {
      @media screen and (max-width: 767px) {
        width: 130px;
      }
    }
  }

  .footer-logo {
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    p {
      font-size: 14px;
      font-weight: $regularText;
      color: #595959;
      margin-bottom: 20px;
      max-width: 230px;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  .getIn-touch {
    h5 {
      font-size: 16px;
      font-weight: $boldText;
      color: $darkgreyText;
      margin-bottom: 30px;

      @media screen and (max-width: 767px) {
        text-align: center;
        margin-bottom: 20px;
      }
    }

    p {
      font-size: 16px;
      font-weight: $regularText;
      color: #040404;
      margin-bottom: 30px;
      @include flex;

      @media screen and (max-width: 1600px) {
        margin-bottom: 25px;
      }

      @media screen and (max-width: 1440px) {
        margin-bottom: 20px;
      }

      @media screen and (max-width: 991px) {
        font-size: 14px;
      }

      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
        justify-content: center;
      }
    }

    .icon {
      margin-right: 20px;
      @include flex;
    }
  }

  .social-media {
    @include flex;
    margin-bottom: 45px;

    @media screen and (max-width: 1600px) {
      margin-bottom: 40px;
    }

    @media screen and (max-width: 1440px) {
      margin-bottom: 30px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 25px;
    }

    li {
      width: 31px;
      height: 31px;
      border-radius: 50%;
      background-color: rgb(187 187 187 / 20%);
      margin: 0 10px;
      @include flex-center;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .allRights-reserved {
    border-top: 1px solid #b1b1b1;
    margin-top: 60px;
    padding-top: 50px;

    @media screen and (max-width: 1600px) {
      margin-top: 50px;
      padding-top: 40px;
    }

    @media screen and (max-width: 1440px) {
      margin-top: 30px;
      padding-top: 30px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 5px;
      padding-top: 15px;
    }

    span {
      font-size: 16px;
      font-weight: $regularText;
      color: #848484;
      display: block;
      text-align: right;

      @media screen and (max-width: 1440px) {
        font-size: 15px;
      }

      @media screen and (max-width: 1366px) {
        font-size: 14px;
      }

      @media screen and (max-width: 767px) {
        text-align: center;
        font-size: 12px;
      }
    }
  }
}


.vehicle-details {
  padding-left: 40px;

  @media screen and (max-width: 1199px) {
    padding-left: 20px;
  }

  @media screen and (max-width: 991px) {
    padding-left: 0;
    margin-top: 30px;
  }

  .head {
    @include flex-between;
    margin-bottom: 25px;

    h3 {
      font-size: 20px;
      font-weight: $boldText;
      color: $blackText;

      @media screen and (max-width: 1199px) {
        font-size: 18px;
      }

      @media screen and (max-width: 767px) {
        font-size: 14px;
        font-weight: $regularText;
        color: $darkgreyText;
        text-transform: uppercase;
      }
    }

    .edit-btn {
      border: 1px solid #dfdfdf;
      background-color: #f9f9f9;
      border-radius: 5px;
      padding: 6px 19px;
      font-size: 12px;
      font-weight: $regularText;
      color: #242424;
      text-transform: uppercase;
      @include flex-center;

      @media screen and (max-width: 767px) {
        font-size: 10px;
        padding: 5px 15px;
      }

      .icon {
        width: 11px;
        height: 11px;
        margin-right: 7px;
        @include flex-center;

        svg {
          @include full-space;
        }
      }
    }
  }

  .content {
    width: 100%;
  }
}

.pricing-details {
  display: grid;
  grid-template-columns: 64% 36%;

  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .save-info {
    padding-top: 25px;

    @media screen and (max-width: 767px) {
      padding-top: 30px;
    }

    p {
      font-size: 14px;
      font-weight: $regularText;
      color: $darkgreyText;
      margin-bottom: 15px;
    }

    .custom-btn {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

.carousel-wrap {
  display: grid;
  grid-template-columns: 55% 45%;
  margin-bottom: 50px;

  @media screen and (max-width: 991px) {
    grid-template-columns: 100%;
    margin-bottom: 30px;
  }
}

.custom-carousel {
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    height: auto;
    overflow: hidden;
  }

  .slider2 {
    height: calc(100% - 95px);

    .slick-list,
    .slick-track {
      height: 100%;
    }
  }

  .slider1 {
    height: 70px;

    @media screen and (max-width: 767px) {
      height: auto;
    }

    .slick-list {
      height: 100%;
    }

    .slick-track {
      height: 100%;
    }

    .slick-slide div {
      height: 100%;
    }

    .slider-item img {
      @include object-fit;
    }
  }
}

.additional-info {
  padding-left: 60px;

  @media screen and (max-width: 991px) {
    padding-left: 0;
    margin-bottom: 30px;
  }

  .head {
    @include flex-between;
    margin-bottom: 25px;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  h5 {
    font-size: 18px;
    font-weight: $boldText;
    color: $darkgreyText;
    text-transform: uppercase;

    @media screen and (max-width: 1366px) {
      font-size: 16px;
    }

    @media screen and (max-width: 767px) {
      font-size: 14px;
      font-weight: $regularText;
      color: $darkgreyText;
      margin-top: 20px;
    }
  }

  .edit {
    font-size: 16px;
    font-weight: $regularText;
    text-decoration: underline !important;
    color: #262626 !important;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  h6 {
    color: $blackText;
    margin: 10px 0;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  p {
    font-size: 16px;
    font-weight: $regularText;
    color: $blackText;
    margin: 10px 0;
    word-break: break-all;

    @media screen and (max-width: 991px) {
      font-size: 14px;
    }

    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }

  hr {
    margin: 25px 0;

    @media screen and (max-width: 767px) {
      margin: 15px 0;
    }
  }
}

// car carousel
.car-carousel {
  border-radius: unset !important;

  .slider2 {
    figure {
      height: 400px;

      img,
      video {
        @include object-fit;
      }
    }
  }

  .slider1 {
    figure {
      height: 75px;

      @media (max-width:420px) {
        height: 45px;
      }

      img,
      video {
        @include object-fit;
        object-fit: contain !important;
        background: #e5e3e3;
        border-radius: 4px;
      }
    }
  }

  width: 100%;
  position: relative;

  @media screen and (max-width: 767px) {
    height: 100%;
    margin-bottom: 0;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
  }

  .slick-slide {
    img {
      width: 100%;
    }
  }

  .slider1 {
    @media screen and (max-width: 767px) {
      margin-top: 10px;
      position: relative;
      z-index: 1;
    }

    .slick-arrow {
      background-color: transparent;
      width: 30px;
      height: 30px;
      padding: 0;
      color: rgb(0 0 0 / 80%);
      z-index: 1;

      &.slick-prev {
        left: 20px;
      }

      &.slick-next {
        right: 20px;
      }

      &::before {
        font-size: 30px;
        background: #000;
        border-radius: 250px;
      }
    }

    .slick-track {
      display: flex;
      gap: 0 8px;
    }

    .slick-list {
      margin-left: -8px;
      margin-right: -7px;

      @media screen and (max-width: 767px) {
        margin-left: -2px;
        margin-right: -2px;
      }
    }

    .slick-slide {
      border-radius: 5px;

      @media screen and (max-width: 767px) {
        padding: 0 2px;
      }

      div {


        .slider-item {
          @media screen and (max-width: 767px) {
            padding: 0 !important;
            display: block !important;
          }
        }
      }


      &.slick-current {
        div {
          @media screen and (max-width: 767px) {
            background: $lineargradient;
          }
        }
      }

      &:first-child {
        @media screen and (max-width: 767px) {
          margin-left: 0;
        }
      }

      &:last-child {
        @media screen and (max-width: 767px) {
          margin-right: 0;
        }
      }
    }
  }

  .slider2 {
    margin-bottom: 25px;
    position: relative;

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
    }

    .slick-list {
      margin: 0;

      .slick-slide {
        border-radius: 5px;
        overflow: hidden;
        padding: 0;

        figure {
          height: 400px;

          img {
            @include object-fit;
          }
        }

        div {
          height: 100%;
          display: flex !important;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .share-btn {
    @media screen and (max-width: 767px) {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: rgb(0 0 0 / 60%);
      position: absolute;
      bottom: 25px;
      right: 25px;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }

  .top-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @include flex-between;
    padding: 20px;
    z-index: 2;

    @media screen and (max-width: 767px) {
      padding: 15px;
    }
  }

  .user {
    @include flex;
    width: 100%;
  }

  .user-name {
    font-size: 14px;
    font-weight: $regularText;
    color: $whiteText;
    word-break: break-word;
    @include flex;

    .owner-details {
      max-width: 100%;
      padding-right: 20px;
    }

    .img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: #f1f1f1;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 25px;
        height: 25px;
        border-radius: 100%;
      }
    }
  }

  .buttons {
    @include flex;

    button {
      width: 31px;
      height: 31px;
      background-color: #141414;
      margin-right: 6px;
      border-radius: 2px;
      @include flex-center;

      @media screen and (max-width: 767px) {
        width: auto;
        height: auto;
        background-color: transparent;
        margin-right: 15px;
      }

      &:nth-child(2) {
        margin-right: 0;
      }

      svg path {
        fill: #eace76;
      }

      &.quicksale {
        svg {

          path:nth-child(2),
          path:nth-child(3) {
            fill: #fff;
          }
        }
      }
    }
  }

  .ratingStars-grp {
    margin-left: 15px;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .item {
    background: #0000001a;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 85px;
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 0.5) 10%,
          rgba(0, 0, 0, 0) 100%);
      position: absolute;
      top: 0;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .quick-sale-text {
      display: block;
      background: linear-gradient(to right, #ff8177, #b22b5b);
      width: 240px;
      padding: 5px 0;
      position: absolute;
      top: 0px;
      right: -80px;
      z-index: 99;
      transform: rotate(45deg);
      text-align: center;
      color: white;
      font-size: 12px;
      text-indent: 37px;
    }

    .premium {
      position: absolute;
      right: 65px;
      top: 20px;
      background: rgba(0, 0, 0, 0.4196078431);
      padding: 3px;
      border: 1px solid #656565;
      border-radius: 4px;
      z-index: 99;
      cursor: default;

      .premium-txt {
        display: block;
        color: #f1dd80;
        font-size: 10px;
      }
    }

    figure {
      position: relative;
      overflow: hidden;

      img {
        object-fit: unset;
      }
    }
  }
}

.info-card {
  box-shadow: 0 4px 4px 0px rgb(0 0 0 / 3%);
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 8px 16px;
  height: 100%;
  background: $whitebg;
  @include flex;

  label {
    font-size: 12px;
    font-weight: $regularText;
    color: $pinkText;
    display: block;
    margin-bottom: 0;
    line-height: 16px;
  }

  input,
  textarea {
    width: 100%;
    font-size: 14px;
    font-weight: $boldText;
    color: $blackText;
    border: none;
    padding: 0;
    outline: none;
    height: 40px;
    resize: none;
    text-overflow: ellipsis;
    margin-bottom: 0;

    &::placeholder {
      font-size: 14px;
      font-weight: $boldText;
      color: $blackText;
    }

    &.readOnly {
      cursor: default;
      pointer-events: none;
      height: auto;
    }
  }

  .icon {
    display: none;
  }

  &.text-area {
    flex-direction: column;
    align-items: flex-start;

    textarea {
      min-height: 117px;
      padding: 2px 0;
    }
  }
}

.info-list {
  display: grid;
  gap: 15px;

  @media screen and (max-width: 767px) {
    gap: 10px;
  }

  &.col2 {
    grid-template-columns: 1fr 1fr;
  }

  &.col3 {
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.extra-features {
  background-color: #3f3244;
  border-radius: 5px;
  padding: 14px 16px;
  height: 116px;
  margin-top: 20px;

  @media screen and (max-width: 767px) {
    border-radius: 20px;
  }

  .head-top {
    @include flex-between;
    margin-bottom: 7px;

    h6 {
      font-size: 14px;
      font-weight: $regularText;
      color: $whiteText;
    }

    .edit {
      font-size: 14px;
      font-weight: $regularText;
      color: #959595 !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .feature-list {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: 70px;

    li {
      font-size: 14px;
      font-weight: $boldText;
      color: $whiteText;

      @media screen and (max-width: 767px) {
        font-weight: $regularText;
      }
    }
  }
}

// accordion
.custom-accordion {
  .szh-accordion__item {
    padding: 20px 0 !important;

    @media screen and (max-width: 1366px) {
      padding: 16px 0 !important;
    }

    @media screen and (max-width: 767px) {
      padding: 8px 0 !important;
    }

    h3 {
      display: flex;
    }

    .szh-accordion__item-btn {
      font-size: 18px;
      font-weight: $boldText;
      color: $darkgreyText;
      text-transform: uppercase;

      @media screen and (max-width: 1366px) {
        font-size: 16px;
      }

      @media screen and (max-width: 991px) {
        font-size: 14px;
      }

      @media screen and (max-width: 767px) {
        font-weight: $regularText;
      }

      &::after {
        content: " ";
        height: 13px;
        width: 13px;
        background: url(../assets/images/down-arrow.svg) no-repeat center;
        background-size: contain;
        transform: rotateX(0deg);
      }
    }

    &::after {
      background: #e6e6e6 !important;
      bottom: unset;
      top: 0;
    }
  }

  .history-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
      gap: 15px;
    }

    .info-list {
      margin-bottom: 15px;

      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
  }

  .service-record {
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
    }
  }

  .service-wrap {
    padding-top: 30px;

    @media screen and (max-width: 767px) {
      padding-top: 15px;
    }
  }

  .car-images {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 15px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
      row-gap: 10px;
    }

    li {
      border-radius: 5px;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      height: 100px;
      background: #ddd;

      @media screen and (max-width: 767px) {
        border-radius: 20px;
      }

      .resetButton {
        height: 100px;
        margin: auto
      }

      img {
        @include object-fit;
      }
    }

    .imgOverlaywrap-small {
      display: flex;
      position: absolute;
      top: unset;
      bottom: unset;
      right: unset;
      left: unset;
      align-items: center;
      justify-content: center;
      background: rgba(230, 230, 230, 0.75);
      backdrop-filter: blur(4px);
      height: 100px;
      width: 250px;

      @media screen and (max-width: 1499px) {
        width: 250px;
      }

      @media screen and (max-width: 1024px) {
        width: 250px;
      }

      @media screen and (max-width: 764px) {
        width: 300px;
      }

      @media screen and (max-width: 500px) {
        width: 150px;
      }

      @media screen and (max-width: 320px) {
        width: 120px;
      }
    }
  }

  .condition-detail {
    .info-list {
      margin-bottom: 15px;

      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }

      .info-card .content {
        width: 100%;

        textarea {
          height: 80px;
          padding: 8px;
        }
      }
    }

    &.condition-detail-wrap {
      .info-list {
        gap: 25px 15px;
      }

      .title-block {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        h5 {
          font-weight: 400;
          text-align: left;
          color: #7c7c7c;
          font-size: 14px;
        }

        span {
          width: 38px;
          height: 19px;
          background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          border-radius: 3.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          padding: 8px 10px;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;

          &.no-date {
            background: #282828;
          }
        }
      }

      .uploadImages-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;

        .uploadImages-items {
          position: relative;

          .upload-img {
            height: 86px;
            border-radius: 5px;
            background-color: rgba(255, 255, 255, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }

            .close-icon {
              position: absolute;
              top: 7px;
              right: 7px;
              width: 14px;
              height: 14px;
              border-radius: 50%;
              background-color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              svg {
                width: 6px;

                path {
                  stroke: #282828;
                }
              }
            }
          }

          .no-img::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url(../assets/images/no-img.svg) no-repeat center;
            background-size: 20px;
            border: 1px solid #eeeeee;

            @media screen and (max-width: 767px) {
              background-size: 14px;
            }
          }
        }
      }

      .resetButton {
        img {
          width: 200px;
          height: 100px;
        }
      }

      .imgOverlaywrap-small {
        display: flex;
        position: absolute;
        top: unset;
        bottom: unset;
        right: unset;
        left: unset;
        align-items: center;
        justify-content: center;
        background: rgba(230, 230, 230, 0.75);
        backdrop-filter: blur(4px);
        height: 100px;
        width: 200px;

        @media screen and (max-width: 1499px) {
          width: 250px;
        }

        @media screen and (max-width: 1024px) {
          width: 250px;
        }

        @media screen and (max-width: 764px) {
          width: 300px;
        }

        @media screen and (max-width: 500px) {
          width: 150px;
        }

        @media screen and (max-width: 320px) {
          width: 120px;
        }
      }
    }
  }

  .wheels-detail {
    .info-list {
      margin-bottom: 25px;

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
  }

  .wheels-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
    }

    .sm-head {
      margin-bottom: 25px;
      text-transform: uppercase;

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;

      @media screen and (max-width: 767px) {
        gap: 10px;
      }

      li {
        border-radius: 5px;
        overflow: hidden;

        @media screen and (max-width: 767px) {
          border-radius: 20px;
        }

        img {
          @include object-fit;
        }
      }
    }
  }

  .edit {
    font-size: 16px;
    color: #202020 !important;
    font-weight: $regularText;
    text-decoration: underline !important;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  .sm-head {
    font-size: 16px;
    font-weight: $boldText;
    color: #1d1d1d;

    @media screen and (max-width: 991px) {
      font-size: 14px;
    }
  }

  .flex-between {
    @include flex-between;
    margin-bottom: 25px;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  .flex-end {
    @include flex;
    justify-content: flex-end;
    margin-bottom: 25px;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  .pricing-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
    }

    .pricing {
      width: 100%;
      background: $greenGradient;
      border-radius: 5px;
      padding: 18px 26px;
      @include flex;

      @media screen and (max-width: 1199px) {
        padding: 15px 20px;
      }

      @media screen and (max-width: 767px) {
        border-radius: 20px;
      }

      &.dark {
        background: #3f3244;
      }

      .tick-icon {
        width: 35px;
        height: 35px;
        @include flex-center;
        background-color: $whitebg;
        border-radius: 50%;
        margin: 0 auto;

        @media screen and (max-width: 767px) {
          width: 30px;
          height: 30px;
        }

        svg {
          width: 11px;
        }

        &.bgchange-tickIcon {
          svg {
            path {
              stroke: #3f3244;
            }
          }
        }
      }

      .tick-icon-grey {
        width: 35px;
        height: 35px;
        @include flex-center;
        background-color: $whitebg;
        border-radius: 50%;
        margin: 0 auto;

        @media screen and (max-width: 767px) {
          width: 30px;
          height: 30px;
        }

        svg {
          width: 11px;
        }

        &.bgchange-tickIcon {
          svg {
            path {
              stroke: #3f3244;
            }
          }
        }
      }

      h2 {
        font-size: 25px;
        color: $whiteText;
        font-family: "Magistral", sans-serif;
        font-weight: 700;
        margin-top: 3px;
        letter-spacing: 2px;

        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }

      .edit-value {
        padding-left: 25px;

        h5 {
          font-size: 16px;
          font-weight: $boldText;
          color: $whiteText;
          margin-bottom: 6px;
        }

        p {
          font-size: 12px;
          font-weight: $regularText;
          color: $whiteText;
          margin-bottom: 12px;
        }

        .custom-btn {
          min-height: 34px;
          min-width: 92px;
          margin-top: 10px;

          @media screen and (max-width: 767px) {
            font-size: 12px;
            min-height: 32px;
          }
        }
      }
    }
  }
}

// upload photos
.upload-photos {
  .white-card {
    @media screen and (max-width: 767px) {
      background-color: transparent;
      padding: 0;
      box-shadow: none;
    }
  }

  .choose-file .click-here {
    @media screen and (max-width: 767px) {
      background: #7c7c7c;
    }
  }

  .head {
    @include flex-between;
    margin-bottom: 25px;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }

    h3 {
      font-size: 14px;
      font-weight: $regularText;
      color: $blackText;
      text-transform: uppercase;
    }
  }

  .upload-carImages {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    @media screen and (max-width: 991px) {
      grid-template-columns: 100%;
    }

    p {
      font-size: 12px;
      font-weight: $regularText;
      color: $lightgreyText;
      text-align: left;
      margin-bottom: 20px;

      @media screen and (max-width: 991px) {
        text-align: center;
        margin-bottom: 10px;
      }
    }

    .custom-btn {
      @media screen and (max-width: 991px) {
        margin: 0 auto;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .images-upload .choose-file {
    margin-bottom: 20px;
  }

  .upload-images {
    width: 100%;
  }

  .images-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    li {
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
      min-height: 119px;

      @media screen and (max-width: 1199px) {
        min-height: 90px;
      }

      @media screen and (max-width: 991px) {
        min-height: auto;
      }

      @media screen and (max-width: 767px) {
        border-radius: 20px;
      }

      img {
        @include object-fit;
      }
    }
  }

  .video-wrap {
    margin-bottom: 20px;
    height: 253px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 1199px) {
      height: 195px;
    }

    @media screen and (max-width: 991px) {
      height: auto;
    }

    @media screen and (max-width: 767px) {
      border-radius: 20px;
    }

    img {
      @include object-fit;
    }

    .close-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: $lineargradient;
      z-index: 9;
      @include flex-center;
      position: absolute;
      top: 10px;
      right: 10px;

      @media screen and (max-width: 767px) {
        width: 25px;
        height: 25px;
      }

      svg {
        width: 9px;
        height: 9px;

        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px;
        }

        path {
          fill: $whitebg;
        }
      }
    }

    .play-icon {
      width: 60px;
      height: 60px;
      background: rgb(255 255 255 / 50%);
      border-radius: 50%;
      @include position-center;

      @media screen and (max-width: 767px) {
        width: 40px;
        height: 40px;
      }

      span {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $lineargradient;
        display: block;
        position: relative;
        margin: auto;

        @media screen and (max-width: 767px) {
          width: 25px;
          height: 25px;
        }

        &::after {
          content: "";
          width: 10px;
          height: 10px;
          background: $whitebg;
          border-radius: 50%;
          @include position-center;

          @media screen and (max-width: 767px) {
            width: 5px;
            height: 5px;
          }
        }
      }
    }
  }

  .confirm-box {
    width: 100%;
    background: #b22b5b;
    font-size: 14px;
    font-weight: $regularText;
    color: #e4e4e4;
    @include flex-center;
    padding: 11px;
    border-radius: 5px;
    margin-right: 40px;

    @media screen and (max-width: 991px) {
      margin-right: 30px;
    }

    @media screen and (max-width: 767px) {
      margin-right: 10px;
      font-size: 12px;
    }
  }

  .carImages-upload {
    .subhead {
      @include flex-between;
      margin-bottom: 10px;

      h4 {
        font-size: 14px;
        font-weight: $boldText;
        color: $blackText;
        text-transform: uppercase;
      }

      .count {
        @include flex;
        font-size: 14px;
        font-weight: $boldText;
        color: $blackText;

        .current {
          color: #ff8f66;
        }
      }
    }

    p {
      font-size: 14px;
      font-weight: $regularText;
      color: $lightgreyText;
      text-align: left;
      margin-bottom: 20px;
    }

    .images-view {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;

      @media screen and (max-width: 767px) {
        grid-template-columns: 100%;
        gap: 20px;
      }
    }

    .upload-box {
      height: 100%;
    }

    .choose-file {
      height: 100%;
    }

    .image-box {
      border-radius: 5px;

      img {
        @include object-fit;
      }
    }
  }

  .progress-container {
    margin-bottom: 20px;
  }

  .button-wrap {
    margin-top: 30px;
    @include flex-end;

    @media screen and (max-width: 767px) {
      justify-content: center;
    }

    .custom-btn {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

.white-card {
  border-radius: 5px;
  box-shadow: 0 4px 4px 0px rgb(0 0 0 / 3%);
  padding: 30px;
  background: $whitebg;

  @media screen and (max-width: 767px) {
    padding: 20px;
  }
}

//choose file
.choose-file {
  width: 100%;
  height: 90px;
  position: relative;

  label {
    width: 100%;
    font-size: 14px;
    color: #7c7c7c;
    font-weight: 400;

    @media (max-width:767px) {
      font-size: 12px !important;
    }
  }

  h6 {
    font-size: 14px;
    color: #7c7c7c;
    font-weight: 400;

    @media screen and (max-width: 767px) {
      font-size: 13px;
    }
  }

  .custom-file-input {
    @include full-space;
    color: transparent;
    opacity: 100%;
    border: 1px dashed pink;
    border-radius: 5px;
    cursor: pointer;
    padding: 38px 30px;
    z-index: 1;

    @media screen and (max-width: 767px) {
      border: none;
      padding: 45px 30px;
      border-radius: 20px;
    }

    &::-webkit-file-upload-button {
      visibility: hidden;
    }

    &:active {
      outline: 0;
    }
  }

  .click-here {
    @include position-center;
    @include flex-center;
    flex-direction: column;

    label {
      font-size: 14px;
      font-weight: $regularText;
      color: #c74362;
      text-align: center;
      margin: 10px 0 0;
    }
  }

  .click-here .cursor {
    label {
      cursor: pointer;
    }

    svg {
      cursor: pointer;
    }
  }
}

// progressbar
.progress-container {
  width: 100%;
  position: relative;
  display: inline-block;
  background: #e4e4e4;
  height: 5px;
  overflow: hidden;
  border-radius: 5px;

  progress {
    opacity: 0;
  }

  .bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: $lineargradient;
  }
}

.surveybar-conatiner {
  @extend .progress-container;
  height: 19px;

  @media screen and (max-width: 767px) {
    display: none;
  }

  .bar {
    background: $greenGradient;
  }
}

//search
.search-wrap {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  background-color: #fafafa;
  overflow: hidden;
  padding: 8px 20px;
  position: relative;

  @media screen and (max-width: 767px) {
    background-color: $whitebg;
  }

  input {
    border: none;
    width: 100%;
    outline: none;
    font-size: 12px;
    font-weight: $regularText;
    padding-left: 20px;

    @media screen and (max-width: 767px) {
      padding-left: 0;
    }

    &::placeholder {
      color: #b3b3b3;
    }
  }

  &::before {
    content: "";
    width: 12px;
    height: 100%;
    background: url(../assets/images/chat-search-icon.svg) no-repeat center;
    background-size: 12px 12px;
    position: absolute;
    top: 0;
    left: 20px;

    @media screen and (max-width: 767px) {
      left: auto;
      right: 20px;
      background-image: url(../assets/images/chat-search-icon.svg);
      width: 14px;
      background-size: 14px 14px;
    }
  }
}

// form
.custom-form {
  .custom-input {
    margin-bottom: 25px;
    position: relative;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  .action-btn {
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    button {
      @media (max-width:768px) {
        width: 100%;
      }
    }
  }
}

.custom-input {
  position: relative;

  label {
    font-size: 14px;
    color: $lightgreyText;
    font-weight: $regularText;
    white-space: nowrap;

    span.required {
      color: red;
    }

    @media screen and (max-width: 767px) {
      font-size: 13px;
    }
  }

  p.error {
    color: red;
    font-size: 13px;
  }

  input[aria-invalid="true"] {
    border: 1px solid red;
  }

  input {
    width: 100% !important;
    padding: 5px 20px;
    min-height: 42px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: $boldText;
    color: $darkgreyText;
    border: 1px solid #e4e4e4;
    outline: inherit;

    &::placeholder {
      color: $lightText;
      font-weight: $regularText;
    }

    &:read-only {
      pointer-events: none;

      &::placeholder {
        color: $lightText;
        font-weight: $regularText;
      }
    }

    &:disabled {
      background: #f7f7f7;
    }

    &.select-input {
      cursor: pointer;
    }
  }

  .select-icon {
    position: absolute;
    top: 11px;
    right: 20px;
    color: #7c7c7c;

    svg {
      font-size: 12px;
    }
  }

  .tick {
    position: absolute;
    top: 8px;
    right: 10px;
  }

  &.show-tick {
    input {
      padding-right: 45px;
    }
  }

  &.show-flag-signin {
    input {
      padding-left: 40px;
    }
  }

  .verify {
    @extend .tick;
    color: #fff;
    font-size: 11px;
    font-weight: $boldText;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 4px;
    background: $lineargradient;
    padding: 4px 10px;

    &.non-valid {
      cursor: default;
      display: none;
    }
  }

  .verify-loader {
    position: absolute;
    top: 12px;
    right: 10px;
  }

  .flag {
    position: absolute;
    top: 7px;
    left: 15px;
  }

  &.show-flag {
    input {
      padding-left: 50px;

      &::placeholder {
        font-weight: 300;
        font-size: 13px;
        color: #c0c0c0;
      }
    }

    input:disabled {
      background: #f7f7f7;
    }
  }

  &.country-flag {
    input {
      padding: 2px 20px 2px 35px;
      font-size: 22px;
      text-align: left;

      @media screen and (max-width: 767px) {
        padding: 2px 20px 2px 55px;
      }

      &::placeholder {
        font-size: 22px;
      }
    }

    .flag-country {
      background-color: #003399;
      border-radius: 1px;
      position: absolute;
      top: 4px;
      left: 4px;
      font-size: 12px;
      color: $whiteText;
      font-weight: $regularText;
      padding: 6px 6px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: 767px) {
        padding: 3px 6px;
      }

      img {
        width: 16px;
      }
    }
  }

  &.with-icon {
    input {
      padding-left: 60px;
    }

    .icon {
      position: absolute;
      top: 8px;
      left: 16px;

      img {
        height: 14px;
      }
    }

    .text {
      position: absolute;
      top: 12px;
      right: 20px;
      font-size: 12px;
      font-weight: $regularText;
      color: $lightgreyText;
    }
  }

  select {
    display: block;
    width: 100%;
    padding: 5px 20px;
    min-height: 42px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #353333;
    border: 1px solid #e4e4e4;
    outline: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }

  .select {
    width: 100%;

    .css-13cymwt-control,
    .css-t3ipsp-control {
      height: 42px;
      border-color: #e4e4e4;
      box-shadow: none;
      padding: 0 20px;

      @media screen and (max-width: 767px) {
        border-radius: 25px;
      }
    }

    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    .css-1xc3v61-indicatorContainer,
    .css-15lsz6c-indicatorContainer {
      padding: 0;
      color: #7c7c7c;
    }

    .css-1nmdiq5-menu {
      margin: 0;
      font-size: 12px;
      font-weight: $regularText;
    }
  }

  textarea {
    border: 1px solid #e4e4e4;
    outline: none;
    padding: 15px 20px;
    width: 100%;
    resize: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: $boldText;
    color: #353333;

    &::placeholder {
      color: $lightText;
      font-weight: $regularText;
    }
  }
  
  textarea::-webkit-scrollbar:hover {
    cursor: auto; 
  }
  textarea::-webkit-scrollbar-thumb:hover {
    cursor: auto; 
  }
}

// checkbox
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 23px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: $regularText;
  color: $darkgreyText;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 15px;
  height: 15px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked~.checkmark {
    background-color: #b22b5b;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    border: 2px solid #e4e4e4;
    border-radius: 5px;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid $whitebg;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
  }

  input:checked~.checkmark:after {
    display: block;
  }
}

//circle checkbox
.circle-checkbox {
  display: block;
  position: relative;
  padding-left: 23px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: $regularText;
  color: $darkgreyText;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: 767px) {
    font-size: 13px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid #e4e4e4;
    border-radius: 50%;

    @media screen and (max-width: 767px) {
      background-color: #eaeaea;
      border: 1px solid #adadad;
    }
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    inset: 0;
    margin: auto;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $lineargradient;
  }

  input:checked~.checkmark:after {
    display: block;
  }

  input:checked~.checkmark {
    background-color: $whitebg;
    border: 1px solid #b22b5b;

    @media screen and (max-width: 767px) {
      background-color: #eaeaea;
    }
  }
}

.checkbox-wrap {
  @include flex;
  margin-bottom: 15px;

  p {
    font-size: 14px;
    font-weight: $regularText;
    color: $lightgreyText;
    padding-left: 10px;

    @media screen and (max-width: 767px) {
      font-size: 12px;
    }

    span {
      color: #f07071;
    }
  }
}

// radio button
.custom-radiobtn,
.circle-radiobtn {
  display: block;
  position: relative;
  margin-bottom: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #353333;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 50%;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    border-radius: 50%;
    transform: rotate(45deg);
  }
}

.custom-radiobtn {
  padding-left: 23px;
  padding-bottom: 5px;

  input:checked~.checkmark {
    background-color: $whitebg;
  }

  input:checked~.checkmark:after {
    display: block;
  }

  .checkmark {
    border: 1px solid #d9d9d9;
  }

  .checkmark:after {
    left: 2px;
    top: 2px;
    width: 10px;
    height: 10px;
    background: $lineargradient;
  }
}

//circle radio
.circle-radiobtn {
  padding-left: 23px;

  input:checked~.checkmark {
    background-color: #ffffff;
    border: 4px solid #d9d9d9;
  }

  input:checked~.checkmark:after {
    display: block;
  }

  .checkmark {
    background-color: #d9d9d9;
  }

  .checkmark:after {
    left: 0;
    top: 0;
    width: 8px;
    height: 8px;
    background-color: #b22b5b;
  }
}

.radio-wrap {
  @include flex;
  margin-bottom: 10px;
  overflow: hidden;

  .custom-radiobtn {
    margin-right: 20px;

    @media screen and (max-width: 767px) {
      margin-right: 10px;
    }
  }
}

.custom-input .radio-wrap {
  @include flex;
  margin-bottom: 1px;
  overflow: hidden;

  .custom-radiobtn {
    margin-right: 20px;

    @media screen and (max-width: 767px) {
      margin-right: 10px;
    }
  }
}

//tab
.custom-tab {
  .nav {
    @include flex-center;

    @media screen and (max-width: 767px) {
      border-radius: 20px;
      overflow: hidden;
      background: $blackbg;
    }

    li {
      width: 50%;
      text-align: center;
      font-size: 16px;
      font-weight: $boldText;
      color: $lightgreyText;
      padding: 0 15px 15px;
      cursor: pointer;
      border-bottom: 1px solid #e8e8e8;

      @media screen and (max-width: 767px) {
        color: $whiteText;
        padding: 9px 10px;
        font-size: 14px;
      }

      &.active {
        color: $blackText;
        position: relative;

        @media screen and (max-width: 767px) {
          background: $lineargradient;
          border-radius: 20px;
          color: $whiteText;
        }

        &::after {
          content: "";
          width: 100%;
          height: 2px;
          position: absolute;
          bottom: 0;
          left: 0;
          background-image: $lineargradient;

          @media screen and (max-width: 767px) {
            display: none;
          }
        }
      }
    }
  }

}

//chat
.chat-wrap {
  height: 100%;

  @media(max-width: 767px) {
    display: flex;
    flex-direction: column;
  }

  .content-section {
    display: flex;
    position: relative;


    @media screen and (max-width: 767px) {
      flex-direction: column;
      overflow: hidden;
    }
  }

  .chat-users {
    width: 355px;
    border: 1px solid #e8e8e8;
    margin-right: 15px;
    background: $whitebg;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;

    @media screen and (max-width: 1199px) {
      width: 300px;
    }

    @media screen and (max-width: 1024px) {
      width: 275px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      border: none;
      padding: 0;
      background: transparent;
    }

    .chat-user-list {
      height: calc(100vh - 200px);
      display: block;
      position: relative;
      overflow: auto;
      flex-grow: 1;

      @media screen and (max-width: 1199px) {
        max-width: 272px;
      }

      @media screen and (max-width: 767px) {
        max-width: 100%;
        border: none;
        padding: 0;
        background: transparent;
      }
    }
  }

  .chat-proceed-btn {
    min-height: 24px;
    font-size: 10px;
    min-width: 110px;
    margin: 0 5px;
    background-color: #3fff3fc4;
    color: #fff;
  }

  .chat-dropdown-list {
    &:active {
      background: #fff !important;
    }

    a {
      display: flex;
      align-items: center;
      padding: 12px;
      font-size: 12px;
      font-weight: 400;
      color: #282828;
      cursor: pointer;
      border-bottom: 1px solid #e4e4e4;

      &:last-child {
        border: none;
      }

      &:active {
        background: #fff !important;
      }

      span {
        margin-left: 15px;
      }
    }

    .add-svg-colour {
      svg {
        path {
          stroke: #f27b79;
        }
      }
    }
  }

  .user-chat {
    border-bottom: 1px solid #e8e8e8;
    padding: 15px 20px;
    @include flex-between;
    border-left: 5px solid transparent;
    position: relative;

    @media screen and (max-width: 1199px) {
      padding: 10px;
    }

    @media screen and (max-width: 767px) {
      border: none;
      background-color: $whitebg;
      margin-bottom: 10px;
      border-radius: 15px;
      padding: 10px 15px;
    }

    .user {
      width: 100%;
      @include flex-between;

      .profile-img {
        width: 100%;
        height: 100%;
      }

      .img {
        width: 60px;
        height: 55px;
        border-radius: 5px;
        overflow: hidden;
        display: block;
        background: linear-gradient(to right, #ff8177, #b22b5b);
        flex: none;

        @media screen and (max-width: 767px) {
          border-radius: 11px;
          width: 57px;
          height: 47px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .user-detail {
        width: 100%;
        padding: 0 15px;

        @media screen and (max-width: 1199px) {
          padding: 0 10px;
        }

        @media screen and (max-width: 767px) {
          position: relative;
        }

        h5 {
          font-size: 14px;
          font-weight: $boldText;
          color: $darkgreyText;
          text-transform: uppercase;
          word-break: break-all;

          @media screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }

        .car-modal-h6 {
          color: #353333;
          font-size: 10px;
          margin-bottom: 2px;
        }

        h6 {
          font-size: 14px;
          font-weight: $boldText;
          color: #ff0000;
          word-break: break-all;

          @media screen and (max-width: 1024px) {
            font-size: 12px;
          }

          @media screen and (max-width: 767px) {
            margin-bottom: 5px;
          }
        }

        p {
          font-size: 10px;
          font-weight: $boldText;
          color: #8b8a8a;
          word-break: break-all;
        }

        .offer-btn-block {
          border-radius: 5px;
          padding: 2px 10px;
          color: #fff;
          font-size: 10px;
          font-weight: 700;
          margin-top: 2px;

          @media (max-width:768px) {
            display: block;
          }

          &.sender {
            background: #0075ff;
          }

          &.received {
            background: #20cf1c;
          }

          &.sold {
            background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
          }

          &.like-initiated {
            background: #FF644A !important;
          }

          &.like-received {
            background: #ED1155 !important;
          }
        }
      }

      .detail {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        min-width: 215px;

        @media (max-width:1024px) {
          min-width: 115px;
        }

        .name {
          cursor: pointer;
          text-align: justify;
          width: 90%;
        }

        .number-msg {
          cursor: pointer;
        }
      }

      .number {
        @include flex-between;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        cursor: pointer;
      }

      .ratingStars-grp {
        margin-left: 0;

        @media screen and (max-width: 767px) {
          display: none;
        }

        li {
          width: 15px;
          height: 15px;
          @include flex-center;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      .number-msg {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: $lineargradient;
        @include flex-center;
        font-size: 10px;
        font-weight: $boldText;
        color: $whiteText;

        @media screen and (max-width: 767px) {
          width: 23px;
          height: 23px;
          position: absolute;
          top: 14px;
          right: 10px;
        }
      }
    }

    .action {
      @include flex;

      .block-icon {
        display: none;

        @media screen and (max-width: 767px) {
          display: block;
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }

        img {
          @include object-fit;
        }
      }

      .dropdown {
        @media screen and (max-width: 767px) {
          position: unset;
        }

        .dropdown-content {
          @media screen and (max-width: 767px) {
            height: 100%;
            top: 1px;
            box-shadow: 0 4px 12px 0 rgb(0 0 0 / 5%);
          }

          .dropdown-list {
            @media screen and (max-width: 767px) {
              @include flex-center;
              padding: 0 10px;
              height: 100%;
              background-color: $blackbg;
              border-radius: 20px;
            }

            li {
              border-bottom: 1px solid #e4e4e4;

              @media screen and (max-width: 767px) {
                border: none;
                padding: 10px;
                width: 39px;
                height: 39px;
                border-radius: 50%;
              }

              span {
                @media screen and (max-width: 767px) {
                  display: none;
                }
              }

              &:hover {
                @media screen and (max-width: 767px) {
                  background: $lineargradient;
                }
              }

              &:last-child {
                border-bottom: none;
              }
            }

            &::before {
              @media screen and (max-width: 767px) {
                display: none;
              }
            }
          }
        }
      }
    }

    &.active {
      border-left: 5px solid #f27b79;

      @media screen and (max-width: 767px) {
        border-left: transparent;
      }
    }
  }

  .chat-search {
    @include flex-center;
    padding: 20px;
    border-bottom: 1px solid #e8e8e8;
    gap: 10px;

    @media screen and (max-width: 1440px) {
      padding: 15px;
    }

    @media screen and (max-width: 1199px) {
      padding: 10px;
    }

    @media screen and (max-width: 767px) {
      border: none;
      padding: 0 0 20px;
    }

    .search-wrap {
      margin-right: 20px;

      @media screen and (max-width: 1199px) {
        margin-right: 10px;
      }

      @media screen and (max-width: 767px) {
        margin-right: 0;
      }

      input {
        background-color: #fafafa;

        @media screen and (max-width: 767px) {
          background-color: transparent;
        }
      }
    }

    .blocked-btn {
      border: none;

      @media (max-width:767px) {
        margin-left: 10px;
      }
    }
  }

  .chat-content {
    width: calc(100% - 370px);
    background: $whitebg;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    transition: all ease-in-out .4s;
    right: 0;

    button.back-btn {
      display: none;
      border-radius: 10px;
      height: 30px;
      width: 30px;

      @media screen and (max-width: 767px) {
        display: block;
      }

      svg {

        path {
          stroke: #fff;
        }
      }
    }

    .chatHead-button {
      @media screen and (max-width: 767px) {
        padding-left: 5px;
        align-items: center;
      }
    }

    @media screen and (max-width: 1199px) {
      width: calc(100% - 315px);
    }

    @media screen and (max-width: 1024px) {
      width: calc(100% - 290px);
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      background: transparent;
      border: none;
      position: absolute;
      top: 0;
      right: -100%;
      bottom: 0;
      display: flex;
      flex-direction: column;

      &.open {
        right: 0;
      }
    }

    .chat-body {
      @media(max-width: 767px) {
        background: #fff;
        padding: 5px;
        flex-grow: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
      }

    }
  }

  .chat-head {
    width: 100%;
    @include flex;
    padding: 12px 15px;
    border-bottom: 1px solid #e8e8e8;

    @media screen and (max-width: 767px) {
      background-color: #ffffff;
      margin-bottom: 0;
      border-radius: 0;
      padding: 10px 10px 10px 15px;
      position: relative;
    }

    .back-btn {
      width: 40px;

      svg {
        width: 22px;
      }
    }

    .image {
      width: 47px;
      height: 47px;
      border-radius: 5px;
      overflow: hidden;
      margin-right: 15px;
      background: linear-gradient(to right, #ff8177, #b22b5b);

      @media screen and (max-width: 767px) {
        width: 35px;
        height: 35px;
        margin-right: 12px
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    h2 {
      font-size: 18px;
      font-weight: $boldText;
      color: $blackText;
      margin: 0;
      word-break: break-all;

      @media screen and (max-width: 767px) {
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    p {
      font-weight: 700;
      color: #f27b79;
      font-size: 10px;
      margin: 2px 0 0;
      word-break: break-all;
    }

    .ratingStars-grp {
      margin-left: 0 !important;
    }
  }

  .chat-box {
    width: 100%;
    padding: 20px 15px;
    position: relative;
    height: calc(100vh - 250px);

    @media screen and (max-width: 767px) {
      padding: 15px 0 120px;
      height: auto;
      flex-grow: 1;
    }

    @media screen and (max-width: 480px) {
      padding: 15px 0 120px;
    }

    .date {
      font-size: 12px;
      font-weight: $regularText;
      color: #828282;
      margin-bottom: 18px;
      @include flex-center;

      @media (max-width:768px) {
        margin: 0;
      }
    }

    .unmoveable-message {
      font-size: 12px;
      font-weight: $regularText;
      color: red;
      margin-bottom: 18px;
      @include flex-center;
    }

    .user-no-chat {
      .user-no-chat-description {
        font-weight: 700;
        text-align: center;
        color: red;
      }
    }

    .grey-color-btn {
      background: #b3b3b3 !important;
      border-color: #e2e2e2 !important;

      &:disabled {
        height: 24px;
      }
    }

    .btn-height {
      height: 24px;
    }

    .chat-list {
      height: auto;
      max-height: 100%;
      display: flex;
      flex-direction: column-reverse;
      overflow-y: auto;
      overflow-x: hidden;
      gap: 15px;

      .transfermix-name {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 10px;
      }

      @media screen and (max-width: 991px) {
        padding: 15px;
      }

      @media screen and (max-width: 767px) {
        position: relative;
        z-index: 1;
        border-radius: 5px;
        max-width: 100%;
        min-width: 100%;
        padding: 0;
        gap: 10px;
      }

      .time {
        @include flex;
        justify-content: flex-end;
        font-size: 10px;
        font-weight: $regularText;
        color: #fff;

        svg {
          margin-right: 5px;

          path {
            stroke: #fff;
          }
        }
      }


      .italic-text {
        font-style: italic;

        .time {
          font-style: normal;
        }
      }

      li {
        @include flex;
        align-items: flex-start;

        &.tcarDt {
          align-items: center !important;

          @media screen and (max-width: 420px) {
            margin-bottom: 15px;
          }
        }

        &:last-child {
          padding-bottom: 0;
          margin-top: 0;
        }

        .cashIcons {
          display: flex;
          justify-self: center;
          align-items: center;
          background: #dbdbdb;

          svg {
            margin: auto;
          }
        }

        .icon {
          width: 36px;
          height: 36px;
          display: block;
          border-radius: 50%;
          background: $lineargradient;
        }

        p {
          max-width: 100%;
          font-size: 14px;
          font-weight: $regularText;
          color: #fff;
          margin: 0;
          padding: 10px;
          position: relative;
          min-width: auto;
          word-break: break-all;
          border-radius: 0px 15px 15px 15px !important;

          @media screen and (max-width: 1199px) {
            max-width: 380px;
          }

          @media screen and (max-width: 991px) {
            max-width: 300px;
            padding: 15px;
          }

          svg {
            margin-right: 5px;

            path {
              stroke: #fff;
            }
          }

          .bluetick {
            path {
              stroke: #498fb4;
            }
          }

          @media screen and (max-width: 767px) {
            border-radius: 21px;
            max-width: calc(100% - 60px);
            min-width: calc(100% - 60px);
          }

          .time {
            @include flex;
            justify-content: flex-end;
            font-size: 10px;
            font-weight: $regularText;
            color: #848484;

            svg {
              margin-right: 5px;

              path {
                stroke: #fff;
              }
            }
          }
        }

        .swap-offer {
          max-width: 460px;
          font-size: 14px;
          font-weight: $regularText;
          margin: 0;
          padding: 10px;
          position: relative;
          border-radius: 15px 0px 15px 15px !important;
          min-width: auto;

          @media screen and (max-width: 1199px) {
            max-width: 380px;
          }

          @media screen and (max-width: 991px) {
            max-width: 300px;
            padding: 15px;
          }

          @media screen and (max-width: 767px) {
            border-radius: 21px;
            max-width: calc(100% - 60px);
            min-width: calc(100% - 60px);
          }

          @media screen and (max-width: 400px) {
            border-radius: 21px;
            max-width: calc(100% - 30px);
            min-width: calc(100% - 30px);
          }

          .time {
            @include flex;
            justify-content: flex-end;
            font-size: 10px;
            font-weight: $regularText;
            color: #fff;

            svg {
              margin-right: 5px;

              path {
                stroke: #fff;
              }
            }

            .bluetick {
              path {
                stroke: #498fb4;
              }
            }
          }
        }

        &.sender {
          justify-content: flex-start;
          margin-right: 51px;

          @media screen and (max-width: 991px) {
            margin-right: 46px;
          }

          .user {
            margin: 0 20px 0 20px;
            display: flex;
            align-items: center;

            @media (max-width:1024px) {
              margin: 0 10px 0 10px;
            }

            .pro-pic {
              width: 50px;
              height: 50px;
              display: flex;
              background: linear-gradient(to right, #ff8177, #b22b5b);
              border-radius: 50%;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .remove-bg {
              background: #dbdbdb;

              img {
                width: 24px;
                height: 24px;
                margin: auto;
              }
            }
          }

          .icon {
            margin-right: 15px;

            @media screen and (max-width: 991px) {
              margin-right: 10px;
            }
          }

          p {
            background-color: #f2f2f2;
            color: $darkgreyText;
            justify-content: flex-start;
            border-top-left-radius: 0;

            &::before {
              content: "";
              width: 20px;
              height: 19px;
              position: absolute;
              top: 0px;
              left: -15px;
              background: url(../assets/images/greyshade.svg) no-repeat center;
              background-size: contain;

              @media screen and (max-width: 1199px) {
                left: -14px;
              }
            }

            &.swap-offer {
              &::before {
                background: url(../assets/images/darkpinkshade2.svg) !important;
              }
            }
          }

          .swap-offer {
            background-color: #f2f2f2;
            color: $darkgreyText;
            justify-content: flex-start;
            border-top-left-radius: 0;

            @media screen and (max-width: 767px) {
              background-color: $whitebg;
            }

            &::before {
              content: "";
              width: 20px;
              height: 19px;
              position: absolute;
              top: 0px;
              left: -15px;
              background: url(../assets/images/greyshade.svg) no-repeat center;
              background-size: contain;

              @media screen and (max-width: 1199px) {
                left: -14px;
              }

              @media screen and (max-width: 767px) {
                background: url(../assets/images/whshade.svg);
                left: -11px;
              }
            }

            &.swap-offer {
              &::before {
                background: url(../assets/images/darkpinkshade2.svg) !important;
              }
            }
          }
        }

        &.reply {
          justify-content: flex-end;
          margin-left: 51px;

          @media screen and (max-width: 991px) {
            margin-left: 46px;
          }

          .user {
            margin: 0 40px 0 20px;
            display: flex;
            align-items: center;

            @media(max-width: 1024px) {
              margin: 0 10px;
            }

            @media(max-width: 767px) {
              margin: 0 10px;
            }

            .pro-pic {
              width: 50px;
              height: 50px;
              display: flex;
              background: linear-gradient(to right, #ff8177, #b22b5b);
              border-radius: 50%;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .remove-bg {
              background: #dbdbdb;

              img {
                width: 24px;
                height: 24px;
                margin: auto;
              }
            }
          }

          .icon {
            margin-left: 15px;

            @media screen and (max-width: 991px) {
              margin-left: 10px;
            }
          }

          p {
            background-color: #f27b79;
            color: $whiteText;
            justify-content: flex-end;
            border-top-right-radius: 0;

            &::after {
              content: "";
              width: 20px;
              height: 19px;
              position: absolute;
              top: 0px;
              right: -17px;
              background: url(../assets/images/pinkshade.svg) no-repeat center;
              background-size: contain;
            }

            &.swap-offer {
              &::after {
                background: url(../assets/images/darkpinkshade.svg) !important;
              }
            }
          }

          .swap-offer {
            background-color: #f27b79;
            color: $whiteText;
            border-radius: 10px 0 10px 10px;

            &::after {
              content: "";
              width: 20px;
              height: 19px;
              position: absolute;
              top: 0px;
              right: -17px;
              background: url(../assets/images/pinkshade.svg) no-repeat center;
              background-size: contain;
            }

            &.swap-offer {
              &::after {
                background: url(../assets/images/darkpinkshade.svg) !important;
              }
            }
          }
        }
      }
    }
  }

  .chat-list li:first-child {
    margin-bottom: 0px;

    @media screen and (max-width: 767px) {
      margin-bottom: 50px;
    }

    @media screen and (max-width: 500px) {
      margin-bottom: 40px;
    }

    @media screen and (max-width: 420px) {
      margin-bottom: 30px;
    }
  }

  .swap-offer {
    background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
    max-width: 100% !important;

    @media screen and (max-width: 1440px) {
      padding: 12px !important;
    }

    .text {
      font-size: 14px;
      font-weight: $boldText;
      color: $whiteText;
      margin-bottom: 16px;
      display: block;

      @media screen and (max-width: 1440px) {
        margin-bottom: 10px;
      }

      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }


    .transferItem-card {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;

      @media screen and (max-width: 1280px) {
        flex-direction: column;
      }

      ul li {
        margin-bottom: 0;

        @media screen and (max-width: 420px) {
          flex-direction: column;
          gap: 10px;


        }

        figure {
          @media screen and (max-width: 1199px) {
            width: 30px;
            height: 30px;
          }
        }
      }

      .tcarDt {
        h6 {
          color: #7c7c7c;
          font-weight: $regularText;
          word-break: break-all;

          @media screen and (max-width: 1440px) {
            font-size: 13px;
          }
        }

        h5 {
          color: $darkgreyText;
          font-weight: $boldText;
          font-size: 13px;
          word-break: break-all;
        }
      }

      .remove-bg {
        background: #dbdbdb;

        img {
          object-fit: cover;
          margin: auto;
          width: 24px;
          height: 24px;
        }
      }

      .ratingStars-grp {
        margin-left: 0;

        @media (max-width:520px) {
          justify-content: center;
          margin-top: 8px;
        }

        li {
          padding: 0;
          border-bottom: 0;
          margin-bottom: 0;
        }
      }

      h6 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80px;

        @media screen and (max-width: 1199px) {
          font-size: 13px;
          width: 100%;
        }
      }

      h5 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 165px;

        @media screen and (max-width: 1199px) {
          font-size: 12px;
        }
      }
    }

    .transferPay {
      .carIcon {
        margin-left: 7px;

        @media screen and (max-width: 1199px) {
          width: 30px;
          height: 30px;
        }

        img {
          @media screen and (max-width: 1199px) {
            width: 20px;
          }
        }
      }
    }

    .userTransfer-details {
      border-radius: 5px;
      padding: 10px 20px;

      @media screen and (max-width: 1440px) {
        padding: 0px 10px;
      }

      @media screen and (max-width: 1199px) {
        padding: 0 10px;
      }
    }

    .transferMethod {
      background: transparent !important;
      width: auto;
      margin: 0;
      padding: 20px;

      @media screen and (max-width: 1199px) {
        padding: 10px;
      }

      @media screen and (max-width: 1024px) {
        padding: 10px 0;
      }

      @media screen and (max-width: 991px) {
        padding: 10px 0;
      }

      @media screen and (max-width: 767px) {
        border-radius: 0;
        top: 0;
        transform: translate(0px, 0px);
      }

      svg {
        width: 25px;
        height: 25px;

        @media screen and (max-width: 1199px) {
          width: 20px;
          height: 20px;
        }

        @media screen and (max-width: 1024px) {
          transform: rotate(90deg);
        }
      }

      h3 {
        font-size: 16px;

        @media screen and (max-width: 1199px) {
          font-size: 14px;
        }

        @media screen and (max-width: 1024px) {
          margin-top: 10px;
        }

        @media screen and (max-width: 991px) {
          @include flex-center;
        }

        @media screen and (max-width: 768px) {
          margin-top: 0;
        }

        span {
          @media screen and (max-width: 991px) {
            padding: 0 5px;
            display: block;
          }
        }
      }
    }

    .traderDetails {
      background-color: $whitebg;
      padding: 10px 20px;

      @media screen and (max-width: 1440px) {
        padding: 0px 10px;
      }

      @media screen and (max-width: 1280px) {
        padding: 5px 15px;
      }

      @media screen and (max-width: 1199px) {
        padding: 0 10px;
      }

      @media screen and (max-width: 767px) {
        margin-top: 0;
      }

      h6 {
        font-size: 14px;

        @media screen and (max-width: 1199px) {
          font-size: 12px;
        }
      }

      h6.cash {
        color: #f27b79;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: $boldText;
        width: auto;
      }

      .transferPay h4 {
        font-size: 10px;
      }
    }

    .button-wrap {
      margin-top: 10px;

      @media screen and (max-width: 1440px) {
        margin-top: 12px;
      }

      @media (max-width:768px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
      }

      @include flex;
      align-items: flex-end;
      justify-content: flex-end;

      .custom-btn {
        min-height: 24px;
        font-size: 10px;
        min-width: 110px;
        margin: 0 5px;

        @media screen and (max-width: 991px) {
          min-width: 80px;
          margin: 0 4px;
        }

        @media screen and (max-width: 800px) {
          min-width: 60px;
          margin: 0 3px;
        }

        &[disabled] {
          background: rgb(166 166 166 / 14%);
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .offer-text-info {
        color: #fff;
        justify-content: flex-start !important;
        font-size: 16px;
      }
    }

    .content-wrap {
      margin-top: 8px;

      @media screen and (max-width: 1440px) {
        margin-top: 12px;
      }

      @include flex;
      justify-content: flex-start;

      .custom-btn {
        min-height: 24px;
        font-size: 10px;
        min-width: 110px;
        margin: 0 5px;

        @media screen and (max-width: 991px) {
          min-width: 80px;
          margin: 0 4px;
        }

        @media screen and (max-width: 767px) {
          min-width: 60px;
          margin: 0 3px;
        }

        &[disabled] {
          background: rgb(166 166 166 / 14%);
        }
      }

      .offer-text-info {
        color: #fff;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 700;
      }
    }

    .groupStatus {
      position: relative;
      z-index: 999;

      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }

  .sender .overlay-offer {
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      inset: 0;
      margin: auto;
      height: 100%;
      width: 100%;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.348);
    }
  }

  .reply .overlay-offer {
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      inset: 0;
      margin: auto;
      height: 100%;
      width: 100%;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.348);
      border-radius: 13px 0 13px 13px;
    }
  }

  .chat-action {
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 5px 20px 25px;

    @media screen and (max-width: 767px) {
      border: none;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      background: #fff;
      padding: 10px;
      border-radius: 0 0 10px 10px;
    }

    .input-collection {
      position: relative;

      .error {
        margin-left: 2px;
        font-size: 14px;
      }

      input {
        padding-right: 42px;
      }

      .loader-spinner {
        position: absolute;
        inset: 0;
      }
    }

    .input-attachment {
      overflow: hidden;
      position: absolute;
      right: 10px;
      top: 5px;
      bottom: 0;
      margin: auto;
    }

    .custom-tab {
      .nav li {
        padding: 0 0 5px;

        @media screen and (max-width: 767px) {
          padding: 6px 10px;
          border: none;
        }
      }

      .content {
        padding: 15px 0 0;

      }
    }

    .make-offer {
      .error {
        color: red;
        font-size: 14px;
      }
    }

    .select-head {
      @include flex;
      padding-bottom: 20px;

      .radio-buttonCheck {
        padding-right: 30px;

        @media screen and (max-width: 767px) {
          padding-right: 15px;
        }
      }
    }

    .custom-form {
      @include flex;
      align-items: flex-start;
      .custom-input {
        width: 100%;
      }

      input {
        background-color: #fafafa;
        border-color: #d9d9d9;
        height: 42px;
        box-shadow: none !important;

        &::placeholder {
          color: $lightgreyText;
        }
      }

      .send-btn {
        margin-left: 13px;

        @media screen and (max-width: 767px) {
          margin-left: 8px;
        }
      }
    }

    .select-content {
      padding-top: 10px;
    }

    .swap-cash {
      .custom-input {
        @include flex;
      }

      .select {
        width: 175px;

        .css-13cymwt-control,
        .css-t3ipsp-control {
          border-right: none;
          border-radius: 5px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          padding: 0 10px 0 20px;
          border-color: #d9d9d9;
          background-color: #fafafa;

          @media screen and (max-width: 767px) {
            border-radius: 25px 0 0 25px;
          }
        }
      }

      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .list-only-attachment {
      flex: 1 1 100%;
    }

    .attachment-images-list {
      display: flex;
      overflow-x: unset;
      width: 100%;
      flex-wrap: wrap;
      margin: 0 -10px;

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #bebebe;
      }

      &::-webkit-scrollbar-thumb {
        background: #727272;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #515151;
      }

      p {
        text-align: center;
        white-space: nowrap;
      }

      li {
        width: calc(100% / 5);
        height: 60px;
        border-radius: 5px;
        position: relative;
        padding: 0 5px;

        @media screen and (max-width: 767px) {
          height: 60px;
          border-radius: 10px;
        }

        .tooltip-button {
          &::after {
            top: unset;
            bottom: 20px;
            left: 4px;
            right: 0;
            margin: auto;
            transform: translate(0);
            font-size: 9px;
            font-weight: bold;
          }

          &::before {
            top: -2px;
            left: 0;
            right: 0;
            margin: auto;
            pointer-events: none;
            transform: rotate(180deg);
          }
        }

        img {
          width: 100%;
          height: 100%;
          max-height: 60px;
          object-fit: contain;
          object-position: center;
          border-radius: 5px;
          background: #e3e3e3;
        }

        .list-car-name {
          display: block;
          text-align: center;
          margin: auto;
          width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 12px;
        }

        .tick-icon-grey {
          position: absolute;
          bottom: 6px;
          right: 6px;
          width: 19px;
          height: 19px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.5);
          display: block;
        }

        &.selected {
          .tick-icon {
            position: absolute;
            bottom: 6px;
            right: 6px;
            width: 19px;
            height: 19px;
            border-radius: 50%;
            background: $lineargradient;
            display: block;

            &::after {
              content: "";
              position: relative;
              left: 7px;
              top: 5px;
              width: 3px;
              height: 6px;
              border: solid #ffffff;
              border-width: 0 1px 1px 0;
              transform: rotate(45deg);
              display: block;
            }
          }
        }

        .more-photos {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgb(0 0 0 / 60%);
          color: $whiteText;
          @include flex-center;
          font-size: 16px;
          font-weight: $regularText;
          cursor: pointer;
        }

        .img-block {
          position: relative;
          width: 100%;

          .close-btn {
            position: absolute;
            right: 10px;
            top: 0px;
            background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
            width: 25px;
            height: 25px;
            border-radius: 100px;
            padding: 4px;

            svg {
              position: relative;
              top: -5px;
              width: 10px;
              height: 10px;

              path {
                fill: #fff;
              }
            }
          }
        }
      }
    }

    .images-list {
      display: flex;
      overflow-x: auto;
      gap: 10px;
      margin-bottom: 10px;

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #bebebe;
      }

      &::-webkit-scrollbar-thumb {
        background: #727272;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #515151;
      }

      p {
        text-align: center;
        white-space: nowrap;
      }

      li {
        width: 180px;
        height: 60px;
        border-radius: 5px;
        flex: 0 0 150px;
        position: relative;

        @media screen and (max-width: 767px) {
          height: 60px;
          border-radius: 10px;
        }

        .tooltip-button {
          &::after {
            top: unset;
            bottom: 20px;
            left: 4px;
            right: 0;
            margin: auto;
            transform: translate(0);
            font-size: 9px;
            font-weight: bold;
          }

          &::before {
            top: -2px;
            left: 0;
            right: 0;
            margin: auto;
            pointer-events: none;
            transform: rotate(180deg);
          }
        }

        img {
          width: 100%;
          height: 100%;
          max-height: 40px;
          object-fit: contain;
          object-position: center;
          border-radius: 5px;
          background: #e3e3e3;
        }

        .list-car-name {
          display: block;
          text-align: center;
          margin: auto;
          width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 12px;
        }

        .tick-icon-grey {
          position: absolute;
          bottom: 1.3px;
          right: 3px;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.5);
          display: block;
        }

        &.selected {
          .tick-icon {
            position: absolute;
            bottom: 1.3px;
            right: 3px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: $lineargradient;
            display: block;

            &::after {
              content: "";
              position: relative;
              left: 7px;
              top: 5px;
              width: 3px;
              height: 6px;
              border: solid #ffffff;
              border-width: 0 1px 1px 0;
              transform: rotate(45deg);
              display: block;
            }
          }
        }

        .more-photos {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgb(0 0 0 / 60%);
          color: $whiteText;
          @include flex-center;
          font-size: 16px;
          font-weight: $regularText;
          cursor: pointer;
        }

        .img-block {
          position: relative;
          width: 150px;
          height: 80px;

          .close-btn {
            position: absolute;
            right: 12px;
            top: 0px;
            background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
            width: 30px;
            height: 30px;
            border-radius: 100px;
            padding: 4px;

            svg {
              position: relative;
              top: -4px;
              width: 10px;
              height: 10px;

              path {
                fill: #fff;
              }
            }
          }
        }
      }
    }

    .info-wrap {
      font-size: 14px;
      font-weight: $regularText;
      color: #6c6c6c;
      @include flex;
      margin-bottom: 10px;

      span {
        font-weight: $boldText;
        margin: 0 8px;
        display: block;
      }
    }

    .button-wrap {
      @include flex-end;
    }
  }

  .empty-chat-wrap {
    overflow: hidden;
    position: relative;

    @media (max-width:768px) {
      display: none;
    }

    .chat-head {
      padding: 20px 15px;
    }

    .chat-body {
      height: 100%;
      overflow: hidden;

      .chat-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        margin-top: 60px;

        @media (max-width: 1100px) {
          margin: 30px;
        }

        @media (max-width: 680px) {
          margin: 0;
        }

        .chat-wrap-txt {
          position: relative;
          z-index: 999;

          svg {
            display: block;
            margin: auto;
          }
        }

        .empty-chat-img {
          position: absolute;
          bottom: 0;
        }

        h1 {
          font-size: 24px;
          font-weight: 700;
          margin: 8px 0;
          background: linear-gradient(90deg, #fb7d75 0%, #b7305d 98.37%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;
        }

        p {
          color: #353333;
          font-size: 16px;
          font-weight: 700;
          text-align: center;
        }
      }
    }
  }


  @media (max-width:1024px) {
    .traderDetails {
      padding: 10px 0;
      margin-top: 0;
    }

    .transferMethod {
      transform: translate(0, 0)
    }
  }
}

.jumping-dots {
  @include flex;
  justify-content: flex-end;
  padding: 5px 0;

  span {
    display: block;
    position: relative;
    margin: 0;
    animation: jump 1s infinite;
    background-color: #e4e4e4;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 3px;

    &:nth-child(2) {
      background-color: $whitebg;
    }
  }

  .dot-1 {
    animation-delay: 200ms;
  }

  .dot-2 {
    animation-delay: 400ms;
  }

  .dot-3 {
    animation-delay: 600ms;
  }
}

@keyframes jump {
  0% {
    bottom: 0px;
  }

  20% {
    bottom: 5px;
  }

  40% {
    bottom: 0px;
  }
}

// table
.table-wrap {
  overflow-x: auto;

  table {
    width: 100%;

    thead {
      background-color: #f3f3f3;

      th {
        font-size: 14px;
        font-weight: $regularText;
        color: $lightgreyText;
        padding: 3px 0;

        &:first-child {
          padding-left: 65px;
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 14px;
          font-weight: $regularText;
          color: $blackText;
          padding: 13px 0;

          .name {
            @include flex;

            span {
              width: 60px;
              height: 60px;
              border-radius: 4px;
              overflow: hidden;
              margin-right: 20px;

              @media screen and (max-width: 991px) {
                margin-right: 15px;
                width: 50px;
                height: 50px;
              }

              img {
                @include object-fit;
              }
            }

            p {
              margin: 0;
              color: $darkgreyText;
              font-weight: $boldText;
              word-break: break-all;
            }
          }

          .action {
            @include flex;

            span {
              width: 17px;
              margin-right: 7px;

              img {
                @include object-fit;
              }
            }
          }

          &.trade-num {
            color: $pinkText;
            text-transform: uppercase;
            font-weight: $boldText;
          }

          .offer-btn-block {
            border-radius: 5px;
            padding: 2px 10px;
            color: #fff;
            font-size: 10px;
            font-weight: 700;
            margin-top: 2px;
            display: flex;
            width: 80px;
            justify-content: center;

            &.sender {
              background: #0075ff;
            }

            &.received {
              background: #20cf1c;
            }

            &.sold {
              background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
            }
          }

          .ratingStars-grp {
            margin-left: 30px;
          }
        }

      }
    }
  }
}

.blocked-users {
  width: 100%;
  background-color: $whitebg;
  border-radius: 4px;
  border: 1px solid #e8e8e8;

  @media screen and (max-width: 767px) {
    background-color: transparent;
    border: none;
  }

  .title-wrp {
    padding: 0;
    margin-bottom: 0;

    @media (max-width:769px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .table-wrap table thead th {
      padding: 3px 15px;
    }
  }

  .search-wrap {
    width: 370px;
    max-width: 100%;

    @media screen and (max-width: 767px) {
      margin-top: 10px;
      width: 100%;
    }

    input {
      background-color: transparent;
    }
  }

  .table-wrap table thead th {
    padding: 7px 15px !important;
  }
}

// modal
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  min-height: 100vh;
  transition: all ease 1s;
  z-index: 11111;

  .back-drop {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.modal-wrap {
  @extend .overlay;
  @include flex-center;

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
    gap: 10px;
  }

  .modal-body {
    max-width: 450px;
    background-color: #f6f6f6;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    animation: animatetop 0.8s ease forwards;
    padding: 35px;
    text-align: center;

    @media screen and (max-width: 991px) {
      padding: 30px;
    }

    @media screen and (max-width: 769px) {
      padding: 10px;
    }

    @media screen and (max-width: 441px) {
      max-width: 90%;
      margin: auto;
    }

    @-webkit-keyframes animatetop {
      from {
        top: -300px;
        opacity: 0;
      }

      to {
        top: 0;
        opacity: 1;
      }
    }

    @keyframes animatetop {
      from {
        top: -300px;
        opacity: 0;
      }

      to {
        top: 0;
        opacity: 1;
      }
    }
  }

  .modal-head {
    margin-bottom: 10px;

    .icon {
      display: block;
      width: 53px;
      height: 53px;
      margin: 0 auto 15px;
      background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
      border-radius: 50%;
      padding: 10px;
      @include flex-center;

      @media screen and (max-width: 767px) {
        width: 50px;
        height: 50px;
      }

      svg {
        color: $whitebg;
        font-size: 22px;
      }
    }

    h3 {
      font-size: 22px;
      font-weight: $boldText;
      color: $darkgreyText;
      margin-bottom: 10px;
      text-transform: uppercase;
      max-width: 100% !important;

      @media screen and (max-width: 767px) {
        font-size: 18px;
      }

      span {
        font-size: 16px;
        color: #343434;
        font-weight: $regularText;
        display: block;
        margin-top: 6px;
        text-transform: none;
      }
    }

    h4 {
      font-size: 18px;
      font-weight: $boldText;
      color: $blackText;
      margin-bottom: 0;
      text-transform: uppercase;

      span {
        font-size: 14px;
        color: #f27b79;
        display: block;
        font-weight: $regularText;
        margin-top: 10px;
        text-transform: capitalize;
      }
    }

    h5 {
      font-size: 14px;
      font-weight: $boldText;
      color: $blackText;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }

  .modal-content {
    border: none;
    background-color: transparent;
    margin-bottom: 20px;
    max-height: 68vh;
    padding: 12px;
    overflow: auto;

    h6 {
      font-size: 16px;
      font-weight: $regularText;
      color: $blackText;
      margin-bottom: 25px;
    }

    p {
      font-size: 14px;
      font-weight: $regularText;
      color: $lightgreyText;
      margin: 5px;
    }

    .error {
      color: red;
      font-size: 13px;
    }

    .no-data {
      height: 35vh;
    }
  }

  .modal-content-transfer {
    border: none;
    background-color: transparent;
    max-height: 68vh;
    overflow: auto;

    h6 {
      font-size: 16px;
      font-weight: $regularText;
      color: $blackText;
      margin-bottom: 25px;
    }

    p {
      font-size: 14px;
      font-weight: $regularText;
      color: $lightgreyText;
      margin: 5px;
    }

    .error {
      color: red;
      font-size: 13px;
    }

    .no-data {
      height: 35vh;
    }
  }

  .modal-foot {
    border: none;
    padding: 0;
    @include flex-center;
    margin-top: 25px;

    @media (max-width:520px) {
      flex-direction: column;
      gap: 10px;
      flex-wrap: wrap;
    }

    .custom-btn {
      margin: 0 4px;

      @media (max-width:520px) {
        width: 100%;
        margin: 0;
      }
    }
  }

  .modal-foot-transfer {
    border: none;
    padding: 0;
    @include flex-center;
    margin-top: 10px;

    @media (max-width:520px) {
      flex-direction: column;
      gap: 10px;
      flex-wrap: wrap;
    }

    .custom-btn {
      margin: 0 4px;

      @media (max-width:520px) {
        width: 100%;
        margin: 0;
      }
    }
  }

  .custom-input {
    input {
      background-color: transparent;

      @media screen and (max-width: 767px) {
        background-color: transparent;
      }
    }
  }

  &.thankYou-modal {
    .modal-foot {
      flex-direction: column;
    }

    .modal-foot-transfer {
      flex-direction: column;
    }

    .rate-experience {
      padding-top: 30px;
      font-size: 14px;
      font-weight: $regularText;
      color: #585858;
      @include flex-center;

      span {
        margin-right: 10px;
      }
    }
  }

  &.areYou-modal {
    h6 {
      text-align: left;
    }

    .modal-content {
      text-align: left;
    }

    .circle-radiobtn {
      margin-right: 50px;

      @media screen and (max-width: 767px) {
        margin-right: 40px;
      }
    }
  }

  &.verify-modal {
    .modal-head {
      position: relative;
      margin-bottom: 25px;

      @media screen and (max-width: 767px) {
        margin-bottom: 15px;
      }

      .back-btn {
        position: absolute;
        left: 0px;

        svg {
          width: 28px;
        }
      }

      h2 {
        font-size: 30px;
        font-weight: $boldText;
        color: $blackText;
        margin: 20px 0 10px;
        text-align: left;

        @media screen and (max-width: 767px) {
          font-size: 24px;
        }
      }

      p {
        font-size: 14px;
        font-weight: $regularText;
        color: #909090;
        text-align: left;

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }

    .icon {
      background: url("../../src/assets/images/verify-bg.svg");
      width: 86px;
      height: 68px;
      padding: 5px;

      img {
        width: 40px;
      }
    }

    .enter-code {
      margin-bottom: 20px;
      position: relative;

      &.input-email-enter {
        input {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 60px;
        }
      }

      input {
        width: 100%;
        border: none;
        border-radius: 0;
        font-size: 16px;
        font-weight: $regularText;
        color: $blackText;
        padding: 0 0 10px;
        outline: none;
        background-color: transparent;

        &::placeholder {
          color: $blackText;
        }
      }

      .edit {
        position: absolute;
        top: 4px;
        right: 0;
        color: $lightgreyText;
        font-size: 12px;
        font-weight: $regularText;
        cursor: pointer;

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }

      &::after {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: $lineargradient;
      }
    }

    .custom-input input {
      letter-spacing: 10px;

      &::placeholder {
        letter-spacing: normal;
      }
    }

    .timer {
      width: 100%;
      @include flex-between;
      margin-top: 15px;
      cursor: pointer;

      p {
        margin: 0;
        font-size: 14px;
        font-weight: $regularText;
        color: $darkgreyText;

        @media screen and (max-width: 767px) {
          font-size: 13px;
        }
      }
    }

    .custom-input {
      .right-text {
        text-align: right;
        font-size: 13px;
        font-weight: $regularText;
        color: $darkgreyText;
        margin-top: 10px;

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }

    .modal-foot {
      flex-direction: column;

      .custom-btn {
        &:first-child {
          margin: 0;
        }
      }
    }
  }

  &.editValue-modal {
    .modal-body {
      text-align: left;

      .modal-content {
        p {
          margin-bottom: 20px;
        }
      }

      .modal-foot {
        justify-content: flex-start;

        .custom-btn {
          min-width: 50%;
        }

        .custom-btn:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &.avatar-modal {
    .modal-body {
      max-width: 550px;

      @media screen and (max-width: 767px) {
        max-width: 90%;
      }
    }

    .close-btn {
      position: absolute;
      right: 20px;
      top: 20px;
      background: #b22b5b;
      height: 24px;
      width: 24px;
      border-radius: 100px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 10px;
        height: 10px;

        path {
          fill: #fff;
        }
      }
    }
  }

  // old modal styles
  .select-list {
    li {
      font-size: 16px;
      font-weight: $regularText;
      color: $lightgreyText;
      text-align: left;
      padding: 15px 0;
      position: relative;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(to right, #e9e9e9, #cecece);
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;

        &::after {
          display: none;
        }
      }

      &.active {
        background-color: $blackbg;
        border-radius: 5px;
        color: $whiteText;
        padding: 9px 25px;

        @media screen and (max-width: 767px) {
          border-radius: 34px;
        }

        &::after {
          display: none;
        }
      }
    }
  }

  &.valuationError-modal {
    .modal-body {
      .modal-foot {
        flex-direction: column;

        .go-back {
          font-size: 14px;
          font-weight: $regularText;
          color: $darkgreyText;
          margin-top: 20px;
        }

        .custom-btn {
          margin: 0 !important;
        }
      }
    }
  }

  &.carValue-modal {
    .modal-body {
      background: #f6f6f6;
      text-align: left;

      @media screen and (min-width: 576px) {
        max-width: 460px;
      }

      p {
        margin-bottom: 25px !important;
      }

      .custom-input {
        margin-bottom: 5px;
      }
    }

    .modal-content {
      overflow: unset;
    }

    .button-group {
      flex-direction: initial;
      margin-top: 25px;

      .custom-btn {
        min-width: 48%;
        margin: 0 5px !important;
      }

      .custom-btn:first-child {
        margin-left: 0;
      }
    }

    .custom-input input {
      background-color: white;
    }
  }

  &.valueInfo-modal {
    .modal-content {
      p {
        margin-bottom: 5px;
      }
    }
  }

  &.extraItems-modal {
    .modal-head {
      h5 {
        text-align: left;
        font-size: 18px;
        margin-bottom: 20px;
      }
    }

    h6 {
      text-align: left;
      font-size: 16px;
      font-weight: $boldText;
      color: rgb(3 3 3 / 72%);
      margin-bottom: 10px;
    }

    .custom-tab {
      .nav {
        li {
          padding: 0 10px 10px;
          font-weight: $regularText;

          &.active {
            font-weight: $boldText;
          }
        }
      }

      .content {
        padding: 25px 0 0;
      }
    }

    p {
      text-align: left;
    }

    .custom-input {
      margin: 15px 0 20px;

      input {
        padding-right: 45px;
      }

      .action {
        position: absolute;
        top: 8px;
        right: 9px;
        width: 26px;
        height: 26px;
        border-radius: 5px;
        background-color: $blackbg;
        @include flex-center;
        border: none;

        @media screen and (max-width: 767px) {
          border-radius: 50%;
        }

        svg {
          fill: $whitebg;
        }
      }
    }

    .item-list {
      li {
        background-color: #f6f6f6;
        @include flex-between;
        padding: 15px 0;
        border-bottom: 1px solid #e6e6e6;
        border-radius: 0;

        @media screen and (max-width: 767px) {
          background-color: transparent;
        }

        p {
          font-size: 14px;
          font-weight: $regularText;
          color: #535353;
          margin-bottom: 0;
        }

        .action {
          width: 19px;
          height: 19px;
          background-color: $blackbg;
          border-radius: 50%;
          @include flex-center;
          border: none;
          padding: 0;
          margin-left: 13px;

          svg {
            fill: $whitebg;
            font-size: 14px;
          }
        }
      }
    }

    .error-msg {
      background-color: rgb(221 221 221 / 31%);
      border-radius: 5px;
      padding: 22px 22px 30px;

      @media screen and (max-width: 767px) {
        border-radius: 20px;
      }

      .icon {
        width: 47px;
        height: 47px;
        background: #b22b5b;
        border-radius: 50%;
        @include flex-center;
        margin: 0 auto 10px;
      }

      h5 {
        font-size: 20px;
        font-weight: 700;
        color: $blackText;
        margin-bottom: 10px;
      }
    }
  }

  .listed-items {
    h4 {
      font-size: 20px;
      font-weight: $boldText;
      color: $darkgreyText;
      margin-bottom: 40px;

      @media screen and (max-width: 991px) {
        font-size: 18px;
      }

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    .note {
      padding-top: 15px;
    }
  }
}

// Subscription
.subscription-warp {
  .container-wrap {
    width: 900px;
  }

  .cmn-head {
    margin-bottom: 0;
  }

  .subCard-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }

    li {
      height: 100%;
    }

    .subscription-card {
      height: 100%;
    }
  }

  .italic-text {
    font-size: 16px;
    color: #ffedf6;
    font-weight: $regularText;
    font-style: italic;
    margin-top: 8px;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.subscription-card {
  background: $whitebg;
  border-radius: 5px;
  padding: 15px;

  .card-head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    p {
      font-size: 14px;
      font-weight: $regularText;
      color: #686868;
      line-height: 18px;

      span {
        font-size: 42px;
        color: $blackText;
        font-family: "Magistral", sans-serif;
        font-weight: $boldText;
        line-height: 56px;
        margin-right: 5px;

        @media screen and (max-width: 767px) {
          font-size: 34px;
        }
      }
    }
  }

  .level {
    background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
    border-radius: 2px;
    font-size: 14px;
    font-weight: $boldText;
    color: $whiteText;
    padding: 2px 16px;
    text-transform: uppercase;

    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }

  .card-action {
    border-top: 1px solid #e2e2e2;
    @include flex-between;
    padding-top: 10px;

    h6 {
      font-size: 15px;
      color: #5c5c5c;
      font-family: "Magistral", sans-serif;
      font-weight: $boldText;
    }
  }

  .custom-btn {
    border-radius: 2px;
    padding: 8px 13px;
  }

  &.active-card {
    background: $orangGradient;
    text-align: center;

    h6 {
      font-size: 16px;
      font-weight: $regularText;
      color: #ffedf6;
      margin-bottom: 5px;
    }

    h3 {
      font-size: 24px;
      font-weight: $boldText;
      color: #ffedf6;
      margin-bottom: 5px;

      @media screen and (max-width: 767px) {
        font-size: 22px;
      }
    }

    p {
      font-size: 12px;
      font-weight: $regularText;
      color: #ffedf6;
      margin-bottom: 10px;
    }

    .custom-btn {
      margin: 0 auto;
    }
  }
}

// signIn
.signIn-wrap {
  padding-bottom: 0;

  .container-wrap {
    margin-bottom: 0;
  }

  .signIn {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 60px 0 0;

    .custom-input {
      input {
        text-transform: lowercase;

        &::placeholder {
          text-transform: capitalize;
        }
      }
    }

    @media screen and (max-width: 991px) {
      padding: 40px 0 60px;
      position: relative;
    }
  }

  .banner-wrap {
    @media screen and (max-width: 767px) {
      display: none;
    }

    p {
      font-size: 16px;
      font-weight: $regularText;
      color: #ffedf6;
      padding-right: 210px;

      @media screen and (max-width: 1199px) {
        padding-right: 194px;
      }

      @media screen and (max-width: 991px) {
        padding-right: 130px;
      }
    }

    img {
      @media screen and (max-width: 1199px) {
        width: 550px;
      }

      @media screen and (max-width: 1024px) {
        width: 400px;
      }

      @media screen and (max-width: 991px) {
        width: 350px;
      }
    }
  }

  h1 {
    font-size: 37px;
    color: $whiteText;
    font-weight: $regularText;
    margin-bottom: 20px;

    @media screen and (max-width: 1199px) {
      font-size: 33px;
    }

    @media screen and (max-width: 1024px) {
      font-size: 30px;
    }

    @media screen and (max-width: 991px) {
      font-size: 24px;
    }

    span {
      font-size: 60px;
      font-weight: $boldText;
      display: block;

      @media screen and (max-width: 1199px) {
        font-size: 50px;
      }

      @media screen and (max-width: 1024px) {
        font-size: 35px;
      }

      @media screen and (max-width: 991px) {
        font-size: 30px;
      }
    }
  }

  .banner-image {
    margin-top: 30px;

    @media screen and (max-width: 1024px) {
      margin-top: 67px;
    }

    @media screen and (max-width: 991px) {
      position: absolute;
      bottom: 0;
    }
  }

  .custom-input {
    margin-bottom: 15px !important;
  }
}

.form-wrap {
  width: 390px;
  background-color: $whitebg;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0px rgb(0 0 0 / 25%);
  padding: 40px;

  @media screen and (max-width: 1024px) {
    width: 490px;
    padding: 30px;
  }

  @media screen and (max-width: 991px) {
    width: 590px;
    padding: 30px;
  }

  @media screen and (max-width: 767px) {
    @include full-space;
    border: none;
    box-shadow: none;
    padding: 15px;
  }

  .head {
    margin-bottom: 25px;

    @media screen and (max-width: 991px) {
      margin-bottom: 20px;
    }

    h2 {
      font-size: 26px;
      font-weight: $boldText;
      color: $blackText;
    }

    p {
      font-size: 14px;
      font-weight: $regularText;
      color: #909090;
      margin-top: 10px;
    }
  }

  h6 {
    font-size: 14px;
    font-weight: $regularText;
    color: #8f8f8f;
    margin: 20px 0 0;
    text-align: center;

    a {
      color: #1e1e1e;
      font-weight: $boldText;
      padding-left: 5px;
    }
  }

  .signup-label {
    color: #1e1e1e;
    font-weight: $boldText;
    padding-left: 5px;
    cursor: pointer;
  }

  .custom-checkbox {
    margin-bottom: 20px;
  }

  .checkbox-wrp {
    p {
      font-size: 12px;
      font-weight: $regularText;
      color: $lightgreyText;
      margin-bottom: 0;

      span {
        color: $blackText;
      }
    }
  }

  .form-footer {
    margin-top: 40px;

    @media screen and (max-width: 991px) {
      margin-top: 25px;
    }
  }

  .divider {
    position: relative;
    text-align: center;
    @include flex-center;

    span {
      font-size: 14px;
      font-weight: 400;
      color: $lightgreyText;
      background-color: #fff;
      padding: 0 10px;
      position: relative;

      @media screen and (max-width: 767px) {
        background-color: #fafafa;
      }
    }

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background-color: rgb(129 127 127 / 50%);
      position: absolute;
      left: 0;
      top: 14px;
      z-index: 0;
    }
  }

  .social-media {
    margin-top: 25px;
    @include flex-center;

    a {
      display: block;
      margin: 0 10px;
      cursor: pointer;

      @media screen and (max-width: 767px) {
        margin: 0 5px;
      }
    }
  }


}

// create account
.create-account {
  overflow: hidden;

  .custom-input {

    input {
      &::placeholder {
        color: #000 !important;
        opacity: 1 !important;
      }

    }

    textarea {
      &::placeholder {
        color: #000 !important;
        opacity: 1 !important;
      }
    }

    .reactAsyncSelect__placeholder {
      color: #000;
    }

    // New added styles
    label {
      color: #000;
    }

    .radio-wrap {
      overflow: unset !important;
    }

    .delete-button {
      cursor: pointer;
      padding-right: 5px;
    }

    .delete-icon {
      padding-bottom: 5px;
    }

    &.email-input {
      input {
        padding-right: 70px;
      }
    }
  }

  .custom-checkbox {
    .checkmark {
      transform: translate(0, -1px);

      &:after {
        top: 2px;
      }
    }
  }

  .cmn-head {
    margin-bottom: 0;
  }

  .spinner {
    top: -4px;
  }

  .white-card {
    display: grid;
    grid-template-columns: 120px auto;
    column-gap: 25px;
    padding: 25px;

    @media screen and (max-width: 991px) {
      grid-template-columns: 100%;
    }
  }

  .exterior-select {

    .reactAsyncSelect__value-container,
    .reactAsyncSelectErr__value-container {
      padding: 0;
      padding-left: 15px;
    }

    input {
      min-height: 40px;
      border: 1px solid #e4e4e4;
      outline: inherit;
      padding: 4px 20px;
    }

    .reactAsyncSelect__control {
      border-color: #e4e4e4;
      box-shadow: none !important;
    }

    .reactAsyncSelectErr__control {
      border-color: red;
      border-radius: 5px;
      box-shadow: none !important;
    }

    .reactAsyncSelect__input-container,
    .reactAsyncSelectErr__input-container {
      padding: 0;
      margin: 0;

      .reactAsyncSelect__input,
      .reactAsyncSelectErr__input {
        text-transform: uppercase;
        width: auto !important;
      }
    }

    .light__single-value,
    .reactAsyncSelect__single-value,
    .reactAsyncSelectErr__single-value {
      font-weight: 700;
      color: #353333;
      font-size: 14px;
      padding: 4px 20px;
      padding-left: 0px;
    }
  }

  .white-card-myprofile {
    display: grid;
    grid-template-columns: 119px auto;
    column-gap: 25px;

    @media screen and (max-width: 991px) {
      grid-template-columns: 100%;
    }
  }

  .form-container {
    display: grid;
    grid-template-columns: minmax(0px, 1fr) 50%;
    column-gap: 25px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
    }
  }

  .avatar-wrap {
    .choose-file {
      .custom-file-input {
        padding: 13px;
      }

      input {
        opacity: 0;
      }

      .custom-file-input-icon {
        padding: 50px 48px !important;
      }
    }

    @media screen and (max-width: 991px) {
      margin: 0 auto 30px;
    }

    p {
      font-size: 14px;
      font-weight: $regularText;
      color: $darkgreyText;
      margin-top: 10px;
      text-align: center;
      cursor: pointer;
    }
  }

  .select-avatar {
    width: 119px;
    height: 119px;
    border-radius: 5px;
    background: $lineargradient;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 119px;
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
    }

    .close-btn {
      position: absolute;
      right: 3px;
      top: 3px;
      background: rgb(255 255 255);
      border-radius: 100px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      z-index: 999;

      img {
        height: auto !important;
      }
    }

    .choose-file {
      height: 100%;

      .custom-file-input {
        border: none;
      }

      .click-here {
        background: transparent !important;
      }

      label {
        display: none;
      }

      svg {
        width: 31px;

        path {
          stroke: $whitebg;
        }
      }
    }
  }

  .remove-bg {
    background: #dbdbdb;

    img {
      object-fit: cover;
      margin: auto;
      width: 75px;
      height: 75px
    }
  }

  .cursor {
    cursor: pointer;
  }

  .button-wrap {
    @include flex-end;
    margin-top: 25px;

    @media (max-width:768px) {
      justify-content: center;
    }

    .custom-btn {
      min-width: 140px;
    }

    .custom-button-cancel {
      margin-right: 15px;
    }
  }

  .radio-wrap {
    height: calc(100% - 56px);
    justify-content: space-between;

    input {
      min-height: auto;
    }
  }

  .italic-text {
    font-size: 12px;
    font-weight: $regularText;
    color: gray;
    font-style: italic;

    span {
      color: red;
    }
  }
}

.choose-avatar {
  p {
    font-size: 14px;
    font-weight: $regularText;
    color: $darkgreyText;
    margin-bottom: 20px;

    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }
}

.avatar-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  li {
    figure {
      background: #f1f1f1;
      border-radius: 5px;
      @include flex-center;
      padding: 15px;
      cursor: pointer;
      overflow: hidden;
      transition: 0.3s;

      @media screen and (max-width: 767px) {
        border-radius: 50%;
        background: $lineargradient;
        padding: 0;
        width: 88px;
        height: 88px;
        margin: 0 auto;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  li.unselected {
    figure {
      border: 2px solid transparent;
    }
  }

  li.selected {
    figure {
      border: 2px solid #b23a5b;
    }
  }
}

// conditions
.conditions-wrap {
  .container-wrap {
    @media screen and (max-width: 767px) {
      position: relative;
    }
  }

  .bookmark-wrap {
    @include flex-end;
    margin-bottom: 10px;

    @media screen and (max-width: 767px) {
      position: absolute;
      top: 0;
      right: 30px;
    }
  }

  .form-container {
    display: grid;
    gap: 15px;

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
      gap: 0;
    }

    .custom-input {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      @media (max-width:480px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 5px 0 !important;
      }
    }

    .toggleSwitch {
      .trigger {
        background: #ffffff;
        box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
        width: 32px;
        height: 17px;

        &:before {
          height: 17px;
          width: 17px;
          left: 1px;
          bottom: 0px;
          background: #434343;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        }
      }
    }

    .toggleSwitch input:checked+.trigger:before {
      background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
      transform: translateX(15px);
    }
  }

  .wheels-wrap-block {
    margin-top: 30px;

    .title-block {
      margin-bottom: 50px;

      @media (max-width:991px) {
        margin-bottom: 30px;
      }
    }

    .form-container {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-bottom: 5px;
    }
  }

  .title-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    h5 {
      font-size: 18px;
      font-weight: 700;
      color: #282828;
      text-align: left;
    }
  }

  .choose-file {
    label {
      display: none;
    }

    .click-here {
      label {
        display: block;
        font-weight: 400;
      }
    }
  }

  .custom-input {
    textarea {
      height: 100%;
      min-height: 121px;

      @media screen and (max-width: 767px) {
        min-height: 140px;
        margin-bottom: 20px;
      }
    }
  }

  .custom-btn {
    margin: 0;
    min-width: 178px;
    height: 42px;
  }

  .button-wrap {
    @include flex-end;
    margin-top: 25px;

    @media screen and (max-width: 767px) {
      justify-content: flex-end;
    }
  }

  .custom-file-input {
    padding: 44px 30px;
  }

  .click-here svg {
    width: 40px;

    @media screen and (max-width: 991px) {
      width: 30px;
    }

    @media screen and (max-width: 767px) {
      width: 22px;
    }
  }

  .uploadImages-list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 10px;
    }
  }

  .informations {
    margin-bottom: 25px;
  }

  .additional-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding-left: 0;

    @media screen and (max-width: 991px) {
      column-gap: 15px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
      gap: 0;
      margin-bottom: 10px;
    }

    label {
      font-weight: 700;
    }
  }
}

.uploadImages-list {
  li {
    width: 100%;

    .upload-img {
      height: 86px;
      border-radius: 5px;
      background-color: rgb(228 228 228 / 30%);
      @include flex-center;
      position: relative;
      overflow: hidden;

      @media screen and (max-width: 767px) {
        height: 60px;
        border-radius: 15px;
      }

      img {
        @include full-space;
        @include object-fit;
      }

      &.no-img::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../assets/images/no-img.svg) no-repeat center;
        background-size: 20px;

        @media screen and (max-width: 767px) {
          background-size: 14px;
        }
      }

      .close-icon {
        position: absolute;
        top: 7px;
        right: 7px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: $whitebg;
        @include flex-center;
        cursor: pointer;

        @media screen and (max-width: 767px) {
          background: $lineargradient;
        }

        svg path {
          stroke: $blackText;

          @media screen and (max-width: 767px) {
            stroke: $whiteText;
          }
        }
      }
    }
  }
}

// wheels and tyres
.wheels-wrap {
  .container-wrap {
    @media screen and (max-width: 767px) {
      position: relative;
    }
  }

  .white-card {
    @media screen and (max-width: 767px) {
      background-color: transparent;
      box-shadow: none;
      padding: 0;
    }
  }

  .bookmark-wrap {
    @include flex-end;
    margin-bottom: 10px;

    @media screen and (max-width: 767px) {
      position: absolute;
      top: 0;
      right: 30px;
    }
  }

  .form-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 15px;

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
    }
  }

  textarea {
    min-height: 121px;

    @media screen and (max-width: 767px) {
      min-height: 140px;
    }
  }

  .wheels-tyres {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
      gap: 0;
    }

    h4 {
      font-size: 14px;
      font-weight: $boldText;
      color: $blackText;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
  }

  .wheels {
    @media screen and (max-width: 767px) {
      margin-top: 15px;
    }
  }

  .tyres {
    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }

  .uploadImages-list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 25px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
      margin-bottom: 10px;
    }
  }

  .choose-file {
    @media screen and (max-width: 767px) {
      margin-bottom: 15px;
    }
  }

  .custom-file-input {
    padding: 60px 30px;

    @media screen and (max-width: 991px) {
      padding: 44px 30px;
    }
  }

  .click-here svg {
    width: 40px;

    @media screen and (max-width: 991px) {
      width: 30px;
    }

    @media screen and (max-width: 767px) {
      width: 22px;
    }
  }

  .button-wrap {
    @include flex-end;
    margin-top: 25px;

    @media screen and (max-width: 767px) {
      justify-content: center;
      margin-top: 15px;
    }

    .custom-btn {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

// viewmatch
.viewmatch-wrap {
  min-height: 100vh;
  overflow: hidden;

  .carousel-withdetail {
    padding-bottom: 0px;
    margin-bottom: 0;
    border-bottom: 1px solid #e6e6e6;

    @media screen and (max-width: 767px) {
      height: 100%;
      @include flex-between;
      flex-direction: column;
    }

    .white-card {
      position: relative;
      z-index: 9;

      @media (max-width:992px) {
        padding: 15px;
        width: 100%;
      }
    }
  }

  .slick-prev,
  .slick-next {
    transform: translate(-12px, 0px);

    @media (max-width:767px) {
      position: absolute;
      inset: 0 !important;
      left: unset !important;
      bottom: unset !important;
      top: 330px !important;
    }
  }

  .slick-prev {
    @media (max-width:767px) {
      right: 20px !important;
    }
  }

  .slick-next {
    @media (max-width:767px) {
      right: 60px !important;
    }
  }

  .custom-carousel {
    .slick-slide {
      figure {
        .item {
          @media (max-width:768px) {
            height: 300px;
          }
        }
      }

    }
  }

  .full-slider {
    background: #ebebeb;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 30px;
    position: relative;

    @media (max-width:480px) {
      padding: 8px;
    }

    @media (max-width:768px) {
      .slick-prev {
        left: 40px;
      }

      .slick-next {
        left: 80px;
        right: unset;
      }
    }

    .slick-prev,
    .slick-next {
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 99;

      @media (max-width:768px) {
        width: 30px;
        height: 30px;
        bottom: 30px;
      }

      &::before {
        display: none;
      }

      img {
        width: 24px;
        height: 24px;

        @media (max-width:768px) {
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  .car-carousel {
    .user {
      padding: 5px;
      border-radius: 4px;
      width: 90%;
    }
  }

  .white-card {
    @media screen and (max-width: 769px) {
      padding: 10px;
      box-shadow: none;
    }
  }

  .button-wrap {
    margin-top: 15px;
    @include flex-center;

    @media screen and (max-width: 992px) {
      flex-direction: column;
      margin-top: 10px;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-top: 10px;
    }

    .custom-btn {
      margin: 0 7px;
      min-width: calc(50% - 7px);

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 992px) {
        min-width: 100%;
        margin: 5px 0;
      }

      @media screen and (max-width: 767px) {
        min-width: 100%;
        margin: 5px 0;
      }
    }
  }

  .info-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 15px;

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .info-card label {
    font-size: 13px;
  }
}

.details-list {
  li {
    border-bottom: 1px solid #cdcdcd;
    padding: 15px 0;

    @media screen and (max-width: 1199px) {
      padding: 10px 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: $boldText;
    color: $blackText;
    @include flex;

    @media screen and (max-width: 1199px) {
      font-size: 22px;
    }

    @media screen and (max-width: 992px) {
      flex-direction: column;
      align-items: self-start;
      gap: 10px;
    }

    @media screen and (max-width: 769px) {
      font-size: 20px;
    }

    span {
      font-size: 16px;
      margin-left: 7px;
      display: block;
      overflow: hidden;

      @media screen and (max-width: 1199px) {
        font-size: 14px;
      }

      @media screen and (max-width: 992px) {
        margin: 0;
      }
    }

    .description {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 400px;

      @media (max-width:420px) {
        max-width: 270px;
      }
    }
  }

  .car-type {
    @include flex-between;

    @media screen and (max-width: 992px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }

  .type {
    @include flex;

    h6 {
      font-size: 16px;
      font-weight: $regularText;
      color: #7e7e7e;
      margin-right: 30px;

      @media screen and (max-width: 1199px) {
        font-size: 14px;
      }
    }
  }

  

  h2 {
    font-size: 26px;
    font-weight: $boldText;
    color: $blackText;

    @media screen and (max-width: 1199px) {
      font-size: 24px;
    }

    @media screen and (max-width: 767px) {
      font-size: 22px;
    }
  }

  h5 {
    font-size: 16px;
    font-weight: $boldText;
    color: $blackText;
    margin-bottom: 10px;

    @media screen and (max-width: 1199px) {
      font-size: 14px;
    }

    @media screen and (max-width: 767px) {
      font-size: 13px;
    }
  }

  p {
    font-size: 16px;
    font-weight: $regularText;
    color: $blackText;

    @media screen and (max-width: 1199px) {
      font-size: 14px;
    }
  }
}

.carousel-withdetail {
  display: grid;
  grid-template-columns: calc(65% - 30px) 35%;
  gap: 30px;

  @media screen and (max-width: 1199px) {
    grid-template-columns: 59% 38%;
  }

  @media screen and (max-width: 991px) {
    grid-template-columns: 100%;
  }

  @media screen and (max-width: 767px) {
    background-color: transparent;
  }
}

.title-buttons {
  @include flex;

  button {
    margin-right: 10px;
    color: #000;

    &.w-auto {
      width: auto;
      padding: 2px 15px;
    }

    &.darkpink-btn {
      color: #fff;
    }

    .icon {
      margin-left: 9px;
    }
  }
}

.headtitle-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width:991px) {
    flex-wrap: wrap;
    gap: 10px;
  }

}

.full-slider {
  @media screen and (max-width: 1330px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 1280px) {
    padding: 0 30px;
  }

  @media screen and (max-width: 767px) {
    padding: 0;
  }

  .slick-next,
  .slick-prev {
    width: 37px;
    height: 37px;
    border-radius: 50%;

    img {
      width: 14px;
      height: 14px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      @include flex-center;
      opacity: 100%;
      margin: 0 auto;
    }

    &::before {
      content: " ";
      width: 14px;
      height: 14px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      @include flex-center;
      opacity: 100%;
      margin: 0 auto;
    }

    @media screen and (max-width: 767px) {
      display: none !important;
    }
  }

  .slick-prev {
    left: -59px;
    background-color: #CF2B2B;

    @media screen and (max-width: 1330px) {
      left: -20px;
    }
  }

  .slick-next {
    background-color: #2CBD55;
    right: -81px;

    @media screen and (max-width: 1330px) {
      right: -20px;
    }
  }
}

// viewcar details
.view-cardetails {
  min-height: 480px;

  .save-info {
    padding-top: 25px;

    @media screen and (max-width: 767px) {
      padding-top: 30px;
    }

    p {
      font-size: 14px;
      font-weight: $regularText;
      color: $darkgreyText;
      margin-bottom: 15px;
    }
  }

  .sm-head {
    font-size: 16px;
    font-weight: $boldText;
    color: #1d1d1d;

    @media screen and (max-width: 991px) {
      font-size: 14px;
    }
  }

  .flex-between {
    @include flex-between;
    margin-bottom: 25px;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  .headtitle-wrap {
    @media (max-width:769px) {
      .icon-txt {
        display: none;
      }

      .custom-btn {
        min-width: 110px;
        padding: 8px 15px;
      }

      .icon {
        margin: 0;
      }
    }

    @media (max-width:520px) {
      flex-direction: column;
      gap: 15px;
    }
  }
}

.view-carprofile {
  min-height: 450px;

  .button-wrap {
    margin-top: 15px;
    @include flex-center;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    .custom-btn {
      margin: 0 7px;
      min-width: calc(50% - 7px);

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 767px) {
        min-width: auto;
        margin: 10px 0;
        width: 100%;
      }
    }
  }

  .report-user {
    @include flex-between;
    position: relative;
    padding: 35px 0 15px;
    margin-bottom: 30px;

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: $lineargradient;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    h6 {
      font-size: 14px;
      font-weight: $boldText;
      color: $darkgreyText;
      @include flex;
      word-break: break-word;

      .icon {
        display: block;
        margin-right: 15px;
      }
    }
  }

  .listed-items {

    h4 {
      font-size: 20px;
      font-weight: $boldText;
      color: $darkgreyText;
      margin-bottom: 10px;

      @media screen and (max-width: 991px) {
        font-size: 18px;
      }

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    .slick-track {
      display: flex;
      align-items: stretch;
      height: 100%;
      padding-bottom: 10px;

      .slick-slide {
        height: unset;

        &>div,
        .item.item1 {
          height: 100%;
        }

        .listingCar-card {
          height: 100%;

          .car-name-price {
            flex: 1 1 100%;

            h3 {
              span {
                width: 100%;
              }
            }
          }

          .cusInfo-icons {
            width: 85px;
          }
        }
      }
    }
  }

  .userprofile-chat {
    @include flex-between;
    border-radius: 5px;
    padding: 15px;
    background-color: $whitebg;
    margin-bottom: 30px;
    box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.13);

    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 15px 10px;
      border-radius: 20px;
    }

    .user {
      @include flex;
      word-break: break-word;

      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 10px;
        justify-content: space-between;
      }
    }

    .user-name {
      @include flex;
      font-size: 14px;
      font-weight: $boldText;
      color: #f27b79;
      margin-right: 20px;
      word-break: break-word;

      @media screen and (max-width: 767px) {
        margin-right: 0;
      }
    }

    .img {
      display: block;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-right: 15px;
      background-color: #f1f1f1;
      padding: 5px;
      flex: 1 1 35px;

      @media screen and (max-width: 767px) {
        margin-right: 10px;
      }

      img {
        @include object-fit;
        border-radius: 100%;
      }

      p {
        flex: 1 1 100%;
      }
    }
  }

  .white-card {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }


}

.carlisting-slider {
  @media screen and (max-width: 1330px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 1280px) {
    padding: 0 30px;
  }

  @media screen and (max-width: 767px) {
    padding: 0;
  }

  .listingCar-card {
    width: calc(100% - 20px);

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    h3 {
      @media screen and (max-width: 767px) {
        margin: 10px 0 10px;
      }
    }

    .confirm-modal {
      .modal-body {
        h3 {
          span {
            margin: auto;
            margin-top: 6px;
          }
        }
      }
    }
  }

  .slick-next,
  .slick-prev {
    z-index: 1;
    width: 37px;
    height: 37px;
    background-color: #404040;
    border-radius: 50%;

    &::before {
      content: " ";
      width: 10px;
      height: 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      @include flex-center;
      opacity: 100%;
      margin: 0 auto;
    }

    @media screen and (max-width: 767px) {
      display: none !important;
    }
  }

  .slick-prev {
    left: -59px;

    @media screen and (max-width: 1330px) {
      left: -20px;
    }

    &::before {
      background-image: url(../assets/images/pre-icon.svg);
    }
  }

  .slick-next {
    right: -59px;

    @media screen and (max-width: 1330px) {
      right: -20px;
    }

    &::before {
      background-image: url(../assets/images/next-icon.svg);
    }
  }
}

// profile details
.profileDetails-wrap {
  min-height: 480px;

  @media screen and (max-width: 767px) {
    padding-bottom: 30px;
    overflow: hidden;
  }

  .quick-sale {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .card-content {
      height: auto;

      @media (max-width:767px) {
        background: #ffffff !important;
        border-radius: 5px !important;

        .content {
          width: 100% !important;
        }
      }

      .head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px !important;
        align-items: center;

        @media screen and (max-width: 767px) {
          display: flex !important;
        }

        .icon {
          @media screen and (max-width: 767px) {
            margin: 0px 10px 10px 0px;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      bottom: 0;
      left: 0;
      z-index: 9;
    }
  }

  .dropdown-navmenu .dropdown-content {
    padding: 5px 15px;
    right: -7px;

    @media (max-width:336px) {
      right: unset;
    }

    button {
      width: 100%;
      justify-content: flex-start;
      padding: 10px 0;
      margin: 0;

      @media (max-width:1280px) {
        font-size: 14px;

        .icon {
          width: 16px;
          margin-right: 10px;
        }
      }
    }
  }
}

.detail-card-wrap {
  display: grid;
  grid-template-columns: 65% 35%;
  margin-bottom: 40px;

  @media screen and (max-width: 991px) {
    grid-template-columns: 100%;
    margin-bottom: 0;
  }

  .subhead {
    font-size: 14px;
    font-weight: $boldText;
    color: $darkgreyText;
    margin-bottom: 20px;
    padding: 0 25px;
  }

  .detail-card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: -6px;
    margin-right: -6px;
    padding-right: 20px;

    @media screen and (max-width: 991px) {
      padding-right: 0;
      margin-bottom: 0;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
    }

    li {
      padding-left: 6px;
      padding-right: 6px;

      @media screen and (max-width: 767px) {
        padding: 0;
      }

      .card-content {
        @include full-space;
        background: $whitebg;
        border-radius: 5px;
        padding: 24px;
        box-shadow: 0 4px 20px 0px rgb(0 0 0 / 9%);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        @media screen and (max-width: 1199px) {
          padding: 20px;
        }

        @media screen and (max-width: 767px) {
          background: transparent;
          border-radius: 20px;
          gap: 10px;
        }

        .head {
          margin-bottom: 0;
        }
      }

      .icon {
        margin-bottom: 12px;
        display: block;
        min-height: 28px;

        @media screen and (max-width: 767px) {
          min-height: auto;
        }
      }

      .title-blocks {
        margin-left: 10px;
        margin-top: 5px;
      }

      h6 {
        font-size: 16px;
        font-weight: $boldText;
        color: $blackText;
        margin-bottom: 10px;

        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }

      p {
        font-size: 14px;
        font-weight: $regularText;
        color: $lightgreyText;
        margin-bottom: 20px;

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }

      .custom-btn {
        min-width: 125px;
      }

      &:first-child {
        .card-content {
          background: $orangGradient;
        }

        h6,
        p {
          color: $whiteText;
        }
      }

      &.premium {
        @media screen and (max-width: 767px) {
          margin-bottom: 25px;
        }

        .head {
          .icon {
            @media screen and (max-width: 767px) {
              margin: 0 10px 0 0;
            }
          }
        }

        .custom-btn {
          @media screen and (max-width: 767px) {
            background-color: $blackbg;
            color: $whiteText;
          }
        }
      }

      &.edit-value {
        @media screen and (max-width: 767px) {
          margin-bottom: 30px;
        }

        .mb-block {
          .custom-btn {
            display: none !important;
          }
        }

        .card-content {
          .content {
            @media screen and (max-width: 767px) {
              margin-right: 15px;
            }
          }

          .icon {
            @media screen and (max-width: 767px) {
              display: none;
            }
          }

          h6 {
            @media screen and (max-width: 767px) {
              margin-bottom: 5px;
            }
          }

          p {
            @media screen and (max-width: 767px) {
              margin-bottom: 0;
            }
          }

          .custom-btn {
            svg {
              @media screen and (max-width: 767px) {
                width: 10px;
              }

              @media screen and (max-width: 480px) {
                width: auto !important;
              }

              path {
                @media screen and (max-width: 767px) {
                  stroke: $whitebg;
                  fill: $whitebg;
                }
              }
            }
          }
        }
      }

      &.quick-sale {
        @media screen and (max-width: 767px) {
          background-color: #f6f6f6;
          width: 100%;
          padding: 0;
          border-top: 5px solid #eaeaea;
        }

        .card-content {
          @media screen and (max-width: 767px) {
            flex-direction: row;
            padding: 20px 20px 30px;
            box-shadow: none;
          }

          .content {
            @media screen and (max-width: 767px) {
              padding-right: 15px;
            }
          }

          .head {
            @media screen and (max-width: 767px) {
              display: none;
            }
          }

          p {
            @media screen and (max-width: 767px) {
              margin-bottom: 0;
            }
          }

          .custom-btn {
            @media screen and (max-width: 767px) {
              background-color: $blackbg;
              color: $whiteText;
            }
          }
        }
      }
    }
  }
}

.performance-meter {
  background-color: $whitebg;
  border-radius: 5px;
  padding: 15px;
  margin-top: 15px;

  h6 {
    font-size: 16px;
    font-weight: $boldText;
    color: $blackText;

    @media screen and (max-width: 767px) {
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }

  .speedometer-wrap {
    @include flex-center;
    margin-top: 15px;

    @media screen and (max-width: 767px) {
      flex-direction: column !important;
    }

    .speedometer-red,
    .speedometer-green {
      position: relative;
      padding: 10px;

      @media screen and (max-width: 767px) {
        padding: 0 10px;
      }
    }

    .speedometer-red {
      .CircularProgressbar-path {
        stroke: #ef1010;
      }
    }

    .speedometer-green {
      .CircularProgressbar-path {
        stroke: #26b961;
      }
    }

    .speedometer {
      .meter {
        padding: 0 20px;

        @media screen and (max-width: 1199px) {
          padding: 0 10px;
        }

        @media screen and (max-width: 991px) {
          padding: 0 80px;
        }

        @media screen and (max-width: 767px) {
          padding: 0;
          max-width: 200px;
        }
      }

      .CircularProgressbar-text {
        font-size: 30px;
        font-weight: $boldText;
        fill: $darkgreyText;
      }

      .content {
        text-align: center;

        h3 {
          padding: 2px 12px;
          background: $blackbg;
          border-radius: 5px;
          font-size: 10px;
          font-weight: $boldText;
          color: $whiteText;
          display: inline-block;
          margin-bottom: 10px;
          font-family: "Magistral", sans-serif;

          span {
            font-size: 18px;
            margin-right: 2px;
          }
        }

        p {
          font-size: 12px;
          font-weight: $regularText;
          color: $lightgreyText;
          text-align: center;
          margin-bottom: 0;

          @media (max-width:768px) {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

.dvder {
  width: 100%;
  height: 1px;
  background: $lineargradient;
  display: block;
}

// dropdown
.three-dots {
  @include flex;
  flex-direction: column;
  border: none;

  span {
    background-color: $lightgreyText;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-bottom: 3px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;

  .dropdown-content {
    display: block;
    position: absolute;
    z-index: 1;
    right: 0;

    .dropdown-list {
      background-color: $whitebg;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      border: 1px solid #e4e4e4;
      border-radius: 5px;
      position: relative;

      li {
        @include flex;
        padding: 12px;
        font-size: 12px;
        font-weight: $regularText;
        color: $blackText;
        cursor: pointer;

        span {
          margin-left: 15px;
        }

        svg {
          min-width: 20px;
        }

        &:hover {
          color: #f27b79;
        }

        &:last-child {
          border-bottom: none;
        }

        &.mute-chat {
          svg path {
            @media screen and (max-width: 767px) {
              stroke: $whitebg;
            }
          }
        }

        &.delete-chat {
          svg path {
            @media screen and (max-width: 767px) {
              fill: $whitebg;
            }
          }
        }

        &.erase-chat {
          svg path {
            @media screen and (max-width: 767px) {
              fill: $whitebg;
            }
          }
        }

        &.block-user {
          svg path {
            @media screen and (max-width: 767px) {
              stroke: $whitebg;
            }
          }
        }
      }

      &::before {
        content: "";
        width: 25px;
        height: 25px;
        position: absolute;
        right: 5px;
        top: -17px;
        z-index: -1;
        background-image: url(../assets/images/drop-arrow.svg);
      }
    }
  }
}

.outline-icon-btn {
  .dropdown-btn {
    margin-right: 0;

    .dropdown-navmenu {
      @include full-space;
      @include flex-center;
    }
  }

  .dropdown-content {
    min-width: 210px;

    a {
      @include flex;
    }

    .icon {
      width: 20px;
      display: block;
      margin-right: 15px;

      svg {
        width: 100%;
      }
    }
  }
}

// report an issue
.report-issue {
  min-height: 100vh;

  .top-bar {
    background-color: $whitebg;
    padding: 15px 35px 15px 15px;
    margin-bottom: 25px;
    @include flex-between;
    border-radius: 5px;

    @media screen and (max-width: 767px) {
      border-radius: 25px;
      padding: 10px;
    }

    .user {
      @include flex;

      .defaultimage {
        padding: 8px;
      }
    }

    .image {
      width: 58px;
      height: 47px;
      border-radius: 2px;
      overflow: hidden;

      img {
        @include full-space;
        object-fit: cover;
      }
    }

    .name {
      margin-left: 15px;
    }

    h2 {
      font-size: 16px;
      font-weight: $boldText;
      color: $darkgreyText;
      margin-bottom: 6px;
      text-transform: uppercase;

      @media screen and (max-width: 767px) {
        font-size: 13px;
        margin-bottom: 3px;
      }
    }

    p {
      font-size: 12px;
      font-weight: $boldText;
      color: #f27b79;

      @media screen and (max-width: 767px) {
        font-size: 11px;
      }
    }
  }

  .info {
    @include flex;
    margin-bottom: 30px;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }

    .icon {
      display: flex;
      width: 21px;
      height: 21px;
      margin-right: 15px;

      svg {
        @include full-space;
      }
    }

    p {
      font-size: 14px;
      font-weight: $boldText;
      color: $darkgreyText;

      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
  }

  .button-wrap {
    @include flex;
    margin-top: 25px;

    .custom-btn {
      margin: 0 10px 0 0;

      @media screen and (max-width: 767px) {
        margin: 0 10px;
        min-width: calc(50% - 10px);
      }

      &:first-child {
        @media screen and (max-width: 767px) {
          margin-left: 0;
        }
      }

      &:last-child {
        @media screen and (max-width: 767px) {
          margin-right: 0;
        }
      }
    }
  }

  textarea {
    min-height: 220px;
  }
}

// questionnaire
.questionnaire-wrap {

  .container-wrap {
    @media screen and (max-width: 767px) {
      height: 100%;
    }
  }

  .content-section {
    @media screen and (max-width: 767px) {
      height: calc(100% - 20px);
    }
  }

  .white-card {
    padding: 55px;

    @media screen and (max-width: 991px) {
      padding: 55px 45px;
    }

    @media screen and (max-width: 767px) {
      padding: 20px 0 0;
      background-color: transparent;
      box-shadow: none;
      height: 100%;
      @include flex-between;
      flex-direction: column;
    }
  }
}

.questionnaire {
  @include flex-between;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .image {
    @include flex;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      height: 100%;
      justify-content: center;
      margin-bottom: 70px;
    }
  }

  figure {
    margin: 0 45px 0 0;

    @media screen and (max-width: 991px) {
      margin: 0 30px 0 0;
    }

    @media screen and (max-width: 767px) {
      margin: 0 0 30px 0;
    }

    img {
      @media screen and (max-width: 991px) {
        width: 180px;
      }

      @media screen and (max-width: 767px) {
        width: 200px;
      }
    }
  }

  h3 {
    font-size: 21px;
    font-size: $regularText;
    color: $lightgreyText;

    @media screen and (max-width: 1199px) {
      font-size: 19px;
    }

    @media screen and (max-width: 991px) {
      font-size: 17px;
    }

    @media screen and (max-width: 767px) {
      text-align: center;
      margin-bottom: 20px;
    }

    span {
      font-size: 36px;
      font-weight: $boldText;
      display: block;
      color: $darkgreyText;

      @media screen and (max-width: 1199px) {
        font-size: 34px;
      }

      @media screen and (max-width: 991px) {
        font-size: 32px;
      }
    }
  }

  .action {
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .custom-btn {
    min-width: 300px;
    margin-bottom: 13px;

    @media screen and (max-width: 1199px) {
      min-width: 240px;
    }

    @media screen and (max-width: 991px) {
      min-width: 180px;
    }

    @media screen and (max-width: 767px) {
      min-width: 100%;
    }
  }

  .skip-text {
    font-size: 14px;
    font-weight: $regularText;
    color: #646464;
    display: block;
    text-align: right;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      display: block;
      text-align: center;
      margin: auto;
    }
  }
}

.survey-questions {
  display: flex;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }

  h6 {
    font-size: 18px;
    font-weight: $regularText;
    color: #3f3244;
    margin-bottom: 15px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      background: $greenGradient;
      border-radius: 25px;
      padding: 12px 25px;
      color: $whiteText;
      text-align: center;
      display: inline-block;
      margin: 0 auto 40px;
      box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.37);
    }

    span {
      color: #ff8f66;

      @media screen and (max-width: 767px) {
        color: $whiteText;
      }
    }
  }

  .surveybar-conatiner {
    margin-bottom: 30px;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  h4 {
    font-size: 18px;
    font-weight: $boldText;
    color: $blackText;
    margin-bottom: 10px;
    text-transform: uppercase;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      text-align: left;
    }
  }

  p {
    font-size: 16px;
    font-weight: $darkgreyText;
    color: $darkgreyText;
    margin-bottom: 15px;

    @media screen and (max-width: 767px) {
      font-size: 14px;
      text-align: left;
    }
  }

  .custom-input label {
    text-align: left;
    display: block;
  }

  .radio {
    width: 100%;
    margin-bottom: 15px;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }

    @include flex;

    .custom-radiobtn {
      margin-right: 45px;
    }
  }

  .survey-circlebar {
    width: 200px;
    height: 200px;
    margin: 0 40px 0 0;

    @media screen and (max-width: 991px) {
      margin: 0 30px 0 0;
    }

    @media screen and (max-width: 767px) {
      margin: 0 auto 30px;
      width: 130px;
      height: 130px;
    }
  }

  .surevey-progressbar {
    width: 100%;

    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  .CircularProgressbar-text {
    fill: #353333 !important;
    stroke: #353333 !important;
    font-size: 40px;
    font-family: "Magistral", sans-serif;
    font-weight: $boldText;
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke: #bf3a60;
  }

  .custom-input {
    margin-bottom: 20px;

    textarea {
      min-height: 105px;

      @media screen and (max-width: 767px) {
        min-height: 150px;
      }
    }
  }
}

.survey-wrap {
  position: relative;
  min-height: 100vh;

  &::after {
    @media screen and (max-width: 767px) {
      content: "";
      position: absolute;
      top: 372px;
      left: 0;
      width: 100%;
      height: calc(100% - 372px);
      background-color: #f6f6f6;
      z-index: -1;
    }
  }

  .white-card {
    @media screen and (max-width: 767px) {
      padding: 20px;

      height: 100%;
      @include flex-between;
      flex-direction: column;
    }
  }

  .button-wrap {
    @include flex;

    @media screen and (max-width: 767px) {
      justify-content: space-between;
      width: 100%;
    }

    .custom-btn {
      margin: 0 22px 0 0;
      min-width: 147px;

      @media screen and (max-width: 767px) {
        min-width: calc(50% - 10px);
        margin: 0 0 20px 0;
      }
    }
  }

  .content-section {
    @media screen and (max-width: 767px) {
      height: calc(100% - 20px);
    }
  }
}

// FAQ
.faq-wrap {

  .container-wrap {
    @media screen and (max-width: 767px) {
      height: 100%;
    }
  }

  .content-section {
    @media screen and (max-width: 767px) {
      height: calc(100% - 24px);
      @include flex-between;
      flex-direction: column;
    }
  }

  .custom-accordion {
    width: 100%;
    margin-bottom: 35px;
  }

  .szh-accordion__item {
    background-color: $whitebg;
    padding: 17px 20px !important;
    margin-bottom: 12px;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.03);
    transition: 0.3s background-color;

    @media screen and (max-width: 767px) {
      border-radius: 25px;
      background-color: #e4e4e4;
    }

    &::after {
      display: none;
    }

    &.szh-accordion__item--expanded {
      @media screen and (max-width: 767px) {
        background-color: $whitebg;
      }

      .szh-accordion__item-btn {
        @media screen and (max-width: 767px) {
          color: $darkgreyText !important;
        }
      }
    }
  }

  .szh-accordion__item-btn {
    text-transform: initial !important;
    font-size: 16px !important;
    font-weight: $regularText !important;
    color: $blackText !important;

    @media screen and (max-width: 767px) {
      font-size: 15px !important;
      color: $lightgreyText !important;
    }
  }

  .szh-accordion__item-content .szh-accordion__item-panel {
    padding-top: 10px;
    font-size: 14px !important;
    font-weight: $regularText !important;
    color: $lightgreyText !important;
    line-height: 22px;

    @media screen and (max-width: 767px) {
      font-size: 13px !important;
    }
  }

  .custom-btn {
    min-width: 125px;

    @media screen and (max-width: 767px) {
      min-width: 100%;
    }

    span {
      margin-right: 15px;
      display: flex;
      align-items: center;
    }
  }
}

// contact us
.contact-wrap {
  min-height: 100vh;

  .cmn-text {
    font-size: 16px;
    font-weight: $regularText;
    color: #7b7b7b;
    margin-bottom: 60px;

    @media screen and (max-width: 991px) {
      font-size: 14px;
      margin-bottom: 50px;
    }

    @media screen and (max-width: 767px) {
      font-size: 12px;
      margin-bottom: 40px;
    }
  }

  .white-card {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
      padding: 15px;
      box-shadow: none;
    }
  }

  .email-fig {
    @include flex-center;
    flex-direction: column;

    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  .email-fig img {
    @media screen and (max-width: 991px) {
      width: 180px;
    }
  }

  .info {
    font-size: 18px;
    font-weight: $regularText;
    color: $darkgreyText;
    @include flex;
    gap: 10px;
    margin-bottom: 70px;


    @media screen and (max-width: 991px) {
      margin-bottom: 50px;
      font-size: 16px;
    }

    @media screen and (max-width: 767px) {
      font-size: 14px;
      margin-bottom: 30px;
    }

    span {
      @include flex;
      margin-right: 15px;

      @media screen and (max-width: 767px) {
        margin-right: 10px;
      }
    }

    svg {
      width: 22px;
      height: 22px;

      @media screen and (max-width: 991px) {
        width: 20px;
        height: 20px;
      }

      @media screen and (max-width: 767px) {
        width: 18px;
        height: 18px;
      }
    }
  }

  .button-wrap {
    @include flex;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    .custom-btn {
      margin: 0 20px 0 0;
      min-width: calc(50% - 10px);

      @media screen and (max-width: 767px) {
        margin: 0 0 20px 0;
        min-width: 100%;
      }
    }
  }
}

// stock
.stock-wrap {
  .title-wrp {
    padding: 0;

    @media screen and (max-width: 769px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .topbar-wrap {
    @media screen and (max-width: 769px) {
      padding: 10px;
      background: #3f3244;
    }

    .action {
      @media screen and (max-width: 769px) {
        width: 100% !important;
        gap: 15px !important;
        flex-direction: row !important;
      }

      @media screen and (max-width: 480px) {
        flex-direction: column;
      }
    }

    .outline-icon-btn {
      margin: 15px 0;

      @media screen and (max-width: 767px) {
        margin: 0;
      }
    }
  }

  .button-wrap {
    @include flex;

    @media screen and (max-width: 767px) {
      margin-top: 15px;
      width: 100%;
      justify-content: flex-end;
    }

    .custom-btn {
      margin-left: 12px;
      min-width: 130px;

      @media screen and (max-width: 991px) {
        min-width: 130px;
      }

      @media screen and (max-width: 767px) {
        min-width: 110px;
        font-size: 12px;
      }
    }
  }

  .stock-details {
    .sm-text {
      font-size: 14px;
      font-weight: $regularText;
      color: $blackText;
      margin-bottom: 25px;

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
        font-size: 12px;
      }
    }
  }



  .description-block {
    ul {
      @media (max-width:769px) {
        flex-direction: column;

        li {
          white-space: nowrap;
          border: none;
        }
      }
    }
  }
}

.topbar-wrap {
  background-color: #3f3244;
  padding: 7px 30px;
  border-radius: 5px;
  margin-bottom: 30px;
  @include flex-between;

  @media screen and (max-width: 991px) {
    padding: 7px 25px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    padding: 0;
    background-color: $whitebg;
    margin-bottom: 20px;
  }

  .menu {
    @media screen and (max-width: 767px) {
      width: 100%;
      background-color: #3f3244;
      padding: 10px 20px;
      border-radius: 5px;
    }

    a {
      color: #baa3c3;
      font-size: 16px;
      font-weight: $regularText;
      margin: 0 17px;

      @media screen and (max-width: 991px) {
        font-size: 14px;
      }

      &:first-child {
        margin-left: 0;
      }

      &.active {
        color: $whiteText;
      }
    }
  }

  .search-box {
    width: 295px;
    margin-right: 25px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 0;
    }
  }

  .search-box input {
    min-height: 44px;
    border: none;
    background: #534458 url(../assets/images/search.svg) no-repeat 17px center;
    padding: 5px 20px 5px 40px;
    color: $whiteText;
    font-weight: $regularText;
    border-radius: 10px;

    &::placeholder {
      color: #85768b;
    }
  }

  .action {
    @include flex;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      width: 100%;
    }
  }

  .dropdown-navmenu {
    @media screen and (max-width: 767px) {
      background-color: #534458;
      width: 100%;
      border-radius: 5px;
      padding: 11px 20px;
      margin: 10px 0;
    }
  }

  .dropdown-btn {
    font-size: 16px;
    font-weight: $regularText;
    color: $whiteText;

    @media screen and (max-width: 991px) {
      font-size: 14px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      @include flex-between;
    }

    svg {
      margin-left: 30px;
    }

    path {
      stroke: $whiteText;
    }
  }
}

.quickEdit-card {
  border-radius: 5px;
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.03);
  padding: 20px;
  background: #ffffff;
  @include flex-between;
  margin-bottom: 15px;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }

  @media screen and (max-width: 767px) {
    padding: 15px;
  }

  .content {
    @include flex;

    @media screen and (max-width: 991px) {
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .detail-card {
    padding-left: 25px;

    @media screen and (max-width: 767px) {
      padding-left: 0;
      width: 100%;
    }
  }

  .body {
    border-bottom: 1px solid #eeeeee;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 20px 0;
    gap: 25px;

    @media screen and (max-width: 1199px) {
      gap: 20px;
    }

    @media screen and (max-width: 991px) {
      padding: 15px 0;
      gap: 15px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }

    p {
      font-size: 14px;
      font-weight: $boldText;
      color: #111111;
    }
  }

  .foot {
    @include flex;
    padding-top: 20px;

    @media screen and (max-width: 991px) {
      padding-top: 15px;
    }

    .custom-input {
      margin-right: 15px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      input {
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .head {
    border-bottom: 1px solid #eeeeee;
  }

  h4 {
    font-size: 18px;
    font-weight: $regularText;
    color: $blackText;
    margin-bottom: 13px;
    position: relative;
    display: inline-block;
    word-break: break-all;
    margin-right: 15px;
    text-decoration: underline;
    text-decoration-color: $blackbg;

    @media screen and (max-width: 991px) {
      font-size: 16px;
    }

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  .sub {
    margin-bottom: 13px;
    @include flex;

    @media screen and (max-width: 991px) {
      margin-bottom: 10px;
    }

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  .sub span {
    font-size: 14px;
    font-weight: $regularText;
    color: #555555;
    display: block;
    padding: 0 10px;
    position: relative;

    @media screen and (max-width: 767px) {
      font-size: 12px;
      padding: 0 6px;
    }

    &::after {
      content: "";
      width: 1px;
      height: 11px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: #cccccc;
    }

    &:last-child {
      padding-right: 0;

      &::after {
        display: none;
      }
    }

    &:first-child {
      padding-left: 0;
    }
  }

  figure {
    height: 244px;
    width: 253px;
    border-radius: 2px;
    overflow: hidden;

    @media screen and (max-width: 1199px) {
      width: 200px;
    }

    @media screen and (max-width: 991px) {
      width: 207px;
      height: 230px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      height: 200px;
    }
  }

  figure img {
    @include object-fit;
  }

  .add-btn {
    position: absolute;
    bottom: 17px;
    right: 0;
    left: 0;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    border-radius: 10px;
    background-color: #303030;
    border: 1px solid $whitebg;
    max-width: 166px;
    min-height: 45px;
    transition: 0.3s background-color;

    @media screen and (max-width: 1199px) {
      max-width: 150px;
    }

    @media screen and (max-width: 991px) {
      max-width: 130px;
      min-height: 36px;
      font-size: 13px;
    }

    &:hover {
      background-color: $blackbg;
    }
  }

  .image {
    height: 100%;
    position: relative;

    @media screen and (max-width: 767px) {
      margin-bottom: 15px;
      width: 100%;
    }
  }

  label {
    color: #878787;
    display: block;
  }

  .custom-input input {
    min-height: 37px;
    width: 122px;
    color: #111111;
    font-weight: $regularText;

    &:read-only::placeholder {
      color: #111111;
      font-weight: $regularText;
    }
  }

  .action {
    min-width: 240px;

    @media screen and (max-width: 1199px) {
      min-width: 180px;
    }

    @media screen and (max-width: 991px) {
      @include flex;
      justify-content: flex-end;
      margin-top: 30px;
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    .custom-input {
      width: 100%;
      margin: 0 0 15px 0;

      @media screen and (max-width: 991px) {
        margin: 0 15px 0 0;
        @include flex;
        width: auto;
      }

      @media screen and (max-width: 767px) {
        margin: 0 0 15px 0;
        width: 100%;
      }

      .select-edit {
        border-color: #e4e4e4;
        box-shadow: none;

        @media screen and (max-width: 991px) {
          width: 180px;
        }

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }

      label {
        @media screen and (max-width: 991px) {
          margin: 0 15px 0 0;
        }
      }
    }

    .pink-btn {
      margin: 0 0 15px 0;

      @media screen and (max-width: 991px) {
        margin: 0 15px 0 0;
      }

      @media screen and (max-width: 767px) {
        margin: 0 0 15px 0;
      }
    }

    .custom-btn {
      width: 100%;

      @media screen and (max-width: 991px) {
        width: auto;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

// team
.team-wrap {
  min-height: 100vh;

  .title-wrp {
    padding: 0;
    margin: 0;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .custom-btn {
      background-color: #303030;

      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 20px;
      }
    }

    .custom-table {
      tbody {
        td {
          p {
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.custom-table {
  overflow-x: auto;

  table {
    width: 100%;
  }

  thead {
    th {
      h6 {
        padding: 7px 15px;
        font-size: 14px;
        font-weight: $regularText;
        color: $blackText;
        background-color: #eaeaea;
        margin-bottom: 20px;
        min-height: 31px;
        height: 100%;
        min-width: 120px;
        text-align: center;
      }

      &:first-child h6 {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child h6 {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        min-width: auto;
      }

      &:nth-last-child(2) h6 {
        min-width: auto;
      }
    }
  }

  tbody {
    td {
      p {
        border-top: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
        padding: 20px 15px;
        margin-bottom: 10px;
        text-align: center;
        font-size: 14px;
        font-weight: $regularText;
        color: $blackText;
        min-height: 69px;
        white-space: nowrap;
      }

      &:first-child {
        p {
          border-left: 1px solid #e3e3e3;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
      }

      &:last-child {
        p {
          border-right: 1px solid #e3e3e3;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
}

.addteam-wrap {
  .input-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 45px;
    row-gap: 20px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
      column-gap: 0;
    }

    .custom-input {
      margin-bottom: 0;
    }
  }

  .button-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    @media screen and (max-width: 767px) {
      justify-content: space-between;
      margin-top: 30px;
    }

    .custom-btn {
      margin-left: 15px;

      @media screen and (max-width: 767px) {
        min-width: calc(50% - 7px);
        margin-left: 0;
      }
    }
  }
}

.mysubscription-warp {
  min-height: 100vh;

  .custom-tab {
    ul {
      @media (max-width:768px) {
        margin-bottom: 15px;
        height: 39px;
      }
    }
  }

  .title-other {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 35px;
  }

  .subscription-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  .currentplan-card {
    background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
    padding: 20px 25px;
    border-radius: 5px;
    @include flex-between;
    margin-bottom: 35px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      text-align: center;
    }

    h6 {
      font-size: 14px;
      font-weight: $boldText;
      color: #ffe5e5;
      margin-bottom: 10px;
    }

    .level {
      background-color: $blackbg;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 700;
      color: #ffffff;
      padding: 2px 10px;
      text-transform: uppercase;
    }

    h2 {
      font-size: 42px;
      font-weight: $boldText;
      color: $whiteText;
      font-family: "Magistral", sans-serif;
      display: flex;
      align-items: baseline;

      @media screen and (max-width: 767px) {
        font-size: 34px;
      }

      span {
        font-size: 14px;
        font-weight: $regularText;
        color: #efd0d0;
        margin-left: 9px;
        display: block;
      }

      .unlimited-cars {
        font-size: 22px !important;
        font-weight: 700 !important;
        margin-left: 0;
      }
    }

    .details {
      @include flex-center;

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }

      h5 {
        font-size: 21px;
        font-weight: $boldText;
        color: $whiteText;
        margin: 0 20px;
        font-family: "Magistral", sans-serif;

        @media screen and (max-width: 767px) {
          margin: 6px 0;
          font-size: 17px;
        }

        span {
          font-size: 15px;
        }
      }

      p {
        font-size: 14px;
        font-weight: $boldText;
        color: #e1d6d6;
        margin-top: 4px;

        @media screen and (max-width: 767px) {
          margin-bottom: 4px;
        }
      }
    }
  }

  .premiumpost-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 45px;

    @media screen and (max-width: 991px) {
      gap: 30px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 30px;
    }

    .premiumpost-card {
      width: 100%;
    }
  }
}

.premiumpost-card {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding: 0px;
  width: calc(100% / 2 - 10px);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  height: 100%;

  &.payment-failed {
    &:after {
      content: "";
      display: block;
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      background: rgba(0, 0, 0, 0.7);
    }

  }

  &.disable-pointer-events {
    pointer-events: none;
  }

  .pink-btn-sold {
    background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
    min-height: 33px;
    min-width: 109px;
    position: absolute;
    z-index: 99;
    inset: 0;
    margin: auto;
    width: 110px;
    height: 33px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }

  figure {
    width: 100%;
    overflow: hidden;
    border-radius: 0;
    position: relative;
    height: 250px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .pink-btn {
      position: absolute;
      left: 25px;
      bottom: 25px;
      min-width: 109px;
      min-height: 33px;

      &.payment-failed {
        z-index: 9999;
        margin: auto;
        right: 0;
        width: 180px;
      }

      @media screen and (max-width: 991px) {
        left: 20px;
        bottom: 10px;
      }
    }

    .overlay-txt {
      position: absolute;
      left: 25px;
      bottom: 25px;
      min-width: 109px;
      min-height: 33px;

      z-index: 999;
      margin: auto;
      right: 0;

      &.failed {
        min-height: 128px;

        svg {
          margin-right: 5px;

          path {
            fill: white;
          }
        }
      }

      h4 {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 4px;
      }

      P {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 16px;
      }

      .payment-failed {
        width: 109px;
        border-radius: 5px;
        position: unset;
        inset: unset;
        margin: auto;

        svg {
          width: 22px;
          margin-right: 8px;

          path {
            fill: #fff;
          }
        }
      }

      @media screen and (max-width: 991px) {
        left: 20px;
        bottom: 20px;
      }
    }
  }

  h3 {
    font-weight: 400;
    font-size: 13px;
    color: $cardRed;
    margin-bottom: 20px;
    text-transform: uppercase;

    span {
      display: block;
      font-weight: 700;
      font-size: 15px;
      text-transform: uppercase;
      color: $blackText;
      margin-top: 10px;
      text-align: left;
      word-break: break-word;

      @media (max-width: 767px) {
        margin-bottom: 12px;
        font-size: 14px;
      }
    }

    @media (max-width: 767px) {
      margin: 0px 0 10px;
      font-size: 12px;
    }
  }

  h4 {
    font-weight: 700;
    font-size: 22px;
    color: #353333;
  }

  h5 {
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    background: $SecondaryAmberGradient;
    color: #ffffff;
    border-radius: 5px;
    padding: 8px;
    margin: 0;
    width: 100%;
  }

  .premium {
    border-top: 1px dashed #a1a1a1;
    padding-top: 20px;
    margin-top: 25px;

    @media screen and (max-width: 991px) {
      margin-top: 20px;
    }

    h4 {
      font-size: 14px;
      font-weight: $regularText;
      color: $blackText;
      margin-bottom: 12px;
      text-transform: uppercase;
      text-align: left;
    }

    p {
      font-size: 12px;
      font-weight: $regularText;
      color: #353333;
      margin-bottom: 10px;
      @include flex-between;

      span {
        font-weight: $boldText;
      }
    }
  }

  .premium-details {
    padding: 25px;
    position: relative;

    .more-wrp {
      text-align: left;
    }

    .history-btn-sold {
      width: 100%;
      position: relative;
      background: #282828;
      z-index: 999;
      pointer-events: auto;
    }

    @media screen and (max-width: 991px) {
      padding: 20px;
    }
  }
}

// old styles
.grid {
  display: grid;

  &.grid-lg-2 {
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;

    @media screen and (max-width: 767px) {
      column-gap: 4%;
      grid-template-columns: 48% 48%;
    }
  }

  &.grid-lg-3 {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
  }

  &.grid-md-1 {
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr !important;
      column-gap: 0 !important;
    }
  }

  &.grid-sm-1 {
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr !important;
      column-gap: 0 !important;
    }
  }
}

.content-box {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 15px;
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.03);

  @media screen and (max-width: 767px) {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .head-wrap {
    position: relative;
    padding: 30px 40px 30px;
    border-bottom: 1px solid #e8e8e8;
    @include flex-center;

    @media screen and (max-width: 767px) {
      padding: 0 25px 25px;
      border: none;
    }

    .bookmark-icon {
      position: absolute;
      right: 40px;
      border: none;
      padding: 0;
      top: 30px;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    h2 {
      font-size: 16px;
      font-weight: $regularText;
      color: $blackText;
      text-transform: uppercase;
      margin: 0;

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }

  .content-wrap {
    padding: 30px 40px 40px;

    @media screen and (max-width: 767px) {
      padding: 10px 0 0;
    }
  }
}

.content-wrap {
  .sub-head {
    font-size: 16px;
    font-weight: $regularText;
    color: $blackText;
    margin-bottom: 20px;
    text-transform: uppercase;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.custom-radio {
  display: block;
  position: relative;
  padding-left: 23px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 16px;
  color: $lightgreyText;
  font-weight: $regularText;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked~.checkmark {
      background: $lineargradient;
    }

    &:checked~.checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;

    &:after {
      content: "";
      position: absolute;
      left: 6px;
      top: 3px;
      width: 3px;
      height: 6px;
      border: solid $whitebg;
      border-width: 0 1px 1px 0;
      transform: rotate(45deg);
      display: none;
    }
  }
}

.radio-button {
  display: block;
  position: relative;
  padding-left: 23px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  color: $lightgreyText;
  font-weight: $regularText;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked~.checkmark {
      background: $whitebg;
    }

    &:checked~.checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1px solid #d9d9d9;

    &:after {
      content: "";
      position: absolute;
      left: 2px;
      top: 2px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $lineargradient;
      transform: rotate(45deg);
      display: none;
    }
  }

  &.dark-shade {
    .checkmark {
      border-color: $blackbg;

      &::after {
        background: $blackbg;
      }
    }
  }
}

.radio-buttonCheck {
  display: block;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  color: $lightgreyText;
  font-weight: $regularText;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 28px;

  @media screen and (max-width: 767px) {
    padding-left: 25px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked~.checkmark {
      background: $lineargradient;
    }

    &:checked~.checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    border-radius: 50%;
    background-color: #e4e4e4;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 8px;
      top: 5px;
      width: 3px;
      height: 6px;
      border: solid #ffffff;
      border-width: 0 1px 1px 0;
      transform: rotate(45deg);
    }
  }

  &.pink-shade {
    .checkmark {
      background-color: transparent;
      border: 1px solid #827343;
    }

    input:checked~.checkmark {
      background: $lineargradient;
      border: none;
    }
  }
}

//emp1248

.support-page-wrap {
  .support-block {
    margin: 21px 0 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0 25px;
    align-items: baseline;

    &.support-sub-block {
      display: flex;
      margin: 0;
      align-items: stretch;

      @media (max-width: 1024px) {
        flex-direction: column;
        gap: 30px 0;
      }

      .support-con-block {
        flex: 1 1 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 1024px) {
          flex: 1 1 100%;
        }
      }

      .support-img-block {
        flex: 1 1 70%;

        @media (max-width: 1024px) {
          flex: 1 1 100%;
        }

        .white-card {
          height: 100%;
        }

        .support-img-wrap {
          display: flex;
          align-items: center;
          gap: 0 30px;

          @media (max-width: 991px) {
            flex-direction: column;
          }

          .left-block {
            width: 50%;

            img {
              width: 360px;

              @media (max-width:640px) {
                width: 100%;
              }
            }
          }

          .right-block {
            width: 50%;

            @media (max-width:640px) {
              width: 100%;
            }

            h5 {
              color: #7C7C7C;
              text-align: center;
              font-size: 21px;
              font-weight: 400;
              line-height: 43px;

              @media (max-width: 991px) {
                font-size: 16px;
                line-height: 26px;
              }
            }

            h6 {
              color: #353333;
              font-size: 36px;
              font-weight: 700;
              margin-bottom: 24px;
              text-align: center;

              @media (max-width: 1024px) {
                font-size: 30px;
              }

              @media (max-width: 1024px) {
                font-size: 24px;
              }

              @media (max-width: 991px) {
                font-size: 18px;
                margin-bottom: 15px;
              }
            }

            button {
              margin: auto;
              background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);

              &:hover {
                background: #282828;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }

    .support-form-block {
      .custom-btn {
        margin-top: 20px;
        font-weight: 700;
        font-size: 14px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
      }
    }

    .support-con-block {
      padding: 30px;
      background: #3f3244;
      border-radius: 5px;

      @media screen and (max-width: 991px) {
        margin-top: 20px;
      }

      .title {
        font-weight: 700;
        font-size: 16px;
        color: #ffffff;
        margin-bottom: 22px;
      }

      .con-list {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        border-bottom: 1px solid #c1c1c1;
        padding-bottom: 20px;

        &:last-child {
          margin: 0;
          padding: 0;
          border: none;
        }

        .con-txt {
          label {
            display: block;
            font-weight: 400;
            font-size: 22px;
            color: #ffc1d7;
            margin-bottom: 15px;

            @media screen and (max-width: 991px) {
              margin-bottom: 10px;
            }
          }

          span {
            font-weight: 700;
            font-size: 24px;
            color: #ffffff;

            @media screen and (max-width: 991px) {
              font-size: 20px;
            }
          }
        }

        .con-icon {
          margin: auto;
          margin-right: 0;

          .CallIcon {
            max-width: 39px;
          }
        }
      }
    }

    .support-chat-block {
      position: relative;

      @media screen and (max-width: 991px) {
        margin-top: 50px;
      }

      .white-card {
        padding: 20px;
        transform: translate(0px, 21px);
        -webkit-transform: translate(0px, 21px);
        -moz-transform: translate(0px, 21px);

        @media screen and (max-width: 991px) {
          transform: translate(0px, 0px);
          -webkit-transform: translate(0px, 0px);
          -moz-transform: translate(0px, 0px);
        }

        .support-Chat {
          transform: translate(0%, -50%);
          position: absolute;
          top: 0;
          left: 0;
        }

        .chat-area {
          min-height: 173px;
          max-height: 173px;
          overflow: auto;
          margin: 10px 0;

          @media screen and (max-width: 991px) {
            min-height: 90px;
            max-height: none;
          }

          p {
            font-weight: 400;
            font-size: 14px;
            color: #33475b;
          }
        }
      }
    }
  }
}

.ques {
  .chat-input {
    display: flex;
    margin: 0;

    .form-control {
      margin-right: 7px;
      max-height: 32px;
      min-height: 32px;
    }
  }

  .send-btn {
    min-width: 32px;
    min-height: 32px;

    .svg {
      width: 20px;
      height: 20px;
    }
  }
}

.my-matches-wrap {
  min-height: 100vh;

  .content-section {
    .txt-description {
      font-weight: 400;
      font-size: 14px;
      color: #353333;
      margin-bottom: 30px;
    }

    .table-wrap {
      table {
        border-collapse: separate;
        border-spacing: 0 10px;

        @media(max-width:768px) {
          border-spacing: 0 5px;
        }

        thead {
          th {
            padding-left: 6px;
          }

          @media (max-width:768px) {
            display: none;
          }
        }

        tbody {
          tr {
            background: #fff;
            box-shadow: 0 0 5px #00000021;

            @media(max-width:768px) {
              display: flex;
              flex-direction: column;
              width: 100%;
              margin-bottom: 15px;
              padding: 8px;
            }
          }

          td {
            background: #ffffff;
            border-top: 1px solid #e3e3e3;
            border-bottom: 1px solid #e3e3e3;
            padding: 8px;
            text-align: center;

            &:nth-child(5) {
              @media(max-width:768px) {
                width: 40px;
                height: 40px;
                margin: auto;
                border-radius: 100px;
                padding: 0;
                border: 1px solid #dadada !important;
                margin-top: 15px;

                img {
                  width: 100%;
                  height: 100%;
                  padding: 5px;

                }
              }


            }

            @media(max-width:768px) {
              border: none !important;
              padding-bottom: 0;

              .ratingStars-grp {
                justify-content: center;
              }
            }

            figcaption {
              text-align: left;

              @media(max-width:768px) {
                text-align: center;
              }
            }

            &.pointer-active {
              cursor: pointer;

              h5 {
                text-decoration: underline;
              }
            }

            &:first-child {
              border-left: 1px solid #e3e3e3;
              border-radius: 5px 0 0 5px;
            }

            &:last-child {
              border-right: 1px solid #e3e3e3;
              border-radius: 0 5px 5px 0;
            }

            &.like-btn-wrap {
              position: relative;

              &::after {
                content: "";
                display: block;
                width: 1px;
                height: 100%;
                background: #e3e3e3;
                position: absolute;
                inset: 0;
                margin: auto;

                @media (max-width:768px) {
                  width: 100%;
                  height: 1px;
                }
              }
            }

            h5 {
              font-weight: 400;
              font-size: 14px;
              color: #282828;
              text-align: left;
            }

            .car-list-txt {
              display: flex;
              margin-top: 7px;

              @media (max-width:768px) {
                justify-content: center;
              }

              @media (max-width:480px) {
                margin-top: 10px;
              }

              li {
                font-weight: 400;
                font-size: 14px;
                color: #9c9c9c;
                padding: 0 8px;
                position: relative;

                &::after {
                  content: "";
                  width: 1px;
                  height: 13px;
                  background: #bbbbbb;
                  position: absolute;
                  inset: 0;
                  left: unset;
                  margin: auto;
                }

                &:first-child {
                  padding-left: 0;
                }

                &:last-child {
                  &::after {
                    display: none;
                  }
                }
              }
            }

            h4 {
              font-weight: 700;
              font-size: 22px;
              color: #353333;

            }

            .car-img {
              width: 56px;
              height: 40px;

              @media (max-width:768px) {
                width: 100%;
                height: 80px;
                object-fit: contain;
              }
            }

            .user-img-block {
              display: flex;
              align-items: center;

              img {
                width: 36px;
                height: 36px;
              }

              figcaption {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                text-transform: uppercase;
                color: #666666;
                margin-left: 9px;
              }
            }

            .like-icons {
              width: 30px;
              height: 30px;
              background: #ffffff;
              border: 1px solid #e3e3e3;
              border-radius: 100px;
              padding: 9px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              z-index: 9;
              cursor: auto;
              margin: auto;

              @media (max-width:767px) {
                width: 36px;
                height: 36px;
              }

              svg {
                width: 13px;
                height: 13px;

                @media (max-width:767px) {
                  width: 32px;
                  height: 32px;
                }
              }
            }

            .action {
              display: flex;
              align-items: center;
              width: 100%;
              justify-content: center;

              svg {
                margin-right: 23px;

                path {
                  stroke: #898989;
                }
              }

              .chat-now-btn {
                display: flex;

                svg {
                  margin-right: 10px;
                }

                span {
                  width: 100%;

                  &.chat-now-txt {
                    display: none;
                    font-weight: 600;

                    @media (max-width:767px) {
                      display: block;
                    }
                  }
                }
              }
            }

            .dropdown-content {
              .dropdown-list {
                span {
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

.exterior-select {
  .css-1u9des2-indicatorSeparator {
    display: none;
  }
}

.payment-history-wrap {
  .content-section {
    .txt-description {
      font-weight: 400;
      font-size: 14px;
      color: #353333;
      margin-bottom: 30px;

      @media (max-width:768px) {
        margin-bottom: 15px;
      }
    }

    .table-wrap {
      table {
        border-collapse: separate;
        border-spacing: 0 10px;

        thead {
          th {
            padding-left: 14px;
            color: #282828;

            &.description {
              max-width: 800px;

              @media screen and (max-width: 1199px) {
                max-width: 650px;
              }

              @media screen and (max-width: 991px) {
                max-width: auto;
              }
            }
          }
        }

        tbody {
          td {
            background: #ffffff;
            border-top: 1px solid #e3e3e3;
            border-bottom: 1px solid #e3e3e3;
            padding: 14px;
            font-weight: 400;
            font-size: 14px;
            color: #282828;

            &:first-child {
              border-left: 1px solid #e3e3e3;
              border-radius: 5px 0 0 5px;
              white-space: nowrap;
            }

            &:last-child {
              border-right: 1px solid #e3e3e3;
              border-radius: 0 5px 5px 0;
            }

            &.status-txt {
              font-weight: 700;
              font-size: 14px;
              text-transform: uppercase;
              color: #000;

              &.success-txt {
                color: #20cf1c;
              }

              &.processing-txt {
                color: orange
              }

              &.pending-txt {
                color: #FFBF00
              }

              &.failed-txt {
                color: rgb(255, 0, 0);
              }
            }

            h6 {
              font-weight: 700;
              font-size: 19px;
            }
          }
        }
      }
    }
  }
}

// react datepicker styles

.react-datepicker-input {
  background: url("../assets/images/calendar-icon.svg") no-repeat right;
  background-size: 20px;
  width: 240px;
  height: 24px;
  cursor: pointer;
  background-position: right 20px center;

  &.showBorder {
    border: 1px solid red;
  }
}

.react-datepicker-input:focus-visible {
  border-bottom-color: #b51148;
  outline: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #b51148;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px white;
  border: 1px solid #b51148;
  margin: 0.066rem;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #b51148;
  border-radius: 50%;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50%;
}

.example-datepicker-class .react-datepicker__today-button {
  color: #b51148;
  background-color: white;
  border-top: 0px;
  text-align: right;
  margin-right: 20px;
}

.dealer-create-account {
  .select-avatar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-btn {
      width: 20px;
      height: 20px;
      background: #ffffff78;
      border-radius: 100px;
      padding: 5px;
      position: absolute;
      top: 3px;
      right: 3px;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: #fff;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.modal-overflow-hidden {
  height: 100vh;
  overflow: hidden;
  position: absolute;
}

.title-head {
  gap: 0 20px;
  align-items: self-start !important;

  .title-head-block {
    display: flex;
    width: 100%;
    flex-direction: column;

    .title-box {
      background: #b22b5b;
      height: 42px;
      opacity: 0.9;
      border-radius: 5px;
      padding: 5px 10px;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #e4e4e4;
      margin-bottom: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.current-value {
  align-content: space-between;
  margin: 5px;

  p {
    display: inline;
  }

  h5 {
    display: inline;
    font-weight: bold;
    padding-left: 5px;
  }
}

.add-extraItem-list {
  h6 {
    min-width: 140px;
  }

  .item-list {
    flex-wrap: wrap;
    gap: 10px 15px;

    li {
      margin: 0 !important;
    }
  }
}

.history-check {
  border-bottom: 1px solid #e6e6e6;

  p {
    margin: 0;
    padding: 10px 5px;
  }

  .alert-image {
    width: 18px;
    margin-right: 5px;
  }
}

.history-check-modal {
  .modal-body {
    background: #f6f6f6;
    text-align: left;

    @media screen and (min-width: 576px) {
      max-width: 460px;
    }

    .custom-input {
      margin-bottom: 5px;
    }

    .modal-head {
      padding: 12px;
    }
  }

  .button-group {
    flex-direction: initial;
    margin-top: 25px;

    .custom-btn {
      min-width: 48%;
      margin: 0 5px !important;
    }

    .custom-btn:first-child {
      margin-left: 0;
    }
  }

  .button-group-middle {
    margin-top: 30px;
    justify-content: flex-end;
    width: 100%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .custom-input input {
    background-color: white;
  }
}

.small-button {
  min-width: 0;
}

.btn-Apply {
  display: block;
  margin-bottom: 20px;
  min-width: 100%;
  background: #b22b5b;
}

.profile-status-radio {
  .radio {
    display: flex;
    gap: 0 50px;

    .custom-radiobtn {
      color: #353333;
    }
  }
}

.user-type {
  width: 350px;
  padding: 32px 30px;

  .modal-content {
    padding: 0;
    margin-bottom: 25px;

    .txt-block {
      text-align: left;
    }

    h6 {
      margin-bottom: 25px;
    }

    .radio-wrap {
      gap: 0 75px;
      margin: 0;
    }

    .radio-button {
      font-size: 14px;
      font-weight: 400;
      color: #393939;

      .checkmark {
        top: 1px;
      }
    }
  }

  .modal-foot {
    margin: 0;

    .custom-btn {
      min-width: 126px;
      font-weight: 700;
      font-size: 16px;
      color: #ffffff;
    }
  }
}

.valuationCheck-warp {
  .step-wrap {
    width: 100%;

    .step-list {
      padding: 20px 25px;
      background: #ffffff;
      border: 1px solid #e8e8e8;
      box-shadow: 0px 0px 46px 2px rgba(0, 0, 0, 0.03);
      border-radius: 5px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      cursor: pointer;

      @media (max-width:769px) {
        padding: 10px;
      }

      .step-block {
        display: flex;
        align-items: center;
        margin-right: 21px;

        .custom-checkbox {
          width: 21px;
          height: 21px;
          margin-right: 12px;
          display: block;

          .checkmark {
            width: 21px;
            height: 21px;
            border: none;
            background: #d9d9d9;
            margin-right: 12px;
            border-radius: 100px;



            &:after {
              left: 8px;
              top: 5px;
              width: 5px;
              height: 9px;
            }
          }

          input:checked~.checkmark {
            background: #95c926;
          }
        }

        h6 {
          font-weight: 700;
          font-size: 12px;
          text-align: center;
          color: #282828;
          background: #d9d9d9;
          border-radius: 5px;
          padding: 3px 7px;
        }
      }

      .step-title {
        .step_heading_wrap {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }

        .title {
          font-weight: 700;
          font-size: 14px;
          color: #b22b5b;
          display: inline-block;
        }

        p {
          font-weight: 400;
          font-size: 13px;
          color: #7c7c7c;
        }
      }

      svg {
        margin: auto;
        margin-right: 0;
      }

      &.isActive {
        .step-block {
          h6 {
            background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
            color: #fff;
          }
        }
      }
    }
  }

  .actionBlock {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 28px;
    width: 100%;
    margin-top: 8px;

    @media(max-width: 767px) {
      flex-direction: column;
      gap: 20px
    }

    .custom-btn-skip {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #646464;
    }

    .custom-btn {
      width: 204px;
      font-weight: 700;
      font-size: 14px;
      color: #ffffff;
      text-transform: uppercase;
      margin: 0;

      &.inActive-btn {
        background: #b7b7b7;
        cursor: not-allowed;
      }
    }
  }

  .txt {
    color: #7c7c7c;
    font-size: 14px;
    font-weight: 400;
    margin: 10px 0;
  }
}

.car-valued-block {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .left-block {
    width: 450px;
    background: #fcfcfc;
    border-radius: 15px 0px 0px 15px;
    padding: 30px 70px;
    align-self: stretch;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .valued-header {
      h6.valued-txt {
        color: #7e7d7d;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 12px;
      }

      h6.maker-txt {
        color: #7e7d7d;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      h6.registration-txt {
        color: #7e7d7d;
        text-align: center;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 12px;
      }

      .tick-icon {
        width: 61px;
        height: 61px;
        background: #95c926;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-bottom: 10px;

        svg {
          path {
            stroke: #fff;
          }
        }
      }

      h2 {
        font-family: "Oswald", sans-serif;
        font-weight: 400;
        font-size: 36px;
        color: #3c3c3c;
        margin-bottom: 14px;
        text-align: center;
      }

      .custom-btn-edt {
        background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
        border-radius: 5px;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #ffffff;
        padding: 7px 20px;
        display: flex;
        align-items: center;
        width: 180px;
        height: 34px;
        margin: auto;
        margin-bottom: 14px;

        svg {
          width: 12px;
          height: 12px;
          margin-right: 8px;

          path {
            fill: #fff;
          }
        }

        &[disabled] {
          background: #cacaca;
          cursor: not-allowed !important;
        }
      }

      p {
        font-weight: 400;
        font-size: 12px;
        color: #3c3c3c;
        text-align: center;
      }

      .valued-wow {
        text-align: center;
        font-weight: 700;
      }
    }
  }

  .right-block {
    position: relative;
    flex: 1 1 100%;
    align-self: stretch;
    padding: 30px;

    .bookmark-icon {
      display: flex;
      margin: auto;
      margin-right: 0;
    }

    .addExtras-Block {
      margin-top: 22px;

      .header-block {
        display: flex;
        align-items: center;

        .txt-block {
          h6 {
            font-weight: 700;
            font-size: 14px;
            color: #353333;
            display: block;
            line-height: 20px;
          }

          p {
            font-weight: 400;
            font-size: 12px;
            color: #7c7c7c;
          }
        }

        .plus-icons {
          width: 30px;
          height: 30px;
          background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          margin-left: 10px;

          .action {
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;

            svg {
              fill: #fff;
            }
          }
        }
      }

      .extra-fe-list {
        margin-top: 18px;

        ul {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 6px;

          li {
            background: #eeeeee;
            border-radius: 3px;
            font-weight: 400;
            font-size: 14px;
            text-transform: capitalize;
            color: #535353;
            padding: 3px 7px 3px 7px;
            display: flex;
            align-items: center;

            span {
              padding-left: 23px;
              cursor: pointer;

              svg {
                width: 6px;
                height: 6px;
              }
            }
          }
        }
      }
    }

    .tradeValuationBLock {
      background: #fbfbfb;
      border: 1px solid #efefef;
      border-radius: 5px;
      margin-top: 21px;
      display: flex;

      @media screen and (max-width: 1024px) {
        display: block;
      }

      .tradeValuation {
        padding: 20px 24px;
        border-right: 1px solid #efefef;
        flex: 1 1 50%;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
          border-bottom: 1px solid #efefef;
        }

        h4 {
          font-weight: 400;
          font-size: 14px;
          text-align: left;
          color: #282828;
          padding-top: 8px;
        }

        p {
          font-weight: 400;
          font-size: 22px;
          text-align: left;
          color: #282828;
        }
      }

      .quickSale {
        padding: 20px 24px;
        border-right: 1px solid #efefef;
        flex: 1 1 50%;
        display: flex;
        align-items: center;

        .tooltip-block {
          display: flex;
          align-items: center;
          gap: 0 8px;

          span {
            font-weight: 400;
            font-size: 14px;
            color: #151515;
          }
        }

        .toggleSwitch {
          margin: auto;
          margin-right: 0;

          em {
            display: none;
          }

          .trigger {
            background: #9f9f9f;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);

            &:before {
              left: 2px;
              width: 13px;
              height: 13px;
              bottom: 2px;
              background: #ffffff;
            }
          }

          input:checked+.trigger {
            background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
          }

          input:checked+.trigger:before {
            transform: translateX(20px);
            background: #fff;
            left: 0px;
          }
        }
      }
    }

    .action-block {
      display: flex;
      margin-top: 27px;

      .custom-btn {
        margin: auto;
        margin-right: 0;

        @media (max-width:520px) {
          margin-right: auto;
        }
      }
    }
  }
}

.valuation-extraItems-modal {
  .modal-head {
    padding: 0 13px;
  }

  .modal-content {
    padding: 0 13px;

    .custom-input {
      margin: 15px 0 0px;
    }

    .radio-buttonCheck .checkmark:after {
      left: 8px;
    }
  }

  .modal-foot {
    margin-top: 25px;
  }
}

.upload-car-images-wrap {
  .confirm-block {
    background: #2c2c2c;
    border-radius: 5px;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    color: #e4e4e4;
    padding: 9px 15px;
    margin-bottom: 60px;
  }

  .upload-car-images-list {
    .list-items {
      padding-bottom: 18px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      .left-block {
        display: flex;

        .custom-checkbox {
          width: 21px;
          height: 21px;
          margin-right: 12px;
          display: block;

          .checkmark {
            width: 21px;
            height: 21px;
            border: none;
            background: #cecece;
            margin-right: 13px;
            border-radius: 100px;

            &:after {
              left: 8px;
              top: 5px;
              width: 5px;
              height: 9px;
            }
          }

          input:checked~.checkmark {
            background: #95c926;
          }
        }

        .title {
          h4 {
            font-size: 14px;
            font-weight: 700;
            text-align: left;
            color: #000;

            small {
              color: #c2512e;
              margin-left: 1px;
            }
          }

          span {
            font-size: 13px;
            font-weight: 400;
            text-align: left;
            color: #7c7c7c;
            margin-top: 5px;
          }
        }
      }

      .right-block {
        display: flex;
        align-items: center;

        .img-block {
          height: 38px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            margin-right: 3px;
          }

          .close-icon {
            background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
            height: 20px;
            width: 20px;
            min-width: 20px;
            border-radius: 100px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 8px;

              path {
                fill: #fff;
              }
            }
          }

          .player-wrapper {
            display: flex;
            justify-content: flex-end;
          }
        }

        .choose-file-block {
          button {
            width: 134px;
            height: 38px;
            background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
            border-radius: 5px;
            position: relative;
            font-weight: 700;
            font-size: 14px;
            text-align: center;
            text-transform: uppercase;
            color: #ffffff;
            display: inline-block;
            overflow: hidden;

            .file-control {
              position: absolute;
              inset: 0;
              width: 100%;
              height: 135%;
              top: -10px;
              font-size: 0px;
              opacity: 0;
              cursor: pointer;
            }
          }
        }
      }

      .uploadImg {
        border-bottom: 1px solid rgb(225, 225, 225);
        padding-bottom: 15px;
      }
    }

    .actionBlock {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 18px;

      @media (max-width:767px) {
        flex-wrap: wrap;
      }

      button {
        text-transform: capitalize;
        font-weight: 700;
        font-size: 14px;
        text-align: center;

        @media (max-width:767px) {
          width: 100%;
        }

        &.custom-btn-skip {
          color: #121212;

          &:hover {
            background: transparent;
          }
        }

        &.custom-btn {
          margin: 0;

          &.inActive-btn {
            background: #b7b7b7;
          }
        }
      }
    }

    .mainImagecheck {
      background: rgba(124, 124, 124, 0.5);
      width: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 4px;
      padding: 0;
      position: absolute;
      inset: 0;
      margin: auto;
      height: 0;
      opacity: 0;

      &.active {
        opacity: 1;
        width: 100%;
        height: 100%;
      }

      .form-check-input[type=radio] {
        background-color: transparent;
        border: 1px solid #fff;
        box-shadow: none;
        float: none;
        margin: 0 !important;
        position: relative;
        cursor: pointer;
      }

      .form-check-input:checked[type=radio] {
        background-color: transparent;
        border: 1px solid #fff;
        box-shadow: none;
        float: none;
        margin: 0 !important;
        position: relative;
      }

      .form-check-label {
        background: rgb(237, 17, 85);
        padding: 4px;
        padding-left: 25px;
        margin-left: -24px;
        border-radius: 5px;
        width: fit-content;
        font-size: 11px;
        font-weight: 700;
        text-align: left;
        color: #fff;
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    .img-block:hover .mainImagecheck {
      opacity: 1;
      height: 100%;
      width: 100%;
    }
  }

  .uploadImg {
    display: flex;
    margin: 0px;
    margin-bottom: 30px;
    flex-wrap: wrap;
    gap: 10px;

    .imgList {
      width: calc(100% / 6 - 10px);
      padding: 0 5px;
      min-width: 150px;

      @media (max-width: 1280px) {
        width: calc(100% / 5 - 10px);
      }

      @media (max-width: 1199px) {
        width: calc(100% / 4 - 10px);
      }

      @media(max-width: 767px) {
        width: calc(100% / 2 - 10px);
      }

      &.videoList {
        width: calc(100% / 4);

        @media (max-width: 991px) {
          width: calc(100% / 2);
        }

        @media (max-width: 768px) {
          width: calc(100% / 1);
        }
      }

      .img-block {
        position: relative;
        border-radius: 8px;
        width: 100%;
        object-fit: cover;
        background-color: #c6c6c6;

        img {
          width: 100%;
          height: 90px;
          object-fit: cover;
          border-radius: 8px;
        }

        .close-icon {
          position: absolute;
          top: 5px;
          right: 5px;
          display: flex;
          border: none !important;
          cursor: pointer;
          z-index: 2;
          width: 20px;
          height: 20px;

          svg {
            width: 100%;
            height: 100%;
            fill: #f44335;
            stroke: transparent;

            path {
              stroke: transparent !important;
            }
          }
        }
      }
    }

    .player-wrapper {
      height: 100%;
    }

    .video-player {
      position: relative;
      width: 35%;
      height: 220px;
      margin-right: 5px;

      @media (max-width:769px) {
        width: 100%;
      }

      .close-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        display: flex;
        border: none !important;
        cursor: pointer;
        z-index: 2;
        width: 24px;
        height: 24px;

        svg {
          width: 100%;
          height: 100%;
          fill: #f44335;
          stroke: transparent;

          path {
            stroke: transparent !important;
          }
        }
      }
    }
  }
}

#react-select-3-placeholder {
  text-transform: none;
}

#react-select-3-input {
  text-transform: uppercase;
  width: auto !important;
}

.instructions-modal {
  .modal-head {
    margin-bottom: 15px;

    h4 {
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      color: #282828;
    }
  }

  .modal-body {
    padding: 30px;
  }

  .modal-content {
    margin-bottom: 0;
    padding: 0;
    padding-right: 15px;

    .item {
      .item-img {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        figure {
          position: relative;

          img {
            width: 100%;
            border-radius: 10px;
            height: 100px;
          }

          .iocns {
            width: 34px;
            height: 34px;
            border: 3px solid #f6f6f6;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            padding: 9px;
            transform: translate(0px, -50%);
            position: absolute;
            left: 0;
            right: 0;

            svg {
              width: 10px;
              height: 10px;
            }

            &.correct {
              background: #89c15c;

              path {
                stroke: #fff;
              }
            }

            &.incorrect {
              background: #c2512e;

              path {
                fill: #fff;
              }
            }
          }
        }
      }

      .item-txt {
        margin-top: 30px;

        h3 {
          font-weight: 700;
          font-size: 18px;
          text-transform: uppercase;
          color: #282828;
          text-align: left;
          margin-bottom: 15px;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          text-align: left;
          color: #535353;
          margin-bottom: 15px;
        }
      }
    }

    .slick-dots {
      text-align: left;
      bottom: 0px;
      bottom: 20px;
      position: relative;

      li {
        width: 5px;
        height: 5px;

        button {
          padding: 0;
          width: 5px;
          height: 5px;

          &:before {
            opacity: 1;
            color: #939393;
            width: 5px;
            height: 5px;
          }
        }

        &.slick-active {
          button:before {
            opacity: 1;
            color: #b22b5b;
          }
        }
      }
    }
  }

  .modal-foot {
    margin: 0;
    justify-content: space-between;

    .dont-show-btn {
      background: transparent;
      font-weight: 400;
      font-size: 13px;
      text-align: left;
      text-decoration-line: underline;
      color: #121212;
      padding: 4px 6px;
      margin: 0;
    }

    .understand-btn {
      background: transparent;
      padding: 4px 6px;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      text-transform: uppercase;
      color: #121212;
      margin: 0;
      min-width: fit-content;
    }
  }
}

.damage-detils {
  .damage-title {
    h6 {
      font-family: "PT Sans", sans-serif;
      font-weight: 700;
      font-size: 18px;
      color: #353333;
      padding-bottom: 20px;
    }
  }

  .damage-detils-wrap {
    margin-bottom: 20px;

    .damage-detils-title {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 15px;
      margin-bottom: 15px;

      h6 {
        font-weight: 400;
        font-size: 12px;
        color: #7c7c7c;
      }

      .toggleSwitch {
        em {
          font-weight: 700;
          font-size: 14px;
          color: #282828;
        }

        .trigger {
          &:before {
            background: #434343;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          }
        }
      }

      .toggleSwitch input:checked+.trigger:before {
        transform: translateX(16px);
        background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
      }
    }

    .damage-detils-img {
      .Img-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .Img-list-items {
          width: calc(100% / 4 - 8px);
          display: flex;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.my-car-list {
  flex-direction: column;

  .listingCars-list-items {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: stretch;
  }

  @media (max-width: 520px) {
    .modal-wrap .modal-foot .custom-btn {
      width: 180px !important;
    }
  }
}

.my-team-button-wrap {
  .custom-btn {
    border: 1px solid #000;

    &:hover {
      background: linear-gradient(to right, #ff8177, #b22b5b);
      border: none;
    }

    &.whiteBtn {
      &:hover {
        border: none;
      }
    }
  }
}

.react-tel-input {
  .form-control {
    border: 1px solid #e4e4e4 !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.instruction-wrap {
  display: flex;
  gap: 0 30px;

  .instruction-img {
    position: relative;
    border: 1px solid #ebebeb;
    border-radius: 19px;

    &::after {
      content: "";
      display: block;
      background: rgba(0, 0, 0, 0.308);
      filter: blur(14px);
      position: absolute;
      bottom: 0;
      width: 90%;
      height: 30px;
      margin: auto;
      left: 0;
      right: 0;
      z-index: -1;
      border-radius: 100px;
    }
  }
}

.instruction-txt {
  padding: 35px;
  background: #ffffff;
  border-radius: 5px;

  h3 {
    font-weight: 700;
    font-size: 24px;
    color: #282828;
    border-bottom: 1px solid #cdcdcd;
    margin-bottom: 20px;
    padding-bottom: 17px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: #535353;
  }
}

.flag-dropdown {
  background: transparent !important;
  border: none !important;

  .selected-flag {
    padding: 0;

    &:hover {
      background: transparent !important;
    }
  }
}

.payment-successful-block {
  .payment-img {
    img {
      display: block;
      margin: auto;
      max-width: 100%;
      height: 209px;
    }
  }

  .payment-txt {
    margin-top: 15px;

    h3 {
      font-weight: 400;
      font-size: 28px;
      text-align: center;
      color: #4caf50;
      margin-bottom: 15px;
      font-family: "Magistral", sans-serif;

      @media screen and (max-width: 520px) {
        font-size: 20px;
      }
    }

    p {
      font-weight: 400;
      font-size: 12px;
      text-align: center;
      color: #3c3c3c;
      margin-bottom: 15px;
      max-width: 410px;
    }

    .btn {
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      color: #ffffff;
      width: auto;
      height: 44px;
      background: #102027;
      border-radius: 5px;
      margin: auto;
      display: block;

      &:hover {
        background: #b22b5b;
      }
    }

    &.failed-txt {
      h3 {
        color: #ff0000;
      }
    }
  }
}

.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.loader-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 70px;
  }
}

.inner-loader-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #343434cf;
  inset: 0;
  z-index: 9999;
}

.loader-center-page-spinner {
  position: absolute;
  width: 100%;
  height: 100vh;
  inset: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #343434cf;
  z-index: 99;
}

.remaining-text {
  font-weight: 700;
  font-size: 12px;
  text-align: left;
  margin: 0;
  color: #3c3c3c;
  margin-bottom: 10px;
}

.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  position: relative;

  &::before {
    border-color: #262626;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 20px;
    width: 9px;
    transform: rotate(314deg);
    left: 12px;
  }
}

.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  position: relative;

  &::before {
    border-color: #262626;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 10px;
    width: 9px;
    transform: rotate(136deg);
    left: 12px;
  }
}

.content-section-overlay {
  position: relative;
  padding-bottom: 40px;

  @media (max-width:768px) {
    padding-bottom: 0;
  }

  &.open {
    transition: 0.5s ease;

    &::before {
      width: 100%;
      transition: 0.5s ease;
    }

    .icon-block {
      width: 70px;
      height: 70px;
      transition: 0.9s ease;
      padding: 10px;

      svg {
        opacity: 1;
        transition: 0.9s ease;
      }
    }
  }

  &::before {
    content: "";
    background: #00000080;
    position: absolute;
    inset: 0;
    right: unset;
    margin: auto;
    height: 100%;
    width: 0;
    z-index: 99;
    backdrop-filter: blur(3px);
  }

  .icon-block {
    position: absolute;
    inset: 0;
    width: 0px;
    height: 0px;
    background: #ffffff;
    cursor: pointer;
    z-index: 999;
    border-radius: 100px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      opacity: 0;

      path {
        stroke: #ccc;
      }
    }
  }

  .isActive {
    svg {
      path {
        stroke: #b22b5b;
      }
    }
  }
}

.damage-wrap {
  .form-container {
    grid-template-columns: 1fr;

    .add-border {
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 5px;
    }

    .custom-input {
      padding-bottom: 15px;
      margin: 0;

      @media screen and (max-width: 767px) {
        margin: 20px 0;
      }
    }

    .image-list {
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 5px;
    }
  }

  .img-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;

    @media screen and (max-width: 1024px) {
      gap: 10px;
    }

    .img-item {
      width: calc(100% / 6 - 20px);
      position: relative;
      background: #f3f3f3;
      border: 1px solid #eeeeee;
      padding: 5px;
      border-radius: 4px;

      @media screen and (max-width: 1280px) {
        width: calc(100% / 5 - 10px);
      }

      @media screen and (max-width: 1199px) {
        width: calc(100% / 4 - 10px);
      }

      @media screen and (max-width: 767px) {
        width: calc(100% / 2 - 10px);
      }

      &.fileupload-btn {
        border: 1px dashed #b22b5b;
        background-color: #fefafb;
      }

      img {
        width: 100%;
        border-radius: 5px;
        object-fit: cover;
        height: 90px;
      }

      .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: transparent !important;
        border: none !important;
        cursor: pointer;
        width: 20px;
        height: 20px;

        svg {
          fill: #f44335;
          stroke: transparent;

          path {
            stroke: transparent !important;
          }
        }
      }
    }

    .choose-file {
      .custom-file-input {
        padding: 26px 30px;
      }
    }
  }

  .wheels-wrap-block {
    margin-bottom: 30px;
  }
}

.error-wrap {
  .error-block {
    img {
      max-width: 100%;
    }

    img,
    p,
    button {
      display: block;
      margin: auto;
      text-align: center;
    }

    p {
      margin: 20px 0;
    }
  }
}

.instruction-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.dashboard-wrp {
  .tail-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
    gap: 24px 0;

    @media(max-width:767px) {
      gap: 10px;
    }

    .tail-list {
      width: calc(100% / 5);
      display: flex;
      padding: 0 12px;

      @media (max-width: 1199px) {
        width: calc(100% / 3);
      }

      @media (max-width: 991px) {
        width: calc(100% / 2);
      }

      @media (max-width: 767px) {
        width: calc(100% / 1);
      }

      .tail-items {
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 20px 22px;
        width: 100%;
        cursor: pointer;

        @media(max-width: 767px) {
          padding: 10px 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &.selected {
          background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
          color: white;

          span {
            color: white;
          }

          h4 {
            color: white;

            @media (max-width:768px) {
              border: 1px solid #fff;
            }
          }
        }

        span {
          color: #4a4a4a;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 2px;
        }

        h4 {
          color: #232323;
          font-size: 40px;
          font-weight: 400;

          @media(max-width: 767px) {
            font-weight: 400;
            display: block;
            width: 100px;
            background: #cd4a65;
            border-radius: 5px;
            color: #fff;
            padding: 10px;
            font-size: 20px;
            text-align: center;
          }
        }
      }
    }
  }

  .sub-details {
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0px 0px 46px 2px rgba(0, 0, 0, 0.03);
    margin-top: 35px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 10px 0;
      padding: 10px;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .txt-block {
      display: flex;
      align-items: center;

      h4 {
        color: #000;
        font-size: 20px;
        font-weight: 700;
        margin-right: 25px;
        padding-right: 25px;
        border-right: 1px solid #c8c8c8;

        @media (max-width: 768px) {
          font-size: 14px;
          font-weight: 700;
          margin-right: 5px;
          padding-right: 5px;
          border-right: 1px solid #c8c8c8;
        }
      }

      span {
        color: #8c8c8c;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .btn-upgrade {
      border-radius: 5px;
      border: 1px solid #fff;
      background: #303030;
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .price-block-txt {
    margin-bottom: 35px;

    @media (max-width: 768px) {
      margin-top: 20px;
    }

    h4 {
      color: #000;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    span {
      color: #8c8c8c;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .table-wrap {
    margin-bottom: 15px;
    overflow-y: hidden;

    table {
      td {
        padding: 8px;

        &.tradeValue {
          color: #8c8c8c;
        }

        .stock-list-title {
          word-break: break-word;
          white-space: break-spaces;
        }
      }
    }
  }

  .tbl-data {
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    background: #fff;
    padding: 30px;

    @media (max-width:768px) {
      padding: 15px;
    }

    h4 {
      color: #181818;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 15px;
    }

    thead {
      background: #dfdfdf;

      th {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        padding: 6px 0;
      }
    }

    td {
      border-bottom: 1px solid #dfdfdf;

      span {
        font-size: 12px;
        color: #787878;
        font-weight: 400;
      }

      h6 {
        font-size: 12px;
        font-weight: 400;
        color: #282828;
      }
    }
  }

  .modal-foot {
    @media (max-width:520px) {
      flex-wrap: wrap;
      flex-direction: column;
      gap: 10px;
    }
  }
}

.dashTbl {
  margin-top: 20px;
  min-height: 450px;

  table {
    border-spacing: 0 12px;
    border-collapse: separate;
    width: 100%;

    thead {
      th {
        color: #000;
        font-size: 12px;
        font-weight: 400;
        padding: 8px;
        white-space: nowrap;

        &:first-child {
          padding: 8px;
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          padding: 8px;
          border-radius: 5px 0 0 5px;
        }

        &.listedTh {
          width: 200px;
        }

        &.descriptionTh {
          width: 300px;
        }

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    tbody {
      tr {
        td {
          color: #000;
          font-size: 14px;
          font-weight: 400;
          background: #fff;
          padding: 8px;
          border-top: 1px solid #e3e3e3;
          border-bottom: 1px solid #e3e3e3 !important;
          white-space: nowrap;

          &:first-child {
            padding: 8px;
            border-radius: 5px 0 0 5px;
            border-left: 1px solid #e3e3e3;
          }

          &:last-child {
            padding: 8px;
            border-radius: 0 5px 5px 0;
            border-right: 1px solid #e3e3e3;
          }

          .description-block {
            display: flex;
            gap: 24px;

            img {
              width: 56px;
              height: 56px;
              object-fit: cover;
              border-radius: 2px;
            }

            h5 {
              color: #000;
              font-size: 14px;
              font-weight: 400;
              text-decoration-line: underline;
              word-break: break-all;
            }

            ul {
              display: flex;
              margin-top: 7px;

              li {
                color: #9c9c9c;
                font-size: 14px;
                font-weight: 400;
                margin-right: 8px;
                padding-right: 8px;
                border-right: 1px solid #bbb;
                line-height: 12px;

                &.errorTxt {
                  color: #fe6969;

                  svg {
                    width: 12px;
                    height: 12px;

                    path {
                      stroke: #fe6969;
                    }
                  }
                }

                &:last-child {
                  border: none;
                  padding: 0;
                }
              }
            }
          }

          .dropdown-btn {
            .btn {
              background: transparent;
              border: none;

              &:focus,
              &:active {
                background: transparent !important;
                border-color: transparent !important;
                box-shadow: none !important;
              }

              &::after {
                content: "";
                border: none;
                background: url("../assets/images/dots-img.svg");
                height: 30px;
                width: 4px;
                background-repeat: no-repeat;
                background-position: 0px 10px;
              }
            }

            .dropdown-menu {
              background-color: #fff;
              border: 1px solid #e4e4e4;
              border-radius: 5px;
              box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
              min-width: 160px;

              &::before {
                content: "";
                position: absolute;
                right: 5px;
                top: -12px;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 12px solid white;
                filter: drop-shadow(0px 0px 0px #989898);
              }

              a {
                align-items: center;
                color: #000;
                cursor: pointer;
                display: flex;
                font-size: 12px;
                font-weight: 400;
                padding: 6px 12px;

                &:focus,
                &:active {
                  background: #f8f9fa !important;
                  border-color: transparent !important;
                  box-shadow: none !important;
                }
              }
            }
          }

          .price-list {
            width: 8px;
            height: 8px;
            background: #b22b5b;
            border-radius: 100px;
            display: inline-block;
            margin-left: 8px;
          }

          .survey-circlebar {
            display: flex;
            align-items: center;
            gap: 20px;

            .survey-circlebar-block {
              width: 40px;
              font-size: 25px;

              .CircularProgressbar-path {
                stroke: #4caf50;
              }
            }
          }

          .listed-block {
            display: flex;

            .toggleSwitch {
              margin-right: 10px;

              em {
                display: none;
              }
            }

            .custom-btn {
              border-radius: 15px;
              background: #6fcf35;
              min-width: 90px;
              min-height: 20px;
              padding: 2px 15px;
              color: #fff;
              text-align: center;
              font-size: 12px;
              font-weight: 700;
              text-transform: uppercase;
              margin-right: 30px;

              &.unlisted {
                background: #cfcfcf;
              }
            }
          }
        }
      }
    }
  }
}

.location-map-wrp {
  margin-bottom: 15px;

  .error {
    color: red;
  }
}

.cardetails-list {
  margin-bottom: 15px;
}

.transfer-Terms {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #f1f1f1;
  padding: 15px;
  width: 100%;

  .checkmark {
    background: #fff;
    border: none;

    &:after {
      left: 6px;
      top: 3px;
    }
  }

  label {
    margin: 0;
    color: #241201;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;

    span {
      color: #f17071;
      margin-left: 3px;
      
    }
    .agree-button{
      cursor:pointer;
      color:#f17071;
    }
  }

  .custom-checkbox input:checked~.checkmark {
    background: #97ce0e;
    cursor: default !important;
  }
}

.terms-conditions-modal {
  .filterTab {
    .content {
      width: 100%;

      ul {
        li {
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 20px;
          padding-left: 20px;
          position: relative;

          @media (max-width:768px) {
            font-size: 14px;
          }

          &::after {
            content: "";
            display: block;
            margin: auto;
            width: 11px;
            height: 11px;
            background: #000;
            border-radius: 100px;
            position: absolute;
            top: 5px;
            left: 0;
            right: unset;
          }
        }
      }
    }
  }

  .modal-foot {
    justify-content: flex-end !important;
    width: 100% !important;

    @media (max-width:768px) {
      justify-content: center !important;
    }

    .custom-btn {
      min-width: 180px !important;
      min-height: 42px !important;

      &.disabled {
        background: #cacaca;
        cursor: not-allowed !important;
      }
    }
  }
}

.title-accodion-new {
  font-size: 18px;
  font-weight: 700;
  color: #353333;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.head-additional-info {
  font-weight: 700;
  font-size: 14px;
}

.history-list-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 0;
  margin: 0 -12px;

  .history-list {
    width: calc(100% / 3);
    padding: 0 12px;

    @media (max-width: 1024px) {
      width: calc(100% / 2);
    }

    @media (max-width: 768px) {
      width: calc(100% / 1);
    }

    .check-history-input {
      display: flex;
      align-items: flex-end;
      width: 100%;
      gap: 0 20px;
      margin-bottom: 25px;
      flex: 1 1 100%;

      .custom-input {
        margin-bottom: 0;
      }
    }
  }
}

.full-width {
  .content {
    width: 100%;
  }
}

.loader-inside-field {
  display: flex;
  position: absolute;
  z-index: 9;
  margin-left: 80%;
  margin-top: 3%;
}

//

.input-phone-number input:focus {
  box-shadow: none !important;
}

.logo-private {
  &::after {
    content: "";
    display: none;
  }
}

.hpi-history {
  .react-datepicker-input {
    background-color: #fafafa;
  }
}

.Carouselmorebtn {
  height: 395px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ExpiredPremiumPost {
  margin: 30px 0 0 0;

  .title-wrap {
    padding-bottom: 30px;

    h5 {
      color: #353333;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
    }
  }

  .ExpiredPremiumPostCard {
    .list {
      width: 33.33%;
      padding: 0 15px;

      @media (max-width: 1024px) {
        width: 50%;
        margin-bottom: 30px;
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      .premiumpost-card {
        width: 100%;


        &.sold {
          &::before {
            content: '';
            position: absolute;
            inset: 0;
            margin: auto;
            background: #00000042;
            z-index: 1;
            pointer-events: none;
          }
        }
      }

      .pink-btn-sold {
        background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
        min-height: 33px;
        min-width: 109px;
        position: absolute;
        z-index: 99;
        inset: 0;
        margin: auto;
        width: 110px;
        height: 33px;
      }

      h3 {
        margin-bottom: 10px;
        text-transform: uppercase;
        text-align: left;
      }

      .premium {
        padding: 0;
        margin: 0;
        border: none;
      }
    }

    .ExpiredPremiumPostCard-items {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
      gap: 30px 0px;

      .disable-pointer-events {
        pointer-events: none;
      }

      .history-btn {
        right: 25px;
        left: unset;
        background: #000;

        &:hover {
          opacity: 0.8;
        }
      }

      .history-btn-sold {
        width: 100%;
        position: relative;
        background: #282828;
        z-index: 99;
        pointer-events: auto;
      }
    }
  }
}

.timer-without-resend {
  width: 100%;
  @include flex-between;
  margin-top: 15px;
  cursor: default;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: $regularText;
    color: $darkgreyText;

    @media screen and (max-width: 767px) {
      font-size: 13px;
    }
  }
}

.acceptTerms-updateUser {
  cursor: auto;
}

.cursor {
  cursor: pointer;
}

.remove-cursor {
  cursor: default;
}

.img-modal-lightbox {
  .modal-body {
    max-width: 760px;

    img {
      width: 100%;
      height: 100%;
      max-height: 450px;
      object-fit: contain;

      @media (max-height:480px) {
        max-height: 90%;
      }
    }

    .closeBtn {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
      background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
      padding: 7px;
      border-radius: 100px;
      height: 34px;
      width: 34px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }
}

.disabled-container {
  opacity: 0.5;
  pointer-events: none;
}

.confirm-modal-pending-payment {
  font-size: 14px;
  font-weight: 400;
  color: #7c7c7c;
  margin: 0;
  margin-top: 5px;
}

/* Map Styles */
.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
  font-weight: 400;
  color: #7c7c7c;
  margin: 0;
  margin-top: 5px;
}

.quick-sale-text {
  display: block;
  background: linear-gradient(to right, #ff8177, #b22b5b);
  width: 240px;
  padding: 5px 0;
  position: absolute;
  top: 0px;
  right: -80px;
  z-index: 99;
  transform: rotate(45deg);
  text-align: center;
  color: white;
  font-size: 12px;
  text-indent: 37px;
}

.likes-block {
  display: flex;
  align-items: center;
  padding: 7px 12px;
  position: absolute;
  top: 30px;
  right: 60px;
  background: #141414;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;

  span {
    margin-left: 9px;
  }
}

.post-type-condition {
  margin-right: 70px;
}

.quickOffer-button-false {
  opacity: 0.5;
}

.btnWrp.logistic-contact {
  display: flex;
  flex-direction: row-reverse;
}

.tollpick-phone {
  button {
    width: 25px;
    height: 25px;
  }
}

//retailCheck loader
.retail-cal {
  display: flex;
  justify-content: space-between;

  .custom-spinner-outer {
    margin: auto;

    .custom-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      border-width: 4px;
      width: 20px;
      height: 20px;
    }
  }
}

.chat-images-collection {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  flex-direction: row;

  img {
    width: 100%;
    height: 100%;
    padding: 5px;
  }

  .image-wrapper-collection {
    width: 50%;
  }

  .single-img {
    width: 300px;
  }

  h6 {
    color: #fff;
  }
}

.selectall-design {
  padding: 20px;
  background-color: #fbfbfb;
  border-radius: 2px;
}

.MyRating-wrap {
  min-height: 100vh;

  table {
    border-collapse: separate;
    border-spacing: 0 9px;

    thead {
      tr {
        th {
          padding: 7px 15px;
        }
      }

      h6 {
        text-align: left !important;
      }
    }

    tbody {
      tr {
        td {
          padding: 7px 15px !important;
          background: #fff;
          border: 1px solid #e3e3e3;
          border-right: none;
          border-left: none;

          &:first-child {
            border-left: 1px solid #e3e3e3;
            border-radius: 5px 0px 0px 5px;
          }

          &:last-child {
            border-radius: 0px 5px 5px 0px;
            border-right: 1px solid #e3e3e3;
          }

          .ratingStars-grp {
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}

.no-car-message {
  display: flex;
  width: 100%;
  justify-content: center;
}

.unBlock-button {
  display: flex;
}

.sold-btn {
  border-radius: 15px;
  background: #6fcf35;
  min-width: 90px;
  min-height: 20px;
  padding: 2px 15px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 30px;
  pointer-events: none;
  border: none;
}

.quick-sale-no-image {
  .no-data {
    height: 100%;
  }
}

.pointer-cursor {
  cursor: default;
}

.btn-tooltip {
  width: 100%;

  &::after {
    top: calc(100% + -10px) !important;
  }

  &::before {
    right: auto;
    left: 50%;
    transform: translate(-50%);
  }
}

.filter-accordion {
  .checkType-checkbox {
    padding-right: 50px;
    margin: 0px;

    @media (max-width:769px) {
      padding-right: 30px;
    }
  }

  .szh-accordion__item .szh-accordion__item-btn {
    position: relative;

    &::after {
      top: 0;
      margin: auto;
    }
  }

  .grpCheck .grpCheck-body {
    .checkType-checkbox {
      padding-right: 0px;
      margin-bottom: 20px;

      @media (max-width:768px) {
        margin-bottom: 0;
      }
    }
  }
}

.quickEditPin-btnInactive {
  margin: 0 0 15px 0;

  @media screen and (max-width: 991px) {
    margin: 0 15px 0 0;
  }

  @media screen and (max-width: 767px) {
    margin: 0 0 15px 0;
  }
}

.dashboard-footer {
  padding-bottom: 50px !important;
}

.agree-text-div {
  padding-top: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  .agree-text {
    margin-left: 10px;
  }
}

.dotted-spinner {
  border: 2px dashed #565656;
  border-top: 2px solid #3c3c3c;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
  width: 25px;
  height: 25px;
}

.send-attachment-images {
  min-width: 135px;
}

.mobile-nav {
  display: none;

  @media screen and (max-width: 1198px) {
    display: block;
  }
}

.show-extra-modal {
  .pro-pic {
    width: 50px;
    height: 50px;
    display: flex;
    background: linear-gradient(to right, #ff8177, #b22b5b);
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: contain;
    }

    @media screen and (max-width: 1600px) {
      width: 35px;
      height: 35px;
    }

    @media screen and (max-width: 1366px) {
      width: 40px;
      height: 40px;
    }
  }

  .figcaption {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #666666;
    margin-left: 9px;
  }
}

.extra-conditions-modal {
  .list-cars {
    .content {
      width: 100%;

      table {
        width: 100%;
        border-collapse: collapse;

        tr {
          border-bottom: 0.5px dashed #3c3c3c;
          padding: 12px;
          display: flex;
          justify-content: space-between;

          .chat-sold-cae-list {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }

        tr:last-child {
          border: 0;
        }
      }

      .figCaption {
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        color: #666666;
        text-align: left;

        h6 {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 14px;
          margin: 3px 10px 3px 0px;
          color: #7c7c7c;
        }

        h5 {
          text-transform: uppercase;
          margin: 3px 0;
          color: #353333;
          font-weight: 700;
          font-size: 13px;
        }

        span {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 180px;
        }
      }

      .transferPay {
        margin: 25% 0 25% 0;

        h3 {
          font-weight: 700;
          font-size: 14px;
          color: #353333;
          margin: 0;
        }
      }
    }
  }

  .modal-foot {
    justify-content: center !important;
    width: 100% !important;

    .custom-btn {
      min-width: 180px !important;
      min-height: 42px !important;

      &.disabled {
        background: #cacaca;
        cursor: not-allowed !important;
      }
    }
  }

  .chat-sold-car {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .chat-sold-car-list {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      gap: 10px;
      padding-bottom: 15px;
      border-bottom: 1px dashed #757575;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      .chat-sold-cae-list {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

.txt-upper-case {
  text-transform: uppercase;
}

.txt-transnone-imp {
  text-transform: none !important;
}

.payby-txt {
  color: #666666 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.transfer-contact-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #cfcfcf;
  padding: 10px 20px;
  border-radius: 5px;

  @media (max-width: 769px) {
    padding: 10px;
    width: 100%;
    padding-left: 14px;
  }

  .text-blk {
    font-size: 16px;
    font-weight: 600;
  }

  .custom-btn {
    min-width: 120px;

    svg {
      transform: rotate(270deg);
      margin-left: 10px;
      width: 15px;
      height: 15px;

      path {
        stroke: white;
      }
    }
  }
}

.question-error {
  .send-btn {
    margin-bottom: 20px;
  }
}

.filter-selected-opt {
  background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.th-filter-drop {
  margin-left: -20px;

  .dropdown-item {
    &:active {
      background: #fff !important;
      color: #000;
    }
  }
}

span.round-image {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.chat-amt-input {
  margin-right: 2px;

  input {
    width: 80% !important;

    &:focus {
      box-shadow: none;
      border: 1px solid #ddd;
    }
  }
}

.chat-payby-select {
  width: 100px !important;
}

.swap-cash input {
  background: #fff;
}

.choose-file-attachment {
  position: relative;
  width: 100%;
  height: 60px;
  background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  svg {
    width: 22px;
  }
}

.choose-button {
  padding-right: 2px;
  width: 100%;
}

.wrap-text-button {
  margin-top: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;

  h5 {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .custom-btn {
    &:last-child {
      margin: 0 !important;
    }
  }
}

.display-flex {
  display: flex;
}

.negocicate-modal {
  padding-bottom: 14px;

  .custom-input {
    display: flex;
    gap: 10px;

    @media screen and (max-width: 520px) {
      flex-direction: column;
    }
  }

  .chat-amt-input {
    flex: 0 0 50%;
  }

  .error {
    position: absolute;
    bottom: 0;
    transform: translate(0, 30px);
    font-size: 12px !important;
    line-height: 19px;
    left: 0;
  }

  .info-wrap {
    svg {
      margin-bottom: 2px;
    }
  }
}

.mc-block-black {
  color: #353333 !important;
}

.car-cash-offer {
  align-items: center;
  margin-top: 10px;
}

.negocicate-modal-content {
  overflow: unset !important;

  .error {
    text-align: left;
    position: relative;

    p {
      font-size: 12px;
      color: red;
      line-height: unset !important;
      margin: 0;
    }
  }
}

.tooltip-warning {
  background-color: #585858;
  border-radius: 10px;
}

.tooltip {
  position: absolute;
  z-index: 11112;
  display: block;
  visibility: visible;
  padding: 5px;
  font-size: 11px;
  opacity: 0;
  filter: alpha(opacity=0)
}

.tooltip-arrow-at-right {
  .tooltip-arrow {
    transform: translate(15px, 0) !important;
  }
}

.tooltip.bottom {
  margin-top: 2px
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #000;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px
}

.overflow-elipse-dot {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}

.overflow-elipse-dot-ts {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.overflow-elipse-dot-blk {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 275px;
}

.chatHead-starRating {
  margin-left: 0 !important;
}

.chatHead-button {
  display: flex;
  align-items: center;
  text-align: justify;
}

.switchOff {
  .toggleSwitch {
    .trigger {
      cursor: default !important;
    }
  }
}

.checkmark_warning,
.checkmark_waiting {
  background-color: #FF0000 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    display: none !important;
  }

  svg {
    margin: 0 !important;
    width: 3px;
  }
}

.checkmark_waiting {
  background-color: #8f8f8f !important;

  svg {
    width: 9px;
  }
}

.step-title_infoicon {
  display: inline-flex;
  width: 16px;
  height: 16px;
  border: solid 2px #919191;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  border-radius: 50%;
  color: #5d5c5c;
  cursor: pointer;
  margin-left: 15px;
  position: relative;

  &:hover .stepinfo_popover {
    display: block;
  }

}

.steplist_disabled {
  background-color: #f6f6f6 !important;
  position: relative;

  &:hover .step-list_disabled_message {
    display: block;
  }

  .step-list_disabled_message {
    position: absolute;
    left: 0;
    top: auto;
    bottom: 0px;
    width: auto;
    height: auto;
    background: #454545f0;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    display: none;
    font-size: 12px;
  }
}

.stepinfo_popover {
  position: absolute;
  width: 200px;
  left: -20px;
  bottom: 25px;
  right: 100px;
  margin: auto;
  background: #000;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  display: none;

  &::after {
    content: "";
    position: absolute;
    width: 0;
    border-top: solid 8px #000;
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
    bottom: -8px;
    left: 19px;
  }

}

.old-new-rate-change {
  display: flex;
  flex-direction: column;

  h4 {
    font-weight: 700;
    font-size: 14px;
    color: #353333;
    margin: 0;
  }
}

.transferPayNew {
  margin: 5% 0 5% 0;
}

.private-place-holder {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 5px !important;

  img {
    width: 60% !important;
  }
}


.imgOverlaywrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
  background: rgba(230, 230, 230, 0.75);
  z-index: 11;

  svg {
    width: 50px;
    height: 50px;
  }
}

.imgOverlaywrap-small {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  z-index: 11;
  background: rgba(230, 230, 230, 0.75);
  backdrop-filter: blur(4px);

  svg {
    width: 30px;
    height: 30px;
  }
}

.custom-accordion-header {
  display: flex;

  .accodion-header-image {
    margin-left: 10px;
  }

  .checkmark_waiting {
    width: 24px;
    height: 24px;
    border-radius: 15px;
  }

  .checkmark_warning {
    width: 24px;
    height: 24px;
    border-radius: 15px;
  }
}

#popover {
  z-index: 9999;

  .notification-wrp-block {
    position: absolute;
    width: 550px;
    right: -30px;
  }
}

.account-form {
  .tollpick-phone {
    svg {
      path {
        stroke: red !important;
      }
    }
  }
}

p.max-char-limit {
  text-align: right;
  font-size: 13px;
  font-style: italic;
  color: #767676;
}

.report-select {
  label {
    width: 100%;
  }
}

.report-txtarea {
  label {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.filterModal {
  .w-50-wrp {
    .checkType-checkbox {
      width: 100% !important;
    }
  }

  .modal-foot {
    margin: 0;

    .custom-btn,
    .send-btn {
      min-width: 150px;
      font-size: 12px;
    }
  }
}

.colourCheckbox-wrp,
.checkType-checkbox {
  width: 100% !important;
}

label.contact-label {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.service-img-holder {
  position: relative;
  height: 100%;
  width: 100%;
}

.align-edit-delete {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .edit-icon {
    display: flex;
    align-items: center;
  }

  .delete-icon {
    display: flex;
    align-items: center;
  }
}

.section-loader-wrap {
  margin-top: 140px;
  min-height: 450px;
  position: relative;
}

.svg-padding {
  padding-left: 10px;
  padding-right: 10px;
}

.sm-mobile-view {
  display: none;

  @media (max-width:769px) {
    display: block;
  }
}

.verification-format-phone {
  div {
    .form-control {
      padding-left: 40px !important;
      padding-top: 10px !important;
      border: none !important;
    }
  }
}

.mycarlists-wrap {
  .title-btn-grp {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-end;
  }

  @media (max-width: 1280px) {
    .sm-mobile-view {
      display: block;
    }
  }
}

.quick-sale {
  .card-content {
    flex-direction: column !important;
    gap: 10px;
  }
}

.notification-item-inner {
  text-align: left;
}

.quick-sale-wrng {
  color: #ff0000a8;
  font-style: italic
}

.modal-condition-yes {
  color: #ffffff !important;

  &.btn-logout {
    .loader {
      border: 3px solid #565656;
      border-top: 3px solid #3c3c3c;
      width: 20px;
      height: 20px;
    }
  }
}

.head-menu .confirm-modal .custom-btn {
  @media (max-width:420px) {
    height: 25px;
  }
}

.reduce-image {
  position: relative;
  min-width: 80px;
  min-height: 80px;
  display: block;
  width: 100%;
}

.detail-modal.img-modal-lightbox {
  margin-top: 100px;
  padding-bottom: 80px;
}

#overlay-tooltip {
  z-index: 99;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;

  &::after {
    content: " ";
    top: 100%;
    /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    position: absolute;
    border-color: black transparent transparent transparent;
  }
}

.overlay-tooltip {
  z-index: 99;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;

  &::after {
    content: " ";
    top: 100%;
    /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    position: absolute;
    border-color: black transparent transparent transparent;
  }
}

.car-carousel .slider1 figure .resetButton {
  height: 75px;
  display: block;
  margin: auto;
}

.car-carousel .slider2 .slick-list .slick-slide figure .resetButton {
  height: 400px;
}

.stock-list-title {
  cursor: pointer;
  text-decoration: underline;
  text-align: left;
}

.full-width-text {
  max-width: 1500px;
  width: 100%;
}

.cusInfo-icons {
  width: fit-content;
  border-radius: 2px;
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, #F87A74 16.28%, #BE385F 82.56%);
  align-self: end;
}

.txt {
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  padding: 5px 10px;
}

.infoIcon {
  border-radius: 2px 0px 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #BE385F;
  padding: 5px;

  .info-tooltip {
    svg {
      width: 12px !important;
      height: 12px !important;

      path {
        stroke: #fff !important;
      }
    }
  }

  button {
    svg {
      width: 14px !important;
      height: 14px !important;

      path {
        stroke: #fff !important;
      }
    }
  }
}

.remove-bg-color {
  background: unset;

  .txt {
    padding-left: 2px;
  }
}

// Likes Modal Styles
.like-model-list {
  li {
    margin: 15px 0px;
  }
}

.like-user-name {
  margin-left: 10px;
  font-weight: 600;
  max-width: 75%;
  text-align: left;
  word-break: break-word;
}

.like-user-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #ddd;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.likeuser-placeholder {
  padding: 8px;

  img {
    border-radius: 0 !important;
  }

  background: #ddd;
}

.like-chat-btn {
  color: #ffffff;
  padding: 3px 12px;
  border-radius: 5px;
  font-size: 13px;

  span {
    margin-left: 3px;
  }
}

.likes-modal-head {
  h3 {
    text-align: center;
    color: #b22b5b !important;
  }
}

.likes-hook-btn {
  position: relative;
  font-weight: normal !important;
}

.disbled-like-chat {
  background: #ddd !important;
  color: #fff !important;
  cursor: default !important;
}

.btn-overlay {
  position: absolute;
  background: #fff8f88f;
  height: 100%;
  padding: 3px;
}

.small-overlay {
  width: 100%;
  left: -3px;
  top: 0px;
}

.profile-btn-overlay {
  left: 0px;
  top: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel-btn-overlay {
  width: 100%;
  left: -5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  .btn-overlay-spinner {
    height: 20px !important;
    width: 20px !important;
  }
}

.bootstrap-modal-header {
  position: absolute;
  z-index: 9;
  right: 40px;
  border-bottom: none;
}

.bootstrap-modal-content {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-dialog {
    margin: 15% auto;

    @media screen and (max-width: 1499px) {
      margin: 20% auto;
    }

    @media screen and (max-width: 1024px) {
      margin: 30% auto;
    }

    @media screen and (max-width: 736px) {
      margin: 35% auto;
    }

    @media screen and (max-width: 500px) {
      margin: 50% auto;
    }
  }
}

.catDetails-button {
  display: flex;
  height: 42px;
  border-radius: 7px;
}

.no-trade-val {
  input {
    width: 90% !important;
  }
}

.mapWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  .mapBlock {
    width: calc(50% - 20px);
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    @media (max-width:768px) {
      width: 100%;
    }

    &::before {
      content: '';
      display: block;
      background: #000000B2;
      position: absolute;
      inset: 0;
      margin: auto;
    }

    img {
      max-width: 100%;
    }

    .custom-btn {
      background: linear-gradient(90deg, #ff8177 0%, #b22b5b 100%);
      position: absolute;
      inset: 0;
      margin: auto;
      width: 165px;
      height: 43px;
      display: flex;
      gap: 8px;

      @media (max-width:768px) {
        font-size: 12px;
      }
    }
  }

  .logisticDetails-wrp {
    width: calc(50% - 20px);
    flex-direction: column;
    margin: 0;

    @media (max-width:768px) {
      width: 100%;
      margin-top: 30px;
    }

    .logisticAddress {
      h6 {
        font-size: 16px;
      }
    }

    .logisticAddress,
    .logisticMethod {
      width: 100%;

      .radio-button {
        .checkmark {
          border: 1px solid #626262;
        }
      }
    }
  }
}

button.back-btn {
  svg {
    width: 22px;
  }
}

.quick-sale-wrp {
  .headtitle-wrap {
    align-items: center !important;
  }

  .outline-icon-btn {
    @media (max-width:1280px) {
      display: block !important;
    }
  }
}

.split-count {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.attachment-tooltip {
  position: absolute !important;
  right: 10px;
  top: 8px;

  svg {
    path {
      stroke: #000;
    }
  }
}

.makesure-list {
  display: block;
  list-style-type: disc;
  padding-inline-start: 20px;
  unicode-bidi: isolate;

  li {
    list-style: disc !important;

  }
}

.tool-tip-info-button {
  position: relative;
  left: 5px;
}

.chat-section,
.AddCarSection {
  min-height: 480px;
  position: relative;
}

.NotificationArrow {
  .popover-arrow {
    display: none;
  }
}

.play-icon-size {
  span {
    svg {
      width: 100px;
      height: 100px;

      path {
        fill: #000
      }
    }
  }
}

.disable-pointer-events {
  pointer-events: none;
}

#toast-email-err-id {
  color: #000
}

.not-active-button {
  background: rgb(235, 235, 235);
}


.no-internet-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-internet-message {
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: rgb(147 147 147);
  }

  p {
    color: #535353;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
  }
}


// new signIn 


:root {
  --primarycolor: #ED1155;
  --blackcolor: #000;
  --bgwhite: #fff;
}


header {
  border-bottom: 2px solid var(--primarycolor);
  padding: 30px 0;
  background: #F9EAF0;

  .header-werap {
    @include flex;
    justify-content: space-between;

    @media (max-width:767px) {
      flex-direction: column;
      gap: 20px 0;
    }

    .brand-section {
      display: block;

      img {
        max-width: 160px;
      }
    }

    nav {
      ul {
        @include flex;
        gap: 30px;

        li {
          a {
            color: var(--primarycolor);
            font-size: 30px;
            font-family: "Outfit", sans-serif;
            font-weight: 600;
            transition: all 0.5s ease-in-out;

            @media (max-width:1367px) {
              font-size: 24px;
            }

            &:hover {
              color: #c91049;
            }
          }
        }
      }
    }
  }
}

.swap-section {
  padding: 75px 0;
  background: #F9EAF0;
  background-position: bottom left;
  position: relative;

  .txt-wrap {
    @include flex;
    margin: 0 -20px;

    @media (max-width:991px) {
      flex-wrap: wrap;
    }

    .left-section {
      width: 50%;
      padding: 0 20px;

      @media (max-width:991px) {
        width: 100%;
        position: relative;
        margin-bottom: 80px;
      }

      .title {
        font-size: 65px;
        font-weight: 700;
        color: var(--primarycolor);
        margin-bottom: 20px;
        text-align: center;

        @media (max-width:1600px) {
          font-size: 50px;
        }
        @media (max-width:1400px) {
          font-size: 45px;
        }
        @media (max-width:1199px) {
          font-size: 42px;
        }

        @media (max-width:1024px) {
          font-size: 38px;
        }
      }

      .description-txt {
        margin-top: 25px;
        font-weight: 400;
        font-size: 20px;
        color: var(--primarycolor);
        line-height: 30px;
      }

      .mobone1 {
        margin-top: 30px;
        position: absolute;
        z-index: 999;

        @media (max-width:520px) {
          width: 230px;
        }

        @media (max-width:400px) {
          display: none;
        }
      }

      .bg-img {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;

        @media (max-width:1199px) {
          width: 55%;
        }

        @media (max-width:991px) {
          width: 80%;
        }
      }
    }

    .right-section {
      width: 50%;
      padding: 0 20px;

      @media (max-width:991px) {
        width: 100%;
      }

      .form-wrap {
        width: 100%;
        background: var(--bgwhite);
        padding: 40px;
        border-radius: 20px;

        .form-header {
          .title {
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 24px;
            color: var(--blackcolor);
          }

          .description-txt {
            font-size: 22px;
            font-weight: 400;
            color: var(--blackcolor);
            line-height: 32px;
            max-width: 450px;
          }
        }

        form {
          margin-top: 24px;

          .cus-input {
            height: 54px;
            border-radius: 20px;
            padding: 0 20px;
            font-size: 20px;
            color: var(--blackcolor);
            width: 100%;
            border: 1px solid var(--primarycolor);
            &:focus-visible{
              outline: none;
            }
            &::placeholder {
              color: var(--blackcolor);
              opacity: 1;
            }
            &::-ms-input-placeholder {
              color: red;
              opacity: 1; /* Firefox */
            }
            @media (max-width: 1600px) {
              font-size: 18px;
            }
            
            @media (max-width: 1199px) {
                    font-size: 16px;
                }
            
            @media (max-width: 991px) {
                    font-size: 14px;
                    min-height: 40px;
                }
          }

          .link {
            margin-top: 24px;
            font-size: 22px;
            color: var(--blackcolor);
            font-weight: 400;
            display: block;
          }

          .btn {
            width: 100%;
            margin-top: 20px;
            font-size: 25px;
            font-weight: 500;
            border-radius: 20px;
            padding: 7px 20px;
            transition: all 0.3s ease-in-out;

            @media (max-width: 1600px) {
              font-size: 20px;
            }
            @media (max-width: 991px) {
              font-size: 14px;
              min-height: 40px
            }

            &:focus{
              box-shadow: none;
            }
            &:hover {
              opacity: 0.8;
            }

            &.btn-primary {
              background: var(--primarycolor);
              color: var(--bgwhite);

            }

            &.btn-secondary {
              background: var(--bgwhite);
              border: 1px solid var(--primarycolor);
              color: var(--primarycolor);
            }
          }
        }

        .form-footer {
          margin-top: 24px;
          @include flex;
          justify-content: space-between;

          p {
            font-size: 22px;
            color: var(--blackcolor);
          }

          ul {
            @include flex;
            gap: 0 10px;

          }
        }
      }
    }
  }

}

.carview-section {
  padding: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .found-car {
    background: var(--bgwhite);
    padding: 30px;
    width: 740px;
    border-radius: 5px;

    @media (max-width:991px) {
      width: 90%;
      min-width: 640px;
    }

    @media (max-width:767px) {
      width: 90%;
      min-width: 90%;
    }

    .title {
      font-size: 36px;
      color: #353333;
      font-weight: bold;

      @media (max-width:991px) {
        font-size: 30px;
      }

      small {
        font-size: 21px;
        color: #7C7C7C;
        font-weight: 400;
        display: block;
      }
    }

    .found-car-body {
      margin-top: 30px;
      @include flex;
      padding: 20px;
      background: #F6F6F6;
      gap: 0 16px;

      .left-section {
        @include flex;
        gap: 0 16px;

        img {
          width: 100px;
          height: 100px;
          border-radius: 10px;
          object-fit: cover;
        }
      }


      .county-section {
        background: #353333;
        width: fit-content;
        @include flex;
        gap: 7px;
        padding: 4px;
        border-radius: 2px;

        .img-block {
          background: #003399;
          border-radius: 2px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 4px;

          img {
            width: 13px;
            height: 8px;
            object-fit: cover;
          }

          span {
            color: var(--bgwhite);
            font-size: 8px;
            font-weight: 400;
            text-align: center;
          }
        }

        h6 {
          color: #fff;
          font-size: 14px;
          font-weight: bold;
        }
      }

      h4 {
        margin-top: 10px;
        font-size: 18px;
        color: #353333;
        font-weight: 400;
      }

      a {
        margin-top: 10px;
        text-decoration: underline;
        color: #353333;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .found-car-footer {
      @include flex;
      justify-content: flex-end;
      margin-top: 40px;
      gap: 17px;

      .btn {
        border-radius: 5px;
        width: 176px;
        height: 42px;
        font-size: 14px;
        color: var(--bgwhite);
        font-weight: bold;
        transition: all 0.3s ease-in-out;

        &:hover {
          opacity: 08;
        }

        &.btn-primary {
          background: #ED1155;
          --bs-btn-border-color:none;
        }

        &.btn-secondary {
          background: var(--blackcolor);
        }
        &:focus{
          box-shadow: none;
        }
      }
    }
  }
}

footer {
  background-color: #ed1155;
  padding: 60px 0 0px;

  .footer-contact {
    .title-block {
      color: var(--bgwhite);
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 20px;

      @media (max-width:991px) {
        text-align: center;
      }
    }

    h3 {
      color: var(--bgwhite);
      font-size: 48px;
      font-weight: 500;
      margin-bottom: 50px;
      line-height: 50px;

      @media (max-width:1367px) {
        font-size: 40px;
      }

      @media (max-width:1024px) {
        font-size: 34px;
      }

      @media (max-width:991px) {
        text-align: center;
        margin-bottom: 20px;
      }
    }

  
  }

  .footer-getin-touch {
    margin-top: 30px;

    h4 {
      color: var(--bgwhite);
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 20px;

      @media (max-width:991px) {
        text-align: center;
      }

      span {
        display: block;
        font-weight: 800;
        margin-top: 5px;

        @media (max-width:991px) {
          text-align: center;
        }
      }
    }

    a {
      display: block;
      margin-bottom: 10px;
      color: var(--bgwhite);
      font-size: 16px;

      @media (max-width:991px) {
        text-align: center;
      }
    }
  }

  .download-app {
    @media (max-width:991px) {
      margin-top: 30px;
    }

    h4 {
      color: var(--bgwhite);
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 20px;

      @media (max-width:991px) {
        font-size: 20px;
        text-align: center;
      }
    }

    .store-links {
      @include flex;
      gap: 0 10px;
      flex-wrap: wrap;
      margin-top: 70px;

      @media (max-width:991px) {
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
        margin-top: 30px;
      }

      a {
        img {
          width: 180px;
        }
      }
    }
  }

  .signin-footer-bottom {
    border-top: 1px solid var(--bgwhite);
    padding: 30px 0;
    margin-top: 50px;



    .copy-right {
      @include flex;
      justify-content: space-between;

      @media (max-width:991px) {
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 20px 0;
      }

      @media (max-width:991px) {
        flex-wrap: wrap;
      }

      p {
        color: var(--bgwhite);
        font-size: 20px;
        font-weight: 500;

        @media (max-width:991px) {
          font-size: 16px;
        }
      }

      ul {
        @include flex;
        gap: 60px;

        li {
          a {
            border-bottom: 1px solid;
            color: var(--bgwhite);
            font-size: 20px;
            font-weight: 500;
            transition: all .3s;

            @media (max-width:991px) {
              font-size: 16px;
            }

            &:hover {
              color: #dedede;
            }
          }
        }
      }
    }
  }
}


.video-block {
  width: 272px;
  display: block;
  height: 540px;
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  margin-left: 240px;
  margin-top: 60px;
  z-index: 999;

  @media (max-width:520px) {
    width: 230px;
    height: 470px;
    margin-left: 200px;
  }

  @media (max-width:400px) {
    width: 230px;
    height: 470px;
    margin-left: 0px;
    margin-top: 50px;
  }

  img {
    position: relative;
    z-index: 9;
  }

  video {
    width: 246px;
    display: block;
    height: 425px;
    position: absolute;
    z-index: 1;
    bottom: 13px;
    left: 10px;
  }

  &::before {
    content: "";
    display: block;
    width: 240px;
    height: 100%;
    background: #ed1156;
    position: absolute;
    z-index: 0;
    left: 12px;
    bottom: 15px;
  }
    &::after {
      content: "";
      display: block;
      width: 238px;
      height: 20px;
      background: #ed1156;
      position: absolute;
      left: 13px;
      bottom: auto;
      z-index: 11;
      top: 96px;
      filter: blur(2px);
      -o-filter: blur(2px);
      -ms-filter: blur(2px);
      -moz-filter: blur(2px);
      -webkit-filter: blur(2px);
      overflow: hidden;
      @media (max-width: 520px) {
       display: none;
    }
    }
}
.mac-swap-section{
  .video-block {
    &::before {     
      background: #f00e60;
    }
      &::after {
        background: #f00e60;
      }
  }
}

.new-signIn {

  :root {
    --primarycolor: #ED1155;
    --blackcolor: #000;
    --bgwhite: #fff;
  }

  ::placeholder {
    color: var(--blackcolor);
  }

  button {
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  img {
    max-width: 100%;
  }

  ul,
  li {
    padding: 0;
    list-style: none;
  }

  a,
  a:hover {
    text-decoration: none;
  }


  body {
    font-family: "Outfit", sans-serif;
    line-height: 1.5;
    color: #333;
    background: #F9EAF0;
  }

  .cus-container {
    max-width: 1240px;
    margin: auto;
    padding: 0 30px;
  }

  @mixin flex {
    display: flex;
    align-items: center;
  }

  .cus-row {
    margin: 0 -15px;
    @include flex;
    flex-wrap: wrap;
    align-items: unset;
  }

  .col-3 {
    width: calc(100% / 3);
    padding: 0 15px;

    @media (max-width:991px) {
      width: 100%;
    }
  }
}

.transaction-canceled{
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  background: $SecondaryAmberGradient;
  color: #ffffff;
  border-radius: 5px;
  padding: 8px;
  margin: 0;
  width: 100%;
}

.miles-away{
  display: flex;
  gap: 10px;
  width:100%;
  .miles-img{
    margin-bottom: 15px;
  }
}

.attachment-button{
  position: absolute;
  right: 12px;
  top: 7px;
  svg {
    color: #939393;
  }
}